import { ApiMetric } from "@incendium/api";
import { useMetricLabels } from "features/analytics/hooks/useMetricLabels";
import { metricConfigByName } from "features/analytics/services/metricsFormatter";
import { useCallback } from "react";

export const useMetricName = () => {
  const { metricLabels } = useMetricLabels();

  return useCallback(
    (metric: ApiMetric) => {
      const found = metricLabels.find((ml) => ml.metric === metric);
      return found?.label
        ? found.label
        : metricConfigByName(metric)?.name || metric;
    },
    [metricLabels]
  );
};
