export const NOT_SET = "(not set)";

export const desktop = "desktop";
export const mobile = "mobile";

// estimated min height based on number of rows in table
export const table2Rows = 287;
export const table3Rows = 350;
export const table4Rows = 402;
export const table5Rows = 450;
export const table5RowsWithComp = 461;

export const xStandardProps = {
  angle: -60,
  tickMargin: 8,
  textAnchor: "end",
  minTickGap: 0,
  fontSize: 11,
  tickCount: 20,
};
export const yStandardProps = {
  allowDataOverflow: true,
  tickCount: 6,
  tickLine: false,
  tickMargin: 8,
  fontSize: 11,
};
