import { ApiProject } from "@incendium/api";
import { listSourceCategories } from "features/sourceCategories/services/sourceCategoryService";
import { useSharedState } from "Hooks/useSharedState";

const fetchSourceCategories = async (selectedProject: ApiProject | null) => {
  if (selectedProject) {
    return listSourceCategories(selectedProject.id!);
  }
  return [];
};
export const useSourceCategories = () => {
  const { state, setState, loading } = useSharedState(
    "sourceCategories",
    [],
    fetchSourceCategories
  );

  return {
    sourceCategories: state,
    setSourceCategories: setState,
    loading,
  };
};
