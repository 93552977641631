import { ApiLocationVelocityType } from "@incendium/api";

export const velocityMap = [
  {
    type: ApiLocationVelocityType.DISABLED,
    label: "Disabled",
    description:
      "Velocity is turned off. No preloading occurs for this location.",
  },
  {
    type: ApiLocationVelocityType.CONSERVATIVE,
    label: "Conservative",
    description:
      "Preloads content based on user actions like touching or clicking a link. Minimal optimization but very low server impact; ideal for servers which lack resources.",
  },
  {
    type: ApiLocationVelocityType.MODERATE,
    label: "Moderate",
    description:
      "Preloads content when the visitor hovers over a link for 200 milliseconds or touches a link. Optimized for a balance between speed and server resource efficiency.",
  },
  {
    type: ApiLocationVelocityType.EAGER,
    label: "Eager (Recommended)",
    description:
      "Preloads all content for the next most likely page, ensuring faster navigation. Note: This may fetch resources from your server, so use with caution if bandwidth or server load is a concern.",
  },
  {
    type: ApiLocationVelocityType.IMMEDIATE,
    label: "Immediate",
    description:
      "Begins preloading resources for the next most likely page as soon as the current page loads, without waiting for user interaction. This provides the fastest possible navigation experience but can significantly impact server bandwidth and load. Use with care, especially for resource-heavy pages or high-traffic environments.",
  },
];

export const velocityMapByType = (type: ApiLocationVelocityType) => {
  return velocityMap.find((f) => f.type === type);
};
