import { Add, Reorder } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { useMemo } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ChartBuilderMetric from "./ChartBuilderMetric";
import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";
import ReactDOM from "react-dom";
import { ApiMetric } from "@incendium/api";

export interface IGroupedMetrics {
  metrics: string[];
  title: string;
}

interface IChartBuilderYAxisProps {
  // availableMetrics: IGroupedMetrics[];
  y: string;
  title?: string;
  subTitle?: string;
  noDrag?: boolean;
  noControls?: boolean;
  noRemove?: boolean;
  maxMetrics?: number;
}

function ChartBuilderYAxis({
  // availableMetrics,
  y,
  title,
  subTitle,
  noDrag,
  noControls,
  noRemove,
  maxMetrics,
}: IChartBuilderYAxisProps) {
  const { chart, setChart } = useReportBuilderContext();

  const yAxis: any = useMemo(() => {
    const idx = chart.yAxisKeys.findIndex((v: any) => v.key === y);
    if (idx < 0) {
      return null;
    }
    return chart.yAxisKeys[idx];
  }, [chart, y]);

  const reachedMaxMetrics = useMemo(() => {
    if (!maxMetrics) {
      return false;
    }

    return (yAxis?.fields || []).length >= maxMetrics;
  }, [maxMetrics, yAxis]);

  return (
    <AccordianChartBuilderSidebarBlock
      defaultClosed={y === "r" && !yAxis}
      title={
        title || `Verticle (Y) ${y === "l" ? "Left" : "Right"} Axis Values`
      }
      subTitle={
        subTitle ||
        "Select a Metric to display on verticle axis, e.g no. of sessions etc"
      }
    >
      {noDrag ? (
        <Box>
          {(yAxis?.fields || []).map((_: any, i: number) => (
            <Box
              key={i}
              mb={1.5}
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <ChartBuilderMetric
                chart={chart}
                setChart={setChart}
                // metrics={availableMetrics}
                index={i}
                yKey={y}
                noControls={noControls}
                noRemove={noRemove}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Droppable droppableId={`yAxis-${y}`}>
          {(prov) => (
            <Box ref={prov.innerRef} {...prov.droppableProps}>
              {(yAxis?.fields || []).map((_: any, i: number) => (
                <Draggable
                  draggableId={`yAxis-${y}-${i}`}
                  index={i}
                  key={i}
                  isDragDisabled={noDrag}
                >
                  {(provided, snapshot) => {
                    const children = (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={i}
                        mb={1.5}
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Box {...provided.dragHandleProps} mr={1}>
                          <Reorder color="secondary" />
                        </Box>

                        <ChartBuilderMetric
                          chart={chart}
                          setChart={setChart}
                          // metrics={availableMetrics}
                          index={i}
                          yKey={y}
                          noControls={noControls}
                          noRemove={noRemove}
                        />
                      </Box>
                    );
                    if (snapshot.isDragging) {
                      return ReactDOM.createPortal(children, document.body);
                    }
                    return children;
                  }}
                </Draggable>
              ))}
              {prov.placeholder}
            </Box>
          )}
        </Droppable>
      )}

      {!noRemove && !reachedMaxMetrics && (
        <Box mt={3}>
          <Button
            color="secondary"
            size="extraSmall"
            endIcon={<Add />}
            onClick={() => {
              setChart(
                produce(chart, (draft) => {
                  const idx = draft.yAxisKeys.findIndex(
                    (v: any) => v.key === y
                  );
                  if (idx >= 0) {
                    (draft.yAxisKeys[idx] as any).fields.push("");
                    return;
                  }
                  draft.yAxisKeys.push({
                    key: y,
                    fields: [ApiMetric.METRIC_NOT_SET],
                  });
                })
              );
            }}
          >
            Add additional metric
          </Button>
        </Box>
      )}
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderYAxis;
