import { ApiDimension } from "@incendium/api";
import { MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import AccordianGlassCard from "Components/UI/AccordianGlassCard";
import { useSelectedDimension } from "features/analytics";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";
import { motion } from "framer-motion";
import { ReactNode, useState } from "react";

interface IInsightsCardProps {
  title: string;
  dimensions?: ApiDimension[];
  body: ReactNode;
}

function InsightsCard({
  title,
  dimensions,
  body,
  ...rest
}: IInsightsCardProps) {
  const [open, setOpen] = useState<boolean>(true);
  const dimensionToName = useDimensionName();

  const { selectedDimension, setSelectedDimension } = useSelectedDimension();

  return (
    <AccordianGlassCard
      onOpenChange={(b) => setOpen(b)}
      localStorageKey={`insight-block-${title}`}
      title={
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <Typography variant="subtitle1">{title}</Typography>
          {open && (dimensions || []).length > 0 && (
            <motion.div
              initial={{ opacity: 0, x: -80 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2, delay: 0.1 }}
            >
              <TextField
                select
                fullWidth
                size="small"
                sx={{
                  maxWidth: "100%",
                  minWidth: 150,
                  width: 200,
                }}
                value={selectedDimension}
                label="change dimension"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  e.nativeEvent.stopImmediatePropagation();
                }}
                onChange={(e) => {
                  setSelectedDimension(e.target.value as ApiDimension);
                }}
              >
                {(dimensions || []).map((dimension) => (
                  <MenuItem key={dimension} value={dimension}>
                    {dimensionToName(dimension)}
                  </MenuItem>
                ))}
              </TextField>
            </motion.div>
          )}
        </Stack>
      }
      {...rest}
    >
      {body}
    </AccordianGlassCard>
  );
}

export default InsightsCard;
