/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiMetricCategory;
(function (ApiMetricCategory) {
    ApiMetricCategory["METRIC_CATEGORY_UNKNOWN"] = "MetricCategoryUnknown";
    ApiMetricCategory["METRIC_CATEGORY_COST_EFFICIENCY"] = "MetricCategoryCostEfficiency";
    ApiMetricCategory["METRIC_CATEGORY_PROFITABILITY"] = "MetricCategoryProfitability";
    ApiMetricCategory["METRIC_CATEGORY_ECOMMERCE"] = "MetricCategoryEcommerce";
    ApiMetricCategory["METRIC_CATEGORY_ATTRIBUTION"] = "MetricCategoryAttribution";
    ApiMetricCategory["METRIC_CATEGORY_RETENTION"] = "MetricCategoryRetention";
    ApiMetricCategory["METRIC_CATEGORY_CUSTOMER_LIFETIME_VALUE"] = "MetricCategoryCustomerLifetimeValue";
    ApiMetricCategory["METRIC_CATEGORY_PUBLISHER_DATA"] = "MetricCategoryPublisherData";
    ApiMetricCategory["METRIC_CATEGORY_TRAFFIC"] = "MetricCategoryTraffic";
    ApiMetricCategory["METRIC_CATEGORY_PREDICTIVE_ANALYTICS"] = "MetricCategoryPredictiveAnalytics";
    ApiMetricCategory["METRIC_CATEGORY_PERFORMANCE"] = "MetricCategoryPerformance";
    ApiMetricCategory["METRIC_CATEGORY_ENGAGEMENT"] = "MetricCategoryEngagement";
    ApiMetricCategory["METRIC_CATEGORY_REVENUE"] = "MetricCategoryRevenue";
    ApiMetricCategory["METRIC_CATEGORY_INTERACTIONS"] = "MetricCategoryInteractions";
    ApiMetricCategory["METRIC_CATEGORY_CONVERSION"] = "MetricCategoryConversion";
    ApiMetricCategory["METRIC_CATEGORY_ADVANCED"] = "MetricCategoryAdvanced";
    ApiMetricCategory["METRIC_CATEGORY_INVENTORY"] = "MetricCategoryInventory";
    ApiMetricCategory["METRIC_CATEGORY_VELOCITY"] = "MetricCategoryVelocity";
})(ApiMetricCategory || (ApiMetricCategory = {}));
export function ApiMetricCategoryFromJSON(json) {
    return ApiMetricCategoryFromJSONTyped(json, false);
}
export function ApiMetricCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiMetricCategoryToJSON(value) {
    return value;
}
