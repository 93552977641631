import {
  Box,
  Button,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";
import backgroundImageStat1 from "Assets/backgrounds/stat-1.jpg";
import backgroundImageStat2 from "Assets/backgrounds/stat-2.jpg";
import backgroundImageStat3 from "Assets/backgrounds/stat-3.jpg";
import backgroundImageStat4 from "Assets/backgrounds/stat-4.jpg";
import { useCallback, useMemo } from "react";
import { ApiChartYAxisKey, ApiMetric } from "@incendium/api";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { useMetricName } from "features/analytics/hooks/useMetricName";

const StyledImageBtn = styled(Button)<{ bgIndex: number; active: boolean }>(
  ({ theme, bgIndex, active }) => {
    const bg =
      bgIndex === 1
        ? backgroundImageStat1
        : bgIndex === 2
        ? backgroundImageStat2
        : bgIndex === 3
        ? backgroundImageStat3
        : backgroundImageStat4;
    return {
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
      height: 70,
      borderRadius: 10,
      border: active ? `2px solid white` : undefined,
      boxShadow: active
        ? `0px 0px 0px 2px ${theme.palette.primary.main}`
        : undefined,
      "&:hover": {
        border: `2px solid white`,
        boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
      },
    };
  }
);

function ChartBuilderStatCardOptions() {
  const { chart, setChart } = useReportBuilderContext();
  const metricToName = useMetricName();
  const onClick = useCallback(
    (idx: number) => {
      setChart(
        produce(chart, (draft) => {
          if (!draft.displayOptions) {
            draft.displayOptions = {};
          }

          draft.displayOptions.statDesignIndex =
            idx === draft.displayOptions.statDesignIndex ? undefined : idx;
        })
      );
    },
    [setChart, chart]
  );

  const metrics = useMemo(() => {
    const fields =
      chart.yAxisKeys.length > 0
        ? (chart.yAxisKeys[0] as ApiChartYAxisKey).fields
        : null;
    if (!fields) {
      return [];
    }
    return fields;
  }, [chart]);

  return (
    <AccordianChartBuilderSidebarBlock
      title={`Stat Card Options`}
      subTitle="Configure Display Options for your Stat Card"
    >
      <Stack spacing={4}>
        <Stack spacing={2}>
          <TextField
            label={`Stat Card's ${metricToName(metrics[0] as ApiMetric)} Title`}
            fullWidth
            size="small"
            value={chart.displayOptions?.statTitle || ""}
            onChange={(e) =>
              setChart(
                produce(chart, (draft) => {
                  if (!draft.displayOptions) {
                    draft.displayOptions = {};
                  }
                  draft.displayOptions.statTitle = e.target.value;
                })
              )
            }
          />
          {metrics.length > 1 && (
            <TextField
              label={`Stat Card's ${metricToName(
                metrics[1] as ApiMetric
              )} Title`}
              fullWidth
              size="small"
              value={chart.displayOptions?.statTitle2 || ""}
              onChange={(e) =>
                setChart(
                  produce(chart, (draft) => {
                    if (!draft.displayOptions) {
                      draft.displayOptions = {};
                    }
                    draft.displayOptions.statTitle2 = e.target.value;
                  })
                )
              }
            />
          )}
        </Stack>
        <StyledFlexBox compact sx={{ maxWidth: 300 }}>
          <Box display="flex" alignItems="center">
            <Switch
              checked={chart.comparison}
              onChange={(e, checked) =>
                setChart((chart) =>
                  produce(chart, (draft) => {
                    draft.comparison = checked;
                  })
                )
              }
            />
            <Typography color={chart.comparison ? "primary" : "secondary"}>
              Add Comparison
            </Typography>
          </Box>
          <ClickableTooltip
            placement="right"
            text={"Introduces comparison with the previous period."}
            icon={<StyledHelp />}
          />
        </StyledFlexBox>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Box>
              <Typography variant="subtitle2">Select a Background</Typography>
              <Typography variant="body2">
                Optional: if you choose not to, Incendium will select one for
                you that works best.
              </Typography>
            </Box>
            <Stack direction={"row"} spacing={2}>
              <StyledImageBtn
                bgIndex={1}
                fullWidth
                onClick={() => onClick(1)}
                active={chart.displayOptions?.statDesignIndex === 1}
              >
                Design One
              </StyledImageBtn>
              <StyledImageBtn
                bgIndex={2}
                fullWidth
                onClick={() => onClick(2)}
                active={chart.displayOptions?.statDesignIndex === 2}
              >
                Design Two
              </StyledImageBtn>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <StyledImageBtn
                bgIndex={3}
                fullWidth
                onClick={() => onClick(3)}
                active={chart.displayOptions?.statDesignIndex === 3}
              >
                Design Three
              </StyledImageBtn>
              <StyledImageBtn
                bgIndex={4}
                fullWidth
                onClick={() => onClick(4)}
                active={chart.displayOptions?.statDesignIndex === 4}
              >
                Design Four
              </StyledImageBtn>
            </Stack>
          </Stack>
          <StyledFlexBox compact sx={{ maxWidth: 300 }}>
            <Box display="flex" alignItems="center">
              <Switch
                checked={!!chart.displayOptions?.statNoBg}
                onChange={(e, checked) =>
                  setChart(
                    produce(chart, (draft) => {
                      if (!draft.displayOptions) {
                        draft.displayOptions = {};
                      }

                      draft.displayOptions.statNoBg = checked;
                    })
                  )
                }
              />
              <Typography color={chart.comparison ? "primary" : "secondary"}>
                No Background
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={"Introduces comparison with the previous period."}
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </Stack>
      </Stack>
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderStatCardOptions;
