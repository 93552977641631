import { Box } from "@mui/material";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer } from "Components/UI/TabContainer";
import { enumToArray } from "Helpers/enumToText";
import { useSelectedProject } from "Hooks";
import ChannelPage from "Pages/Train/ChannelPage";
import { ChannelsPage } from "Pages/Train/ChannelsPage";
import SourcCategoriesPage from "Pages/Train/SourcCategoriesPage";
import TrafficBucketPage from "Pages/Train/TrafficBucketPage";
import { TrafficBucketsPage } from "Pages/Train/TrafficBucketsPage";
import TrafficConsolidatePage from "Pages/Train/TrafficConsolidatePage";
import { useCallback } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";

enum Tab {
  channel = "Channels",
  categories = "Source Categories",
  buckets = "Traffic Buckets",
  consolidate = "Traffic Consolidate",
}

function TrafficPage() {
  const { selectedProject } = useSelectedProject();
  const history = useHistory();
  const location = useLocation();

  const onTabChange = useCallback(
    (value: Tab) => {
      const baseURL = `/clients/${selectedProject?.clientId}/projects/${selectedProject?.id}/train/traffic/`;
      switch (value) {
        case Tab.channel:
          history.push(baseURL + "channels");
          break;
        case Tab.categories:
          history.push(baseURL + "source-categories");
          break;
        case Tab.buckets:
          history.push(baseURL + "buckets");
          break;
        case Tab.consolidate:
          history.push(baseURL + "consolidate");
          break;

        default:
          break;
      }
    },
    [history, selectedProject]
  );

  const isActive = useCallback(
    (t: Tab) => {
      switch (t) {
        case Tab.channel:
          return location.pathname.includes("channel");
        case Tab.categories:
          return location.pathname.includes("source-categories");
        case Tab.buckets:
          return location.pathname.includes("bucket");
        case Tab.consolidate:
          return location.pathname.includes("consolidate");

        default:
          return false;
      }
    },
    [location]
  );

  return (
    <>
      <TabContainer fullWidth>
        {enumToArray(Tab).map((t) => (
          <TabButton
            key={t}
            onClick={() => onTabChange(t)}
            isActive={isActive(t)}
          >
            {t}
          </TabButton>
        ))}
      </TabContainer>
      <Box mt={4}>
        <Switch>
          <Route
            exact
            path="/clients/:clientId/projects/:projectId/train/traffic/channels"
          >
            <ChannelsPage />
          </Route>
          <Route
            exact
            path="/clients/:clientId/projects/:projectId/train/traffic/channels/:channelId"
          >
            <ChannelPage />
          </Route>
          <Route
            exact
            path="/clients/:clientId/projects/:projectId/train/traffic/source-categories"
          >
            <SourcCategoriesPage title="Source Categories" />
          </Route>
          <Route
            exact
            path="/clients/:clientId/projects/:projectId/train/traffic/buckets"
          >
            <TrafficBucketsPage />
          </Route>
          <Route
            exact
            path="/clients/:clientId/projects/:projectId/train/traffic/buckets/:bucketId"
          >
            <TrafficBucketPage />
          </Route>
          <Route
            exact
            path="/clients/:clientId/projects/:projectId/train/traffic/consolidate"
          >
            <TrafficConsolidatePage />
          </Route>
        </Switch>
      </Box>
    </>
  );
}

export default TrafficPage;
