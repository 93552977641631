import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid, Stack } from "@mui/material";
import { table4Rows, table5Rows, desktop, mobile } from "features/analytics";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import AnalyticsChartStatCard from "features/analytics/components/AnalyticsChartStatCard";

function VelocityReportPage() {
  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={4}>
        <Stack spacing={2}>
          <AnalyticsChartStatCard
            chart={{
              name: "Revenu Impact (Velocity)",
              dimension: [ApiDimension.DIMENSION_NOT_SET],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.STAT_CARD,
              attributes: [],
              displayOptions: {
                statTitle: "Revenue Impact",
              },
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_VELOCITY_SESSION_REVENUE_IMPACT],
                },
              ],
            }}
            noToolbar
          />

          <AnalyticsChartStatCard
            chart={{
              name: "Conversion rate uplift (Velocity)",
              dimension: [ApiDimension.DIMENSION_NOT_SET],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.STAT_CARD,
              attributes: [],
              displayOptions: {
                statTitle: "Conversion Rate Uplift",
              },
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE_UPLIFT,
                  ],
                },
              ],
            }}
            noToolbar
          />
          <AnalyticsChartStatCard
            chart={{
              name: "Sessions Optimized (Velocity)",
              dimension: [ApiDimension.DIMENSION_NOT_SET],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.STAT_CARD,
              attributes: [],
              displayOptions: {
                statTitle: "Sessions Optimized",
              },
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_VELOCITY_SESSION_COUNT],
                },
              ],
            }}
            noToolbar
          />
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <AnalyticsCard
          chart={{
            name: "Conversion Rate: Velocity Vs Baseline",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_VELOCITY_SESSION_MACRO_CONVERSION_COUNT,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "Pageviews Optimized Vs Not Optimized",
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              ApiDimension.DIMENSION_VELOCITY_STATUS,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <AnalyticsCard
          chart={{
            name: "Optimized Vs Not Optimized",
            dimension: [ApiDimension.DIMENSION_VELOCITY_STATUS],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                chart: {
                  [ApiMetric.METRIC_PAGE_VIEWS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE],
                chart: {
                  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>

      <Grid item xs={6} sx={{ minHeight: table4Rows }}>
        <AnalyticsCard
          chart={{
            name: "Sales Performance: Optimized Vs Not Optimized (Desktop)",
            dimension: [ApiDimension.DIMENSION_VELOCITY_STATUS],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_DEVICE,
                operator: ApiOperator.EQUAL,
                value: desktop,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_CHECKOUT_START_RATE,
                  ApiMetric.METRIC_CHECKOUT_COMPLETION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SALES_REVENUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: table4Rows }}>
        <AnalyticsCard
          chart={{
            name: "Sales Performance: Optimized Vs Not Optimized (Mobile)",
            dimension: [ApiDimension.DIMENSION_VELOCITY_STATUS],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_DEVICE,
                operator: ApiOperator.EQUAL,
                value: mobile,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_CHECKOUT_START_RATE,
                  ApiMetric.METRIC_CHECKOUT_COMPLETION_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SALES_REVENUE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: table4Rows }}>
        <AnalyticsCard
          chart={{
            name: "Sales Performance: Optimized Vs Not Optimized (Desktop)",
            dimension: [ApiDimension.DIMENSION_VELOCITY_STATUS],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_DEVICE,
                operator: ApiOperator.EQUAL,
                value: desktop,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ minHeight: table4Rows }}>
        <AnalyticsCard
          chart={{
            name: "Sales Performance: Optimized Vs Not Optimized (Mobile)",
            dimension: [ApiDimension.DIMENSION_VELOCITY_STATUS],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_DEVICE,
                operator: ApiOperator.EQUAL,
                value: mobile,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
                  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                  ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              noFilters: true,
              showTotals: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default VelocityReportPage;
