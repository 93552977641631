import { locationService } from "Apis";
import { useSharedState } from "./useSharedState";
import { ApiLocation } from "@incendium/api";
import { useSelectedProject } from "./useSelectedProject";
// import { useCallback, useEffect, useMemo } from "react";
// import produce from "immer";

export const useLocations = (locationId?: number) => {
  const { selectedProject } = useSelectedProject();

  const fetchLocations = async () => {
    if (selectedProject) {
      const locations = await locationService.locationServiceListLocations({
        projectId: selectedProject.id as number,
      });
      return locations.results || [];
    }
    return [];
  };
  const fetchLocation = async () => {
    if (selectedProject && locationId) {
      const res = await locationService.locationServiceReadLocation({
        projectId: selectedProject.id as number,
        locationId,
      });
      return res.result || {};
    }
    return {};
  };
  const {
    state,
    setState,
    refetch,
    childState,
    setChildState,
    resetEmpty,
    loading,
  } = useSharedState<ApiLocation, ApiLocation[]>(
    "locations",
    [],
    fetchLocations,
    {},
    locationId,
    fetchLocation,
    {
      name: "",
      urlMatch: "",
      items: [],
      showInAnalytics: true,
    }
  );

  return {
    loading,
    locations: state,
    setLocations: setState,
    refetchLocations: refetch,
    location: childState as ApiLocation,
    setLocation: setChildState as (d: ApiLocation) => void,
    resetEmpty,
  };
};
