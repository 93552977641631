import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import ChartDescriptionDrawer from "features/analytics/components/ChartDescriptionDrawer";
import { ApiDimension, ApiMetric } from "@incendium/api";

interface ChartDescriptionDrawerContextType {
  openDrawer: (
    dimensions: ApiDimension[],
    metrics: ApiMetric[],
    title?: string
  ) => void;
}

// Create the context
const ChartDescriptionDrawerContext = createContext<
  ChartDescriptionDrawerContextType | undefined
>(undefined);

export const useChartDescriptionDrawer = () => {
  const context = useContext(ChartDescriptionDrawerContext);
  if (!context) {
    throw new Error(
      "useMetricDrawer must be used within a MetricDrawerProvider"
    );
  }
  return context;
};

export const ChartDescriptionDrawerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDimensions, setSelectedDimesnions] = useState<ApiDimension[]>(
    []
  );
  const [selectedMetrics, setSelectedMetrics] = useState<ApiMetric[]>([]);
  const [selectedTitle, setSelectedTitle] = useState("");

  const openDrawer = useCallback(
    (dimensions: ApiDimension[], metrics: ApiMetric[], title?: string) => {
      setSelectedDimesnions(dimensions);
      setSelectedMetrics(metrics);
      setSelectedMetrics(metrics);
      setSelectedTitle(title ? title : "");
      setIsDrawerOpen(true);
    },
    []
  );

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setSelectedDimesnions([]);
    setSelectedMetrics([]);
    setSelectedTitle("");
  }, []);

  return (
    <ChartDescriptionDrawerContext.Provider value={{ openDrawer }}>
      {children}
      <ChartDescriptionDrawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        dimensions={selectedDimensions}
        metrics={selectedMetrics}
        title={selectedTitle}
      />
    </ChartDescriptionDrawerContext.Provider>
  );
};
