import {
  ApiMetric,
  ApiSimpleMetricLabel,
  ApiDimension,
  ApiSimpleDimensionLabel,
} from "@incendium/api";
import { Box, DrawerProps, Typography } from "@mui/material";
import Loading from "Components/Loading/Loading";
import StyledDrawer, { StyledDrawerTitle } from "Components/UI/StyledDrawer";
import { useMetricLabels } from "features/analytics/hooks/useMetricLabels";
import { useDimensionLabels } from "features/analytics/hooks/useDimensionLabels"; // Hook for dimensions
import produce, { Draft } from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  readMetricLabel,
  readDimensionLabel,
} from "features/analytics/services/analyticsLabelService";
import { CallbackOrVal } from "Interfaces";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";

interface IChartDescriptionDrawerItemProps<T> {
  item: T extends ApiSimpleMetricLabel ? ApiMetric : ApiDimension;
  itemLabels: T[];
  setItemLabels: (val: CallbackOrVal<T[]>) => void;
  open?: boolean;
  isMetric: boolean;
  itemsLength: number;
}

function ChartDescriptionDrawerItem<
  T extends ApiSimpleMetricLabel | ApiSimpleDimensionLabel
>({
  item,
  itemLabels,
  setItemLabels,
  open,
  isMetric,
  itemsLength,
}: IChartDescriptionDrawerItemProps<T>) {
  const [loading, setLoading] = useState(false);

  const itemLabel = useMemo(() => {
    if (isMetric) {
      return (itemLabels as ApiSimpleMetricLabel[]).find(
        (il) => il.metric === item
      );
    } else {
      return (itemLabels as ApiSimpleDimensionLabel[]).find(
        (il) => il.dimension === item
      );
    }
  }, [itemLabels, item, isMetric]);

  const loadArticle = useCallback(async () => {
    if (!item) return;

    setLoading(true);

    const res = isMetric
      ? await readMetricLabel(item as ApiMetric)
      : await readDimensionLabel(item as ApiDimension);

    if (!res) {
      setLoading(false);
      return;
    }

    setItemLabels((labels) =>
      produce(labels, (draft: Draft<T[]>) => {
        const idx = draft.findIndex((d) =>
          isMetric
            ? (d as ApiSimpleMetricLabel).metric === item
            : (d as ApiSimpleDimensionLabel).dimension === item
        );
        if (idx >= 0) {
          draft[idx] = res as Draft<T>;
        }
      })
    );

    setLoading(false);
  }, [setItemLabels, item, isMetric]);

  useEffect(() => {
    if (!open || !itemLabel || !itemLabel.hasArticle) {
      return;
    }
    if (itemLabel.article && itemLabel.article.length > 0) {
      return;
    }
    loadArticle();
  }, [open, itemLabel, loadArticle]);

  const title = useMemo(() => {
    return (
      <>
        <small>({isMetric ? "Metric" : "Dimension"})</small>
        {" - "}
        {itemLabel?.label || ""}
      </>
    );
  }, [itemLabel?.label, isMetric]);

  return (
    <AccordianChartBuilderSidebarBlock
      title={title}
      subTitle={itemLabel?.description}
      defaultClosed={itemsLength > 1}
    >
      {loading ? (
        <Loading />
      ) : (
        <Box dangerouslySetInnerHTML={{ __html: itemLabel?.article || "" }} />
      )}
    </AccordianChartBuilderSidebarBlock>
  );
}

interface IChartDescriptionDrawerProps extends DrawerProps {
  metrics: ApiMetric[];
  dimensions: ApiDimension[]; // Add dimensions to props
  title: string;
}

function ChartDescriptionDrawer({
  open,
  onClose,
  metrics,
  dimensions,
  title,
}: IChartDescriptionDrawerProps) {
  const { metricLabels, setMetricLables } = useMetricLabels();
  const { dimensionLabels, setDimensionLables } = useDimensionLabels(); // Hook for dimension labels

  return (
    <StyledDrawer open={open} onClose={onClose} maxWidth={800}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">{title}</Typography>
      </StyledDrawerTitle>

      {dimensions
        .filter((d) => d !== ApiDimension.DIMENSION_NOT_SET)
        .map((dimension) => (
          <ChartDescriptionDrawerItem<ApiSimpleDimensionLabel>
            key={`dimension-${dimension}`}
            open={open}
            item={dimension}
            itemLabels={dimensionLabels}
            setItemLabels={setDimensionLables}
            isMetric={false}
            itemsLength={dimensions.length}
          />
        ))}

      {metrics
        .filter((m) => m !== ApiMetric.METRIC_NOT_SET)
        .map((metric) => (
          <ChartDescriptionDrawerItem<ApiSimpleMetricLabel>
            key={`metric-${metric}`}
            open={open}
            item={metric}
            itemLabels={metricLabels}
            setItemLabels={setMetricLables}
            isMetric={true}
            itemsLength={metrics.length}
          />
        ))}
    </StyledDrawer>
  );
}

export default ChartDescriptionDrawer;
