import {
  ApiDimension,
  ApiMetric,
  ApiSimpleDimensionLabel,
  ApiSimpleMetricLabel,
  LabelServiceReadDimensionLabelDimensionEnum,
  LabelServiceReadMetricLabelMetricEnum,
  LabelServiceUpdateDimensionLabelDimensionEnum,
  LabelServiceUpdateMetricLabelMetricEnum,
} from "@incendium/api";
import { analyticsLabelService } from "Apis";

export const listMetricLabels = async () => {
  const res = await analyticsLabelService.labelServiceListMetricLabel();
  return res.results || [];
};

export const listDimensionLabels = async () => {
  const res = await analyticsLabelService.labelServiceListDimensionLabel();
  return res.results || [];
};

export const saveMetricLabel = async (
  metric: ApiMetric,
  label: ApiSimpleMetricLabel
) => {
  const res = await analyticsLabelService.labelServiceUpdateMetricLabel({
    metric: metric as unknown as LabelServiceUpdateMetricLabelMetricEnum, // LabelServiceUpdateMetricLabelMetricEnum is same as ApiMetric
    payload: {
      ...label,
    },
  });

  return res.label;
};
export const saveDimensionLabel = async (
  dimension: ApiDimension,
  label: ApiSimpleDimensionLabel
) => {
  const res = await analyticsLabelService.labelServiceUpdateDimensionLabel({
    dimension:
      dimension as unknown as LabelServiceUpdateDimensionLabelDimensionEnum, // LabelServiceUpdateDimensionLabelDimensionEnum is same as ApiDimension
    payload: {
      ...label,
    },
  });

  return res.label;
};

export const readMetricLabel = async (metric: ApiMetric) => {
  const res = await analyticsLabelService.labelServiceReadMetricLabel({
    metric: metric as unknown as LabelServiceReadMetricLabelMetricEnum, // LabelServiceReadMetricLabelMetricEnum is same as ApiMetric
  });
  return res.label;
};

export const readDimensionLabel = async (dimension: ApiDimension) => {
  const res = await analyticsLabelService.labelServiceReadDimensionLabel({
    dimension:
      dimension as unknown as LabelServiceReadDimensionLabelDimensionEnum, // LabelServiceReadDimensionLabelDimensionEnum is same as ApiDimension
  });
  return res.label;
};
