import {
  ApiCondition,
  ApiEffectivenessCondition,
  ApiSessionEffectivenessCondition,
} from "@incendium/api";
import Delete from "@mui/icons-material/Delete";
import { Button, Fab, Typography, styled, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import GlassCard from "Components/GlassCard/GlassCard";
import { AnimatePresence, motion } from "framer-motion";
import produce from "immer";
import { useMemo, useRef, useState } from "react";
import { useUpdateEffect } from "react-use";
import ConditionalGroupRow from "./ConditionalGroupRow";
import {
  IChannelRuleOverride,
  useConditionGroupContext,
} from "./ConditionGroupProvider";

interface IStyledAndBoxProps {
  top?: number;
  bottom?: number;
}

const StyledAndBox = styled(Box)<IStyledAndBoxProps>(
  ({ theme, top, bottom }) => ({
    position: "absolute",
    left: -14,
    top: "100%",
    transform: "translate(-100%, -6px)",
    background: theme.palette.info.dark,
    color: theme.palette.common.white,
    width: 34,
    height: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontSize: 12,
    "& > span": {
      background: theme.palette.info.dark,
      position: "fixed",
      zIndex: 1,
    },
    "&::before": {
      content: "''",
      position: "absolute",
      bottom: 16,
      left: 16,
      borderTop: `1px solid ${theme.palette.info.dark}`,
      borderLeft: `1px solid ${theme.palette.info.dark}`,
      height: top || 39,
      width: 32,
      borderTopLeftRadius: 10,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: 16,
      left: 16,
      borderBottom: `1px solid ${theme.palette.info.dark}`,
      borderLeft: `1px solid ${theme.palette.info.dark}`,
      height: bottom || 39,
      width: 32,
      borderBottomLeftRadius: 10,
    },
  })
);

const ConditionalGroupCondition = ({
  conditions,
  i,
  idx,
}: {
  conditions:
    | ApiCondition[]
    | ApiEffectivenessCondition[]
    | ApiSessionEffectivenessCondition[]
    | IChannelRuleOverride[];
  i: number;
  idx: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const [top, setTop] = useState<number | undefined>(undefined);
  const [bottom, setBottom] = useState<number | undefined>(undefined);

  const removeCondition = (i: number) => {
    setConditionGroups(
      produce(conditionGroups, (draft) => {
        (draft[idx].conditions || []).splice(i, 1);
      })
    );
  };

  useUpdateEffect(() => {
    if (!ref?.current?.offsetHeight || !conditionGroups.length) {
      return;
    }
    const margin = 24;
    const nextSib = ref?.current?.nextElementSibling;
    setTop((ref?.current?.clientHeight + margin) / 2 + 2);
    setBottom(
      ((nextSib ? nextSib?.clientHeight : ref?.current?.clientHeight) +
        margin) /
        2 +
        1
    );
  }, [ref, conditionGroups]);

  return (
    <Box
      ref={ref}
      mb={3}
      display={"flex"}
      sx={{
        position: "relative",
      }}
      component={motion.div}
      animate={{
        x: 0,
        opacity: 1,
      }}
      initial={{ x: -20, opacity: 0 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{
        delay: 0.1,
      }}
      key={i}
    >
      {i !== conditions.length - 1 && (
        <Box
          component={motion.div}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          initial={{ scale: 0.5, opacity: 0 }}
          exit={{ y: -50, opacity: 0 }}
          key={`and-${idx}-${i}`}
        >
          <StyledAndBox top={top} bottom={bottom}>
            <span>AND</span>
          </StyledAndBox>
        </Box>
      )}

      <ConditionalGroupRow groupIdx={idx} rowIdx={i} />
      <IconButton
        sx={{ marginLeft: 2 }}
        onClick={() => removeCondition(i)}
        disabled={conditions.length === 1}
      >
        <Delete />
      </IconButton>
    </Box>
  );
};

function ConditionalGroupCard({ idx }: { idx: number }) {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const conditions = useMemo(() => {
    return conditionGroups[idx]?.conditions || [];
  }, [conditionGroups, idx]);

  const addCondition = () => {
    setConditionGroups(
      produce(conditionGroups, (draft) => {
        if (!draft[idx]) {
          return;
        }
        // todo: fix types here, session condition grup was not compatible
        (draft[idx].conditions as any) = [...(draft[idx].conditions || []), {}];
      })
    );
  };

  const removeConditionGroup = () => {
    setConditionGroups(
      produce(conditionGroups, (draft) => {
        draft.splice(idx, 1);
      })
    );
  };

  return (
    <Box
      component={motion.div}
      animate={{ scale: 1, opacity: 1 }}
      initial={{ scale: 0.8, opacity: 0 }}
      exit={{ y: -50, opacity: 0 }}
      transition={{ ease: "backOut" }}
      sx={{ position: "relative", width: "100%", zIndex: 2 }}
    >
      <GlassCard>
        <Box
          py={3}
          px={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">Match all of the following</Typography>
          <Box pl={6} mt={3} sx={{ width: "100%" }}>
            <AnimatePresence>
              {conditions.map((condition, i) => (
                <ConditionalGroupCondition
                  conditions={conditions}
                  i={i}
                  idx={idx}
                  key={condition.id || i}
                />
              ))}
            </AnimatePresence>
          </Box>
          <Box>
            <Button
              color="primary"
              size="small"
              variant="contained"
              sx={{ minWidth: 100 }}
              disableElevation
              onClick={addCondition}
            >
              + and
            </Button>
          </Box>
        </Box>
      </GlassCard>
      <Box
        sx={{
          position: "absolute",
          right: -20,
          top: "50%",
          transform: "translate(100%, -50%)",
        }}
      >
        <Fab
          size="small"
          onClick={removeConditionGroup}
          disabled={conditionGroups.length === 1}
        >
          <Delete />
        </Fab>
      </Box>
    </Box>
  );
}

export default ConditionalGroupCard;
