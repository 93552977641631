/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiDimensionCategory;
(function (ApiDimensionCategory) {
    ApiDimensionCategory["DIMENSION_CATEGORY_UNKNOWN"] = "DimensionCategoryUnknown";
    ApiDimensionCategory["DIMENSION_CATEGORY_AUDIENCE_SEGMENTS"] = "DimensionCategoryAudienceSegments";
    ApiDimensionCategory["DIMENSION_CATEGORY_CONTENT"] = "DimensionCategoryContent";
    ApiDimensionCategory["DIMENSION_CATEGORY_CONTENT_AND_CONVERSION_JOURNEYS"] = "DimensionCategoryContentAndConversionJourneys";
    ApiDimensionCategory["DIMENSION_CATEGORY_CONVERSIONS_AND_INTERACTIONS"] = "DimensionCategoryConversionsAndInteractions";
    ApiDimensionCategory["DIMENSION_CATEGORY_CRAWLERS"] = "DimensionCategoryCrawlers";
    ApiDimensionCategory["DIMENSION_CATEGORY_DEVICE_AND_TECHNOLOGY"] = "DimensionCategoryDeviceAndTechnology";
    ApiDimensionCategory["DIMENSION_CATEGORY_DOMAIN"] = "DimensionCategoryDomain";
    ApiDimensionCategory["DIMENSION_CATEGORY_JOB_DATA"] = "DimensionCategoryJobData";
    ApiDimensionCategory["DIMENSION_CATEGORY_LANDING_PAGES"] = "DimensionCategoryLandingPages";
    ApiDimensionCategory["DIMENSION_CATEGORY_OTHER"] = "DimensionCategoryOther";
    ApiDimensionCategory["DIMENSION_CATEGORY_PRODUCTS"] = "DimensionCategoryProducts";
    ApiDimensionCategory["DIMENSION_CATEGORY_SESSION_TIMING"] = "DimensionCategorySessionTiming";
    ApiDimensionCategory["DIMENSION_CATEGORY_TRAFFIC_AND_CAMPAIGNS"] = "DimensionCategoryTrafficAndCampaigns";
    ApiDimensionCategory["DIMENSION_CATEGORY_INCENDIUM_CAMPAIGNS"] = "DimensionCategoryIncendiumCampaigns";
    ApiDimensionCategory["DIMENSION_CATEGORY_TRANSACTIONS"] = "DimensionCategoryTransactions";
    ApiDimensionCategory["DIMENSION_CATEGORY_USER_DATA_AND_DEMOGRAPHICS"] = "DimensionCategoryUserDataAndDemographics";
})(ApiDimensionCategory || (ApiDimensionCategory = {}));
export function ApiDimensionCategoryFromJSON(json) {
    return ApiDimensionCategoryFromJSONTyped(json, false);
}
export function ApiDimensionCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiDimensionCategoryToJSON(value) {
    return value;
}
