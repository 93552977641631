import { useCheckLoginStatus } from "Hooks";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { authService } from "Apis";
import Loading from "Components/Loading/Loading";
import { LoginLayout, PasswordReset } from "features/login";

const Msg = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Stack
      spacing={2}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {children}
    </Stack>
  );
};

const Fecthing = () => {
  return (
    <Msg>
      <Typography color={"white"}>Checking email Link</Typography>
      <Loading />
    </Msg>
  );
};

const InvalidLink = () => {
  return (
    <Msg>
      <Typography color={"white"}>Link Invalid</Typography>
    </Msg>
  );
};

function InitialPassword() {
  useCheckLoginStatus();
  const [loading, setLoading] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const { code } = useParams<{ code: string }>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!code) {
      return;
    }

    const load = async (code: string) => {
      try {
        await authService.authValidateResetPasswordCode({
          body: { code },
        });
      } catch (e) {
        setInvalid(true);
        enqueueSnackbar("Internal Server Error", {
          variant: "error",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      }
    };

    load(code);
  }, [code, enqueueSnackbar]);

  if (loading) {
    return <LoginLayout form={<Fecthing />} animateLogo={false} />;
  }
  if (invalid) {
    return <LoginLayout form={<InvalidLink />} animateLogo={false} />;
  }

  return (
    <LoginLayout
      form={<PasswordReset isInitial code={code} />}
      animateLogo={false}
    />
  );
}

export default InitialPassword;
