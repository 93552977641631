import {
  ApiDimension,
  ApiDimensionCategory,
  ApiSimpleDimensionLabel,
} from "@incendium/api";
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StyledTooltip } from "Components/UI/StyledTooltip";
import {
  dimensionCategoryTitle,
  orderGroupedDimensionLabels,
} from "features/analytics";
import { useDimensionLabels } from "features/analytics/hooks/useDimensionLabels";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";
import { dimensionOriginalNameToEnum } from "features/analytics/services/dimensionsFormatter";
import produce from "immer";
import { IChart } from "Interfaces";
import { useChartDescriptionDrawer } from "Providers/ChartDescriptionDrawerProvider";
import { useCallback, useEffect, useMemo, useState } from "react";

interface IChartBuilderDimensionProps {
  selectedDimension: ApiDimension;
  chart: IChart;
  setChart: React.Dispatch<React.SetStateAction<IChart>>;
}

function ChartBuilderDimension({
  selectedDimension,
  chart,
  setChart,
}: IChartBuilderDimensionProps) {
  const { openDrawer } = useChartDescriptionDrawer();
  const dimensionToName = useDimensionName();
  const { dimensionLabels, loading } = useDimensionLabels();
  const [selectedOption, setSelectedOption] = useState<
    ApiSimpleDimensionLabel | undefined
  >(undefined);

  const parsedDimensions = useMemo(() => {
    const sorted = orderGroupedDimensionLabels(dimensionLabels);
    return sorted.map((s) => ({
      ...s,
      category: dimensionCategoryTitle(
        s.category || ApiDimensionCategory.DIMENSION_CATEGORY_UNKNOWN
      ) as ApiDimensionCategory,
    }));
  }, [dimensionLabels]);

  useEffect(() => {
    const idx = parsedDimensions.findIndex(
      (f) =>
        f.dimension === selectedDimension ||
        f.dimension === dimensionOriginalNameToEnum(selectedDimension)
    );
    if (idx < 0) {
      return;
    }

    setSelectedOption({
      ...parsedDimensions[idx],
      label:
        parsedDimensions[idx].label !== ""
          ? parsedDimensions[idx].label
          : (parsedDimensions[idx].dimension as string),
    });
  }, [chart.dimension, parsedDimensions, selectedDimension]);

  const onChange = useCallback(
    (e, value) => {
      setChart((chart) =>
        produce(chart, (draft) => {
          const idx = (draft.dimension || []).findIndex(
            (d) => d === selectedDimension
          );
          if (idx >= 0 && draft.dimension) {
            draft.dimension[idx] = value?.dimension || "";
          }
        })
      );
    },
    [setChart, selectedDimension]
  );

  return (
    <Autocomplete
      id="x"
      size="small"
      onChange={onChange}
      loading={loading}
      value={
        selectedOption || {
          dimension: "" as ApiDimension,
          label: "",
        }
      }
      groupBy={(option) =>
        option.category || ApiDimensionCategory.DIMENSION_CATEGORY_UNKNOWN
      }
      options={parsedDimensions}
      isOptionEqualToValue={(option, value) =>
        option.dimension === value.dimension
      }
      disableClearable
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Dimension"
          variant="outlined"
          size="small"
        />
      )}
      renderOption={(props, option) => {
        return (
          <StyledTooltip
            wide
            title={
              <>
                <Stack px={2} py={1}>
                  <Typography variant="subtitle1" color={"inherit"}>
                    {dimensionToName(option.dimension as ApiDimension)}
                  </Typography>
                  <Typography variant="body1" color={"inherit"}>
                    {option.description || "No description available"}
                  </Typography>
                </Stack>

                <Box mt={1}>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    onClick={() =>
                      openDrawer([option.dimension as ApiDimension], [])
                    }
                  >
                    Click to see full description
                  </Button>
                </Box>
              </>
            }
            placement="left"
            arrow
            enterDelay={500}
          >
            <Box component="li" {...props}>
              {dimensionToName(option.dimension as ApiDimension)}
            </Box>
          </StyledTooltip>
        );
      }}
    />
  );
}

export default ChartBuilderDimension;
