import {
  ApiConditionCustom,
  ApiConversion,
  ApiSessionConditionCustom,
} from "@incendium/api";
import { Box, Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import ConditionalGroup from "Components/ConditionalGroup/ConditionalGroup";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import produce from "immer";
import { useEffect, useMemo, useState } from "react";

export const engagementRuleType = (
  type: string | ApiConditionCustom | ApiSessionConditionCustom
) => {
  switch (type) {
    case "conversion":
    case ApiConditionCustom.CONVERSION_TRIGGERED:
    case ApiSessionConditionCustom.CONVERSION_TRIGGERED:
      return "Conversion (goal achieved)";
    case ApiSessionConditionCustom.CONVERSION_COUNT:
    case ApiConditionCustom.CONVERSION_COUNT:
      return "No. Of All Conversions";
    case ApiSessionConditionCustom.MACRO_CONVERSION_COUNT:
    case ApiConditionCustom.CONVERSION_MACRO_COUNT:
      return "No. Of Conversions";
    case ApiSessionConditionCustom.MICRO_CONVERSION_COUNT:
    case ApiConditionCustom.CONVERSION_MICRO_COUNT:
      return "No. Of Interactions";
    case ApiConditionCustom.TIME_ON_PAGE_OVER:
      return "Time (seconds) on page";
    case ApiSessionConditionCustom.AVG_TIME_ON_PAGE:
      return "Avg. Time (seconds) on visit's pages";
    case ApiConditionCustom.SCROLL_RATE_OVER:
      return "Amount (%) of page scrolled to";
    case ApiConditionCustom.VIEW_RATE_OVER:
      return "Amount (%) of page viewed";
    case ApiConditionCustom.TIME_ON_PAGE_PERCENTILE:
    case ApiSessionConditionCustom.TIME_ON_PAGE_PERCENTILE:
      return "Lead ranks in top (%) - time spent";
    case ApiConditionCustom.SCROLL_RATE_PERCENTILE:
    case ApiSessionConditionCustom.SCROLL_RATE_PERCENTILE:
      return "Lead ranks in top (%) - scroll rate";
    case ApiConditionCustom.VIEW_RATE_PERCENTILE:
    case ApiSessionConditionCustom.VIEW_RATE_PERCENTILE:
      return "Lead ranks in top (%) - view rate";
    case ApiConditionCustom.PAGE_SCORE:
      return "Lead Score gained on pageview";
    case ApiSessionConditionCustom.LEAD_SCORE_DURING_SESSION:
      return "Lead Score gained during visit";
    case ApiSessionConditionCustom.NUMBER_OF_PAGE_VIEWS:
      return "No. of pageviews within the visit";
    case ApiSessionConditionCustom.NUMBER_OF_EFFECTIVE_PAGE_VIEWS:
      return "No. of effective pageviews during a visit";
    case ApiSessionConditionCustom.EFFECTIVE_PAGE_VIEWS_PERCENTAGE:
      return "Amount (%) of effective pageviews / total pageviews in visit";
  }
};

const EngagementRulesRow = ({
  conversions,
  groupIdx,
  rowIdx,
}: {
  conversions: ApiConversion[];
  groupIdx: number;
  rowIdx: number;
}) => {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();

  const condition = useMemo(() => {
    return (conditionGroups[groupIdx]?.conditions || [])[rowIdx] || {};
  }, [conditionGroups, groupIdx, rowIdx]);

  const [type, setType] = useState<string | undefined>("");
  const [types] = useState<string[]>([
    ApiConditionCustom.CONVERSION_TRIGGERED,
    ApiConditionCustom.TIME_ON_PAGE_OVER,
    ApiConditionCustom.SCROLL_RATE_OVER,
    ApiConditionCustom.VIEW_RATE_OVER,
    ApiConditionCustom.TIME_ON_PAGE_PERCENTILE,
    ApiConditionCustom.SCROLL_RATE_PERCENTILE,
    ApiConditionCustom.VIEW_RATE_PERCENTILE,
  ]);

  useEffect(() => {
    setType(condition.custom || ApiConditionCustom.UNSPECIFIED);
  }, [condition]);

  const value = useMemo(() => {
    return (conditionGroups[groupIdx]?.conditions || [])[rowIdx]?.value || "";
  }, [conditionGroups, groupIdx, rowIdx]);

  const conversionId = useMemo(() => {
    return (
      (conditionGroups[groupIdx]?.conditions || [])[rowIdx]?.conversionId || ""
    );
  }, [conditionGroups, groupIdx, rowIdx]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          select
          variant="outlined"
          fullWidth
          label="Type"
          value={type}
          onChange={(e) => {
            setConditionGroups(
              produce(conditionGroups, (draft) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].custom = e.target
                  .value as ApiConditionCustom;
              })
            );
          }}
        >
          {types
            .sort((a, b) => a.localeCompare(b))
            .map((t) => (
              <MenuItem key={t} value={t}>
                {engagementRuleType(t)}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        {type === ApiConditionCustom.CONVERSION_TRIGGERED ? (
          <TextField
            select
            variant="outlined"
            fullWidth
            label="conversion"
            value={conversionId}
            onChange={(e) => {
              setConditionGroups(
                produce(conditionGroups, (draft) => {
                  (draft[groupIdx]?.conditions || [])[rowIdx].conversionId =
                    Number(e.target.value);
                })
              );
            }}
          >
            {conversions.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            label="value"
            value={value}
            fullWidth
            onChange={(e) => {
              setConditionGroups(
                produce(conditionGroups, (draft) => {
                  (draft[groupIdx]?.conditions || [])[rowIdx].value =
                    e.target.value;
                })
              );
            }}
            type={"number"}
            InputProps={{
              startAdornment: [
                ApiConditionCustom.TIME_ON_PAGE_PERCENTILE,
                ApiConditionCustom.SCROLL_RATE_PERCENTILE,
                ApiConditionCustom.VIEW_RATE_PERCENTILE,
              ].includes(type as ApiConditionCustom) ? (
                <InputAdornment position="start">%</InputAdornment>
              ) : (
                ""
              ),
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

function EngagementRules({ conversions }: { conversions: ApiConversion[] }) {
  const { setRowComponent } = useConditionGroupContext();

  useEffect(() => {
    setRowComponent(() => (groupIdx, rowIdx) => {
      return (
        <EngagementRulesRow
          groupIdx={groupIdx}
          rowIdx={rowIdx}
          conversions={conversions}
        />
      );
    });
  }, [conversions]);

  return (
    <Box
      sx={{
        width: 800,
        maxWidth: "80%",
        margin: "auto",
      }}
    >
      <ConditionalGroup></ConditionalGroup>
    </Box>
  );
}

export default EngagementRules;
