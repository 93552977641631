import { listDimensionLabels } from "features/analytics/services/analyticsLabelService";
import { useSharedState } from "Hooks/useSharedState";

export const useDimensionLabels = () => {
  const fetch = async () => {
    const res = await listDimensionLabels();
    return res;
  };
  const { state, setState, loading } = useSharedState(
    "dimensionLabels",
    [],
    fetch,
    { isGlobal: true }
  );

  return {
    dimensionLabels: state,
    setDimensionLables: setState,
    loading,
  };
};
