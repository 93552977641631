import { ApiSimpleDimensionLabel, ApiSimpleMetricLabel } from "@incendium/api";
import { Check, Close, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "Components/Loading/Loading";
import SpacedList from "Components/UI/SpacedList";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer, TabWrapper } from "Components/UI/TabContainer";
import { cell1Icon } from "consts";
import { useDimensionLabels } from "features/analytics/hooks/useDimensionLabels";
import { useMetricLabels } from "features/analytics/hooks/useMetricLabels";
import { sortAlpha } from "Helpers/arrays";
import useAppendSearchState from "Hooks/useAppendSearchState";
import { useCallback, useMemo, useState } from "react";
import { camelToText } from "Helpers/camelToText";
import AnalyticsLabelDrawer from "features/analytics/components/AnalyticsLabelDrawer";
import {
  readDimensionLabel,
  readMetricLabel,
  saveDimensionLabel,
  saveMetricLabel,
} from "features/analytics/services/analyticsLabelService";
import produce from "immer";
import { useNotification } from "Hooks";
import { truncate } from "Helpers/truncate";

function AnalyticsLabelsPage() {
  const {
    metricLabels,
    setMetricLables,
    loading: metricsLoading,
  } = useMetricLabels();
  const {
    dimensionLabels,
    setDimensionLables,
    loading: dimensionsLoading,
  } = useDimensionLabels();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const loading = useMemo(
    () => dimensionsLoading || metricsLoading,
    [dimensionsLoading, metricsLoading]
  );
  const [tab, setTab] = useAppendSearchState<"metric" | "dimension">(
    "al",
    "metric"
  );
  const [open, setOpen] = useState(false);
  const [fetchLoading, setFetchLoading] = useState<
    ApiSimpleMetricLabel | ApiSimpleDimensionLabel | null
  >(null);
  const [selectedLabel, setSelectedLabel] = useState<
    ApiSimpleMetricLabel | ApiSimpleDimensionLabel
  >({});
  const [search, setSearch] = useState("");

  const labels = useMemo(() => {
    const arr =
      tab === "metric"
        ? sortAlpha(metricLabels || [], "metric")
        : sortAlpha(dimensionLabels || [], "dimension");

    return search.length >= 3
      ? [...arr].filter(
          (l) =>
            (l.label || "").toLowerCase().includes(search.toLowerCase()) ||
            (l["metric"] || "").toLowerCase().includes(search.toLowerCase()) ||
            (l["dimension"] || "").toLowerCase().includes(search.toLowerCase())
        )
      : arr;
  }, [tab, metricLabels, dimensionLabels, search]);

  const updateLabels = useCallback(
    (res) => {
      if (tab === "metric") {
        setMetricLables((l) =>
          produce(l, (draft) => {
            const idx = draft.findIndex((f) => f.metric === res["metric"]);
            if (idx >= 0) {
              draft[idx] = res as ApiSimpleMetricLabel;
            }
          })
        );
      } else {
        setDimensionLables((l) =>
          produce(l, (draft) => {
            const idx = draft.findIndex(
              (f) => f.dimension === res["dimension"]
            );
            if (idx >= 0) {
              draft[idx] = res as ApiSimpleDimensionLabel;
            }
          })
        );
      }
    },
    [tab, setDimensionLables, setMetricLables]
  );

  const loadLabel = useCallback(
    async (label) => {
      if (label.article && label.article.length > 0) {
        setSelectedLabel(label);
      } else {
        const fn =
          tab === "metric"
            ? readMetricLabel(label["metric"])
            : readDimensionLabel(label["dimension"]);
        const res = await fn;
        setSelectedLabel(res || label);
        updateLabels(res || label);
      }
    },
    [tab, updateLabels]
  );

  const onEdit = useCallback(
    async (label) => {
      setFetchLoading(label);
      await loadLabel(label);
      setFetchLoading(null);
      setOpen(true);
    },
    [loadLabel]
  );

  const onClose = useCallback(() => {
    setOpen(false);
    setSelectedLabel({});
  }, []);

  const onSubmit = useCallback(
    async (label) => {
      try {
        const fn =
          tab === "metric"
            ? saveMetricLabel(label["metric"], label as ApiSimpleMetricLabel)
            : saveDimensionLabel(
                label["dimension"],
                label as ApiSimpleDimensionLabel
              );
        const res = await fn;

        if (!res) {
          return;
        }
        updateLabels(res);
        showSuccessNotification("Label Updated");
      } catch (error) {
        showErrorNotification("Error Updating Label");
      } finally {
        onClose();
      }
    },
    [tab, showSuccessNotification, showErrorNotification, onClose, updateLabels]
  );

  const catToText = useCallback(
    (t) =>
      camelToText(t)
        .replace("Metric Category", "")
        .replace("Dimension Category", ""),
    []
  );

  const field = useMemo(
    () => (tab === "metric" ? "metric" : "dimension"),
    [tab]
  );

  if (loading) {
    return <Loading fullHeight />;
  }
  return (
    <>
      <TabContainer fullWidth>
        <TabButton onClick={() => setTab("metric")} isActive={tab === "metric"}>
          Metrics
        </TabButton>
        <TabButton
          onClick={() => setTab("dimension")}
          isActive={tab === "dimension"}
        >
          Dimensions
        </TabButton>
      </TabContainer>
      <TabWrapper>
        <Box my={4}>
          <SpacedList
            title={`List Of ${tab === "metric" ? "Metrics" : "Dimensions"}`}
            action={
              <Stack direction={"row"} alignItems="flex-end" spacing={2}>
                <Typography color="secondary">
                  {labels.length} Results
                </Typography>
                <TextField
                  size="small"
                  label="Search"
                  value={search}
                  sx={{ minWidth: 300 }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Stack>
            }
          >
            <TableHead>
              <TableRow>
                <TableCell width={200}>Internal Name</TableCell>
                <TableCell>Label</TableCell>
                <TableCell width={190}>Grouping / Category</TableCell>
                <TableCell>Short Description</TableCell>
                <TableCell width={120}>Has Article?</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {labels.map((label) => (
                <TableRow key={label[field]}>
                  <TableCell>{label[field]}</TableCell>
                  <TableCell>{label.label}</TableCell>
                  <TableCell>{catToText(label.category)}</TableCell>
                  <TableCell>{truncate(label.description || "", 40)}</TableCell>
                  <TableCell>
                    {label.hasArticle ? (
                      <Check fontSize="small" />
                    ) : (
                      <Close fontSize="small" />
                    )}
                  </TableCell>
                  <TableCell align="right" style={{ width: cell1Icon }}>
                    <IconButton
                      onClick={() => onEdit(label)}
                      size="small"
                      color="primary"
                      disabled={label[field] === fetchLoading?.[field]}
                    >
                      {label[field] === fetchLoading?.[field] ? (
                        <Loading size={24} />
                      ) : (
                        <Edit />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </SpacedList>
        </Box>
      </TabWrapper>
      <AnalyticsLabelDrawer
        open={open}
        onClose={onClose}
        label={selectedLabel}
        type={tab || "metric"}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default AnalyticsLabelsPage;
