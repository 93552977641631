import { SvgIcon, SvgIconProps } from "@mui/material";

function LightbulbIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 538 538">
      <g
        transform="translate(0.000000,538.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M2569 4829 c-417 -29 -785 -192 -1045 -461 -218 -225 -325 -476 -325 -763 -1 -251 79 -454 366 -935 267 -448 374 -699 375 -882 l0 -48 750 0 750 0 0 49 c0 62 22 169 56 269 42 127 142 322 309 601 225 379 295 518 345 698 62 217 36 468 -71 692 -81 171 -196 314 -357 442 -229 184 -569 313 -874 334 -46 3 -103 7 -128 9 -25 2 -93 0 -151 -5z" />
        <path d="M2058 1560 c-79 -24 -127 -100 -108 -170 12 -46 65 -95 117 -109 52 -15 1194 -15 1246 0 50 14 104 63 116 105 20 72 -30 152 -107 174 -45 12 -1223 12 -1264 0z" />
        <path d="M2068 1096 c-85 -30 -121 -73 -121 -140 0 -80 75 -145 169 -146 39 0 43 -3 78 -59 96 -157 284 -252 496 -252 212 0 400 95 496 252 35 57 38 59 78 59 103 0 190 92 167 177 -12 43 -37 71 -91 99 -33 18 -71 19 -635 21 -483 2 -607 0 -637 -11z" />
      </g>
    </SvgIcon>
  );
}

export default LightbulbIcon;
