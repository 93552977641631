import { ApiMetric } from "@incendium/api";
import { useMetricName } from "features/analytics/hooks/useMetricName";
import { useCallback } from "react";

export const useLabelMetricToName = () => {
  const metricToName = useMetricName();
  return useCallback(
    (item: string) => {
      const parts = item.includes("\\") ? item.split("\\") : item.split(" ");
      // remove previous
      let name = parts[parts.length - 1];
      parts[parts.length - 1] = metricToName(name as ApiMetric);
      let modifiedString = parts.join(" ");
      const isPrevious = modifiedString.includes("previous_");
      modifiedString = isPrevious
        ? modifiedString.replace("previous_", "")
        : modifiedString;
      return isPrevious ? `Previous ${modifiedString}` : modifiedString;
    },
    [metricToName]
  );
};
