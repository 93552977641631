import { ApiSimpleSourceCategory } from "@incendium/api";
import SourceCategoriesDrawer from "features/sourceCategories/components/SourceCategoriesDrawer";
import SourceCategoriesList from "features/sourceCategories/components/SourceCategoriesList";
import { useSourceCategories } from "features/sourceCategories/hooks/useSourceCategories";
import { deleteSourceCategory } from "features/sourceCategories/services/sourceCategoryService";
import withPagetitle from "HoC/withPagetitle";
import { useSelectedProject } from "Hooks";
import { useConfirmation } from "Hooks/useConfirmation";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";

function SourcCategoriesPage() {
  const { selectedProject } = useSelectedProject();
  const { setSourceCategories } = useSourceCategories();
  const handleDelete = useConfirmation();
  const [selectedCategory, setSelectedCategory] =
    useState<ApiSimpleSourceCategory>({});

  const [open, setOpen] = useState(false);
  const onNew = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onEdit = useCallback((cat: ApiSimpleSourceCategory) => {
    setSelectedCategory(cat);
    setOpen(true);
  }, []);

  const onDelete = useCallback(
    (cat: ApiSimpleSourceCategory) => {
      if (!cat.id || !selectedProject?.id) {
        return;
      }
      handleDelete({
        title: `Are you sure you want to delete this category?`,
        body: `This action will effect your calculated channels if they use this category.`,
        callback: async () => {
          await deleteSourceCategory(selectedProject.id!, cat.id!);
          setSourceCategories((c) =>
            produce(c, (draft) => {
              const idx = draft.findIndex((c) => c.id === cat.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );

          return "Category Deleted";
        },
      });
    },
    [handleDelete, selectedProject, setSourceCategories]
  );

  useEffect(() => {
    if (!open) {
      setSelectedCategory({});
    }
  }, [open]);

  const onSaved = useCallback(
    (res: ApiSimpleSourceCategory) => {
      setSourceCategories((c) =>
        produce(c, (draft) => {
          const idx = draft.findIndex((c) => c.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          } else {
            draft.push(res);
          }
        })
      );
      setOpen(false);
    },
    [setSourceCategories]
  );

  return (
    <div>
      <SourceCategoriesList
        onClick={onNew}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <SourceCategoriesDrawer
        open={open}
        onClose={onClose}
        onSaved={onSaved}
        projectId={selectedProject!.id!}
        category={selectedCategory}
      />
    </div>
  );
}

export default withPagetitle(SourcCategoriesPage);
