import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

interface IStyledTooltipProps extends TooltipProps {
  wide?: boolean;
}

export const StyledTooltip = styled(
  ({ className, ...props }: IStyledTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "wide",
  }
)(({ theme, wide }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    textShadow: "none",
    border: `2px solid ${theme.palette.primary.main}`,
    padding: 0,
    textAlign: "center",
    maxWidth: wide ? 700 : undefined,
    minWidth: 150,
    "& .MuiButtonGroup-root": {
      boxShadow: "none",
      overflow: "hidden",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    "& .MuiButtonBase-root": {
      borderRadius: 0,
      borderColor: "white",
    },
    "& .MuiButton-icon svg": {
      fontSize: 16,
    },
    "& .MuiStack-root": {
      overflow: "hidden",
    },
  },
}));
