import { Box, Grid, Stack, useTheme } from "@mui/material";
import { StatCardTypography } from "Components/UI/StatCard";
import { useState } from "react";
import AudienceEffectiveCard from "./AudienceEffectiveCard";
import { useAudienceAnalyticsContext } from "Providers/AudienceAnalyticsProvider";
import { AudiencePickerSubAction } from "Pages/Analyse/Audiences";
import {
  AnalyticsIncreaseDecreaseStatCard,
  AnalyticsStatCard,
  percentageTopOfTotal,
} from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { formatNumber } from "Helpers/numbers";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { useMetricName } from "features/analytics/hooks/useMetricName";

function AudienceOverviewTab() {
  const metricToName = useMetricName();
  const [metric, setMetric] = useState<unknown>(
    ApiMetric.METRIC_SESSIONS_COUNT
  );
  const { audienceAttributes } = useAudienceAnalyticsContext();
  const theme = useTheme();

  return (
    <>
      <AudiencePickerSubAction />
      <Grid container spacing={AnalyticsSpacing}>
        <Grid item xs={12}>
          <Stack spacing={AnalyticsSpacing}>
            <Stack direction={"row"} spacing={AnalyticsSpacing}>
              <Stack
                sx={{ minWidth: 313, width: 313 }}
                spacing={AnalyticsSpacing}
              >
                <AnalyticsStatCard
                  chart={{
                    name: "",
                    dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: audienceAttributes,
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [metric as ApiMetric],
                      },
                    ],
                  }}
                  renderBody={(data) => (
                    <>
                      <StatCardTypography size="xs" opactity={0.8} mb={1}>
                        Highest Performing Audience:
                      </StatCardTypography>
                      <StatCardTypography size="large" lines={1}>
                        {data[0]?.name}
                      </StatCardTypography>
                    </>
                  )}
                />
                <AnalyticsStatCard
                  chart={{
                    name: "",
                    dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: audienceAttributes,
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [metric as ApiMetric],
                      },
                    ],
                  }}
                  renderBody={(data) => (
                    <>
                      <StatCardTypography size="xs" opactity={0.8}>
                        Generating
                      </StatCardTypography>
                      <StatCardTypography mb={1} size="large">
                        {formatNumber(
                          percentageTopOfTotal(data, metric as ApiMetric)
                        )}
                        %
                      </StatCardTypography>
                      <StatCardTypography size="xs" opactity={0.8} dense>
                        of total {metricToName(metric as ApiMetric)}
                      </StatCardTypography>
                    </>
                  )}
                />
                <AnalyticsIncreaseDecreaseStatCard
                  metric={ApiMetric.METRIC_SESSIONS_COUNT}
                  chart={{
                    name: "",
                    dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
                    template: ApiChartTemplate.SNAPSHOT,
                    type: ApiChartType.GRAPH,
                    attributes: audienceAttributes,
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [metric as ApiMetric],
                      },
                    ],
                  }}
                />
              </Stack>
              <Box sx={{ flex: 1 }}>
                <AnalyticsCard
                  chart={{
                    name: "Channel Performance by",
                    dimension: [
                      ApiDimension.DIMENSION_SESSION_START_BY_DAY,
                      ApiDimension.DIMENSION_LEAD_AUDIENCE,
                    ],
                    template: ApiChartTemplate.TREND,
                    type: ApiChartType.GRAPH,
                    attributes: audienceAttributes,
                    yAxisKeys: [
                      {
                        key: "l",
                        fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                      },
                    ],
                    displayOptions: {
                      noFilters: true,
                      availableMetrics: [
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
                        ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                        ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                        ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                        ApiMetric.METRIC_LEADS_COUNT,
                        ApiMetric.METRIC_NEW_LEADS_COUNT,
                        ApiMetric.METRIC_RETURNING_LEADS_COUNT,
                        ApiMetric.METRIC_SESSION_REVENUE,
                        // "AOV",
                        ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
                        ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
                      ],
                      noAttributionToggle: true,
                    },
                  }}
                  onChangeMetric={(m) => setMetric(m)}
                />
              </Box>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={8}>
          <AudienceEffectiveCard attributes={audienceAttributes} />
        </Grid>
        <Grid item xs={4} sx={{ minHeight: 406 }}>
          <AnalyticsCard
            chart={{
              name: "Session Comparisons in the Selected Period vs. Previous Same Period",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              comparison: true,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                  chart: {
                    [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                  },
                },
              ],
              displayOptions: {
                noLegend: true,
                noFilters: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: 450 }}>
          <AnalyticsCard
            chart={{
              name: "Avg. Conversions Per Audience vs Avg. Interactions by Audience",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              displayOptions: {
                noLegend: true,
                noFilters: true,
                fills: {
                  [ApiMetric.METRICMICRO_CONVERSION_COUNT_PER_LEAD]:
                    theme.palette.analytics.green,
                },
              },
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRICMACRO_CONVERSION_COUNT_PER_LEAD],
                  chart: {
                    [ApiMetric.METRICMACRO_CONVERSION_COUNT_PER_LEAD]:
                      ApiYAxisChartType.BAR,
                  },
                },
                {
                  key: "r",
                  fields: [ApiMetric.METRICMICRO_CONVERSION_COUNT_PER_LEAD],
                  chart: {
                    [ApiMetric.METRICMICRO_CONVERSION_COUNT_PER_LEAD]:
                      ApiYAxisChartType.DOT,
                  },
                },
              ],
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: 450 }}>
          <AnalyticsCard
            chart={{
              name: "Conversion Comparisons in the Selected Period vs. Previous Same Period",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              comparison: true,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                  chart: {
                    [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]:
                      ApiYAxisChartType.BAR,
                  },
                },
              ],
              displayOptions: {
                noLegend: true,
                noFilters: true,
              },
            }}
          />
        </Grid>

        <Grid item xs={6} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Composition by",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                },
              ],
              displayOptions: {
                noFilters: true,
                showTotals: true,
                availableMetrics: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                  ApiMetric.METRIC_LEADS_COUNT,
                  ApiMetric.METRIC_NEW_LEADS_COUNT,
                  ApiMetric.METRIC_RETURNING_LEADS_COUNT,
                ],
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AnalyticsCard
            chart={{
              name: "Session & Engagement Levels by Audience",
              dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              displayOptions: {
                noFilters: true,
                metricsOrder: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                ],
                fills: {
                  [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE]:
                    theme.palette.analytics.green,
                },
                noLegend: true,
              },
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                  chart: {
                    [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                  },
                },
                {
                  key: "r",
                  fields: [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE],
                  chart: {
                    [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE]:
                      ApiYAxisChartType.DOT,
                  },
                },
              ],
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Primary Stats by Marketing Channels",
              dimension: [
                ApiDimension.DIMENSION_CHANNEL,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                  chart: {
                    [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                    [ApiMetric.METRIC_PAGE_VIEWS_COUNT]: ApiYAxisChartType.BAR,
                    [ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE]:
                      ApiYAxisChartType.BAR,
                    [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE]:
                      ApiYAxisChartType.BAR,
                    [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE]:
                      ApiYAxisChartType.BAR,
                    [ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE]:
                      ApiYAxisChartType.BAR,
                  },
                },
              ],
              displayOptions: {
                noLegend: true,
                noFilters: true,
                availableMetrics: [
                  ApiMetric.METRIC_SESSIONS_COUNT,
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                  ApiMetric.METRIC_LEADS_COUNT,
                  ApiMetric.METRIC_NEW_LEADS_COUNT,
                  ApiMetric.METRIC_RETURNING_LEADS_COUNT,
                ],
              },
            }}
          />
        </Grid>

        <Grid item xs={6} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Primary Stats by Marketing Channels",
              dimension: [
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
                ApiDimension.DIMENSION_CHANNEL,
              ],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                    ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                    ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                    ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
                    ApiMetric.METRIC_LEADS_COUNT,
                    ApiMetric.METRIC_NEW_LEADS_COUNT,
                    ApiMetric.METRIC_RETURNING_LEADS_COUNT,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience ROAS & LTV Stats by Marketing Channels",
              dimension: [
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
                ApiDimension.DIMENSION_CHANNEL,
              ],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_SESSION_REVENUE,
                    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
                    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
                    ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_LTV,
                    ApiMetric.METRIC_SPEND,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
                noAttributionToggle: true,
              },
            }}
          />
        </Grid>

        <Grid item xs={6} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Total Sessions by User Types",
              dimension: [
                ApiDimension.DIMENSION_USER_TYPE,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                  stackId: "l",
                  chart: {
                    [ApiMetric.METRIC_SESSIONS_COUNT]: ApiYAxisChartType.BAR,
                  },
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>

        <Grid item xs={6} sx={{ minHeight: 500 }}>
          <AnalyticsCard
            chart={{
              name: "Audience Conversions by User Types",
              dimension: [
                ApiDimension.DIMENSION_USER_TYPE,
                ApiDimension.DIMENSION_LEAD_AUDIENCE,
              ],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.GRAPH,
              attributes: audienceAttributes,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT],
                  stackId: "l",
                  chart: {
                    [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]:
                      ApiYAxisChartType.BAR,
                  },
                },
              ],
              displayOptions: {
                noFilters: true,
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AudienceOverviewTab;
