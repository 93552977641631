import {
  ApiChartTemplate,
  ApiChartType,
  ApiYAxisChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
} from "@incendium/api";
import {
  Delete,
  Edit,
  Error,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Card,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { chartService } from "Apis";
import { useSelectedProject } from "Hooks";
import { useCharts } from "Hooks/useCharts";
import produce from "immer";
import { IChart } from "Interfaces";
import { useSnackbar } from "notistack";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { memo, useMemo, useState } from "react";
import { friendlyOperator } from "Helpers/operators";
import { ReactComponent as AreaGraphic } from "Assets/Images/graphic-datadisplay-area.svg";
import { ReactComponent as BarGraphic } from "Assets/Images/graphic-datadisplay-bar.svg";
import { ReactComponent as BubbleGraphic } from "Assets/Images/graphic-datadisplay-bubble.svg";
import { ReactComponent as LineGraphic } from "Assets/Images/graphic-datadisplay-line.svg";
import { ReactComponent as MapGraphic } from "Assets/Images/graphic-datadisplay-map.svg";
import { ReactComponent as MixGraphic } from "Assets/Images/graphic-datadisplay-mix.svg";
import { ReactComponent as PieGraphic } from "Assets/Images/graphic-datadisplay-pie.svg";
import { ReactComponent as StackGraphic } from "Assets/Images/graphic-datadisplay-stack.svg";
import { ReactComponent as TableGraphic } from "Assets/Images/graphic-datadisplay-table.svg";
import { StyledMiddleBox } from "Components/UI/StylesFlexBox";
import { StyledWhiteIconBtn } from "Components/Button/StyledButtons";
import { useUpdateEffect } from "react-use";
import { MulitlineElipsed } from "Components/UI/StyledTypograpy";
import { chartTemplateToColour, chartTemplateToIcon } from "Helpers/chartFns";
import { useMetricName } from "features/analytics/hooks/useMetricName";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";

const ChartHero = ({ chart }: { chart: IChart }) => {
  const background = useMemo(() => {
    return chartTemplateToColour(chart?.template);
  }, [chart]);

  const icon = useMemo(() => {
    return chartTemplateToIcon(chart?.template);
  }, [chart]);

  const graphic = useMemo(() => {
    if (chart.template === ApiChartTemplate.MAP) {
      return <MapGraphic />;
    }

    switch (chart.type) {
      case ApiChartType.GRAPH:
        if (
          chart.yAxisKeys.filter((y) => typeof y !== "string" && y.stackId)
            .length > 0
        ) {
          return <StackGraphic />;
        }

        const types = [
          ...new Set(
            chart.yAxisKeys
              .map((y) =>
                typeof y === "string" ? ["line"] : Object.values(y.chart || {})
              )
              .flat()
          ),
        ];

        if (types.length > 1 && types.every((v) => v === types[0])) {
          return <MixGraphic />;
        } else {
          switch (types[0] as ApiYAxisChartType) {
            case ApiYAxisChartType.BAR:
              return <BarGraphic />;
            case ApiYAxisChartType.LINE:
              return <LineGraphic />;
            case ApiYAxisChartType.AREA:
              return <AreaGraphic />;
            default:
              break;
          }
        }
        return <LineGraphic />;

      case ApiChartType.TABLE:
        return <TableGraphic />;
      case ApiChartType.PIE:
        return <PieGraphic />;
      case ApiChartType.RADAR:
        return <>?</>;
      case ApiChartType.BUBBLE:
        return <BubbleGraphic />;

      default:
        break;
    }
  }, [chart]);

  return (
    <Stack
      direction="row"
      sx={{
        height: 100,
        width: "100%",
        background,
        color: "white",
      }}
    >
      <Box sx={{ width: 90, padding: "25px 20px" }}>{icon}</Box>
      <StyledMiddleBox sx={{ flex: 1, background: "rgba(255,255,255, 0.29)" }}>
        {graphic}
      </StyledMiddleBox>
    </Stack>
  );
};

function ChartLibraryCard({
  chart,
  onClick,
  noDelete,
  setShowBackdrop,
  selectOnly,
}: {
  chart: IChart;
  onClick: (c: IChart) => void;
  noDelete?: boolean;
  setShowBackdrop?: React.Dispatch<React.SetStateAction<boolean>>;
  selectOnly?: boolean;
}) {
  const theme = useTheme();
  const { selectedProject } = useSelectedProject();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { charts, setCharts } = useCharts();
  const [expanded, setExpanded] = useState(false);
  const metricToName = useMetricName();
  const dimensionToName = useDimensionName();

  useUpdateEffect(() => {
    if (setShowBackdrop) {
      setShowBackdrop(expanded);
    }
  }, [expanded]);

  const metrics: string[] = useMemo(() => {
    return (chart.yAxisKeys || []).reduce((agg: any, y) => {
      if (typeof y === "string") {
        return [...agg, y];
      }
      return [...agg, ...(y.fields || [])];
    }, []);
  }, [chart.yAxisKeys]);

  const onDelete = () => {
    openConfirmation({
      title: (
        <>
          <Error color="error" fontSize="large" />
          <Typography variant="body1" fontWeight={"bold"}>
            Are you sure you want to delete this chart.
          </Typography>
          <Typography variant="body1" fontWeight={"bold"}>
            "{chart.name}"?
          </Typography>
        </>
      ),
      callback: async () => {
        try {
          await chartService.chartServiceDeleteChart({
            projectId: selectedProject?.id as number,
            chartId: chart?.id as number,
          });

          setCharts(
            produce(charts, (draft) => {
              const idx = draft.findIndex((c) => c.id === chart.id);
              if (idx !== -1) draft.splice(idx, 1);
            })
          );
          enqueueSnackbar(`${chart.name} Deleted`, {
            variant: "success",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
        } catch (error: any) {
          const t = await error.json();
          if (t.code === 9) {
            openConfirmation({
              title: `This chart is used in live reports.`,
              body: t.message,
              callback: async () => {
                try {
                  await chartService.chartServiceDeleteChart({
                    projectId: selectedProject?.id as number,
                    chartId: chart?.id as number,
                    queryToken: t.details[0].message,
                  });

                  setCharts(
                    produce(charts, (draft) => {
                      const idx = draft.findIndex((c) => c.id === chart.id);
                      if (idx !== -1) draft.splice(idx, 1);
                    })
                  );
                  enqueueSnackbar(`${chart.name} Deleted`, {
                    variant: "success",
                    autoHideDuration: 2000,
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                  });
                } catch (error) {
                  enqueueSnackbar("Something Went Wrong", {
                    variant: "error",
                    autoHideDuration: 2000,
                    anchorOrigin: { horizontal: "right", vertical: "top" },
                  });
                }

                closeConfirmation();
              },
            });
            return;
          }

          enqueueSnackbar("Something Went Wrong", {
            variant: "error",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
        }
        closeConfirmation();
      },
    });
  };

  return (
    <Card
      sx={{
        position: expanded ? "absolute" : "relative",
        borderRadius: 6,
        zIndex: expanded ? 1 : undefined,
        width: "100%",
      }}
      elevation={0}
    >
      <ChartHero chart={chart} />

      <Box
        px={2}
        py={1}
        sx={{ position: "relative", height: expanded ? "auto" : 58 }}
      >
        <Link
          component={MulitlineElipsed}
          variant="body1"
          fontWeight={"bold"}
          onClick={() => onClick(chart)}
          sx={{
            textWrap: "pretty",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          {chart.name}
        </Link>
        {expanded && (
          <Box mt={1}>
            <Divider />
            <Box mt={1} mb={0.5}>
              <Typography
                variant="subtitle2"
                display={"inline"}
                lineHeight={1.3}
              >
                Dimensions:{" "}
                <Typography
                  variant="caption"
                  display={"inline"}
                  lineHeight={1.3}
                >
                  {(chart.dimension || [])
                    .map((d) => dimensionToName(d as ApiDimension))
                    .join(", ")}
                </Typography>
              </Typography>
            </Box>
            <Box mb={0.5}>
              <Typography
                variant="subtitle2"
                display={"inline"}
                lineHeight={1.3}
              >
                Metrics:{" "}
                <Typography
                  variant="caption"
                  display={"inline"}
                  lineHeight={1.3}
                >
                  {(metrics || [])
                    .map((m) => metricToName(m as ApiMetric))
                    .join(", ")}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                display={"inline"}
                lineHeight={1.3}
              >
                Attributes:{" "}
                {(chart.attributes || []).map((a, i) => (
                  <Typography
                    key={i}
                    variant="caption"
                    display={"inline"}
                    lineHeight={1.3}
                  >
                    {dimensionToName(a.key as ApiDimension)}{" "}
                    {friendlyOperator(
                      a.operator
                        ? (a.operator as ApiOperator)
                        : ApiOperator.EQUAL
                    )}{" "}
                    {a.value}
                    {i < (chart.attributes || []).length - 1 && ", "}
                  </Typography>
                ))}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <Stack
        spacing={1}
        p={0.8}
        direction={"row"}
        justifyContent="center"
        sx={{ background: theme.palette.secondary.main }}
      >
        {selectOnly ? (
          <Link
            component={MulitlineElipsed}
            variant="body1"
            fontWeight={"bold"}
            color="inherit"
            onClick={() => onClick(chart)}
            sx={{
              color: "white",

              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            Select
          </Link>
        ) : (
          <>
            <StyledWhiteIconBtn
              size="extraSmall"
              onClick={() => onClick(chart)}
            >
              <Edit />
            </StyledWhiteIconBtn>
            <StyledWhiteIconBtn
              size="extraSmall"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <VisibilityOff /> : <Visibility />}
            </StyledWhiteIconBtn>

            {!noDelete && (
              <StyledWhiteIconBtn size="extraSmall" onClick={onDelete}>
                <Delete />
              </StyledWhiteIconBtn>
            )}
          </>
        )}
      </Stack>
    </Card>
  );
}

export default memo(ChartLibraryCard);
