import { ApiDimension } from "@incendium/api";
import {
  getFilterableDimensions,
  getFilterableDimensionValues,
} from "features/analytics/services/analyticsService";
import { useSelectedProject } from "Hooks";
import { useSharedState } from "Hooks/useSharedState";
import produce from "immer";
import { TFilterableDimensions } from "Interfaces";
import { useCallback } from "react";

// used to cache request, when multiple compoennts calling get values at once
const fetchCache: Partial<Record<ApiDimension, Promise<string[]>>> = {};

const useFilterableDimensions = () => {
  const { selectedProject } = useSelectedProject();

  const fetch = async () => {
    if (selectedProject) {
      const res = await getFilterableDimensions(selectedProject.id as number);
      let result = {} as TFilterableDimensions;
      (res || []).forEach((value) => {
        result[value] = {
          names: [],
          status: "UNFETCHED",
        };
      });
      return result;
    }
    return {} as TFilterableDimensions;
  };
  const { state, setState, refetch, loading } = useSharedState<
    TFilterableDimensions,
    TFilterableDimensions
  >("filterableDimensions", {} as TFilterableDimensions, fetch);

  const getDimensionValues = useCallback(
    async (dimension: ApiDimension) => {
      if (!selectedProject?.id) {
        return;
      }
      const o = state[dimension];
      if (!o) {
        return [];
      }

      if (o.status === "FETCHED") {
        return o.names;
      }

      if (fetchCache[dimension]) {
        return fetchCache[dimension];
      }

      const fetchPromise = getFilterableDimensionValues(
        selectedProject.id as number,
        dimension
      )
        .then((res) => {
          setState(
            produce(state, (draft) => {
              if (draft[dimension]) {
                draft[dimension].status = "FETCHED";
                draft[dimension].names = res || [];
              }
            })
          );

          // Clear the cache after resolving
          delete fetchCache[dimension];

          return res || [];
        })
        .catch((error) => {
          console.error("Error fetching dimension values:", error);

          // Clear the cache if the call fails
          delete fetchCache[dimension];

          return [];
        });

      // Store the ongoing promise in the global cache
      fetchCache[dimension] = fetchPromise;

      return fetchPromise;
    },
    [state, setState, selectedProject]
  );

  return {
    filterableDimensions: state as TFilterableDimensions,
    setFilterableDimensions: setState,
    refetch,
    loading,
    getDimensionValues,
  };
};

export default useFilterableDimensions;
