import { ApiDimension, ApiMetric } from "@incendium/api";
import { Box } from "@mui/material";
import FullWidthHeightGlass from "Components/Page/FullWidthHeightGlass";
import { dimensionNameToEnum } from "features/analytics";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";
import {
  ExplorerSidebar,
  ExplorerTable,
  useExplorerContext,
} from "features/explorer";
import { useEffect, useMemo } from "react";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface IContentExplorerProps {
  noTabs?: boolean;
  metrics: ApiMetric[];
  limit?: number;
  dimensionNoClick?: boolean;
  colWidths?: { [key: string]: number };
}

function ContentExplorer({
  noTabs,
  metrics,
  limit,
  dimensionNoClick,
  colWidths,
}: IContentExplorerProps) {
  const { dimension, setAttributes, setDimension } = useExplorerContext();
  const history = useHistory();
  const location = useLocation();
  const dimensionToName = useDimensionName();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const onClick = useCallback(
    (s, o) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(
        [
          ApiDimension.DIMENSION_CONVERTING_SESSION_JOURNEY,
          ApiDimension.DIMENSION_SALES_SESSION_JOURNEY,
        ].includes(dimension)
          ? dimensionToName(ApiDimension.DIMENSION_LOCATION)
          : dimensionToName(dimension),
        typeof s === "string" ? s : s[dimension]
      );
      const updatedSearchString = searchParams.toString();
      history.push({
        pathname: "./content-performance-analysis",
        search: updatedSearchString,
      });
    },
    [dimension, history]
  );

  useEffect(() => {
    queryParams.forEach((v, k) => {
      if (k !== "tab") {
        setAttributes([
          {
            key: dimensionNameToEnum(k),
            value: v || "",
          },
        ]);
        setDimension(ApiDimension.DIMENSION_BROWSER);
        return;
      }
    });
  }, [queryParams, setAttributes, setDimension]);

  return (
    <FullWidthHeightGlass height={`calc(100vh - ${noTabs ? 128 : 165}px)`}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `73% 27%`,
          height: "100%",
        }}
      >
        <Box pr={1} sx={{ flex: 1 }}>
          <ExplorerTable
            onClick={
              queryParams.size === 0 && !dimensionNoClick
                ? { [dimension]: onClick }
                : undefined
            }
            metrics={metrics}
            limit={limit}
            colWidths={colWidths}
          />
        </Box>
        <ExplorerSidebar />
      </Box>
    </FullWidthHeightGlass>
  );
}

export default ContentExplorer;
