import { listMetricLabels } from "features/analytics/services/analyticsLabelService";
import { useSharedState } from "Hooks/useSharedState";

export const useMetricLabels = () => {
  const fetch = async () => {
    const res = await listMetricLabels();
    return res;
  };
  const { state, setState, loading } = useSharedState(
    "metricLabels",
    [],
    fetch,
    { isGlobal: true }
  );

  return {
    metricLabels: state,
    setMetricLables: setState,
    loading,
  };
};
