import { Grid } from "@mui/material";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { AnalyticsChartStatCard, table5Rows } from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiOperator,
  ApiYAxisChartType,
} from "@incendium/api";
import { AnalyticsSpacing } from "consts";
import { useMemo } from "react";
import { useMetricName } from "features/analytics/hooks/useMetricName";

function ReturningCustomerInsights() {
  const metricToName = useMetricName();

  const newReturningAttribute = useMemo(() => {
    return {
      key: ApiDimension.DIMENSION_USER_TYPE,
      operator: ApiOperator.EQUAL,
      value: "Returning Customer",
    };
  }, []);

  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: `${metricToName(ApiMetric.METRIC_REPEAT_CUSTOMER_COUNT)}`,
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_REPEAT_CUSTOMER_COUNT],
              },
            ],
            displayOptions: {
              statTitle: `${metricToName(
                ApiMetric.METRIC_REPEAT_CUSTOMER_COUNT
              )}`,
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: `${metricToName(ApiMetric.METRIC_REPEAT_CUSTOMER_RATE)}`,
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_REPEAT_CUSTOMER_RATE],
              },
            ],
            displayOptions: {
              statTitle: `${metricToName(
                ApiMetric.METRIC_REPEAT_CUSTOMER_RATE
              )}`,
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: `${metricToName(ApiMetric.METRIC_REPEAT_CUSTOMER_REVENUE)}`,
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_REPEAT_CUSTOMER_REVENUE],
              },
            ],
            displayOptions: {
              statTitle: `${metricToName(
                ApiMetric.METRIC_REPEAT_CUSTOMER_REVENUE
              )}`,
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: "Total Returning Visits",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_RETURNING_CUSTOMER_SESSION_COUNT],
              },
            ],
            displayOptions: {
              statNoBg: true,
              statTitle: "Total Returning Visits",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: " Returning Customer Rate",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_RETURNING_CUSTOMER_RATE],
              },
            ],
            displayOptions: {
              statNoBg: true,
              statTitle: " Returning Customer Rate",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsChartStatCard
          chart={{
            name: " Returning Customer Conversion Rate",
            dimension: [ApiDimension.DIMENSION_NOT_SET],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes: [],
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE,
                ],
              },
            ],
            displayOptions: {
              statNoBg: true,
              statTitle: "Returning Customer Conversion Rate",
            },
            comparison: true,
          }}
          noToolbar
        />
      </Grid>
      <Grid item xs={8} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Repeat Customer Revenue",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            displayOptions: {
              noFilters: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_REPEAT_CUSTOMER_REVENUE],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Total Returning Visits",
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_WEEK],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [],
            displayOptions: {
              noFilters: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_RETURNING_CUSTOMER_SESSION_COUNT],
                chart: {
                  [ApiMetric.METRIC_RETURNING_CUSTOMER_SESSION_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Visits Between Conversions",
            dimension: [
              ApiDimension.DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION,
            ],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            orderBy: `${ApiDimension.DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION}`,
            attributes: [newReturningAttribute],
            displayOptions: {
              noFilters: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_LEADS_COUNT],
                chart: {
                  [ApiMetric.METRIC_LEADS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Days Between Conversions",
            dimension: [
              ApiDimension.DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION,
            ],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            orderBy: `${ApiDimension.DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION}`,
            attributes: [newReturningAttribute],
            displayOptions: {
              noFilters: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_LEADS_COUNT],
                chart: {
                  [ApiMetric.METRIC_LEADS_COUNT]: ApiYAxisChartType.BAR,
                },
              },
            ],
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{ minHeight: 400 }}>
        <AnalyticsCard
          chart={{
            name: "Returning Customers by Channel",
            dimension: [ApiDimension.DIMENSION_CHANNEL],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: [],
            includeEmpty: true,
            displayOptions: {
              showTotals: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_RETURNING_CUSTOMER_COUNT],
              },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ minHeight: table5Rows }}>
        <AnalyticsCard
          chart={{
            name: "Returning Customers Product Interaction",
            dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.TABLE,
            attributes: [newReturningAttribute],
            displayOptions: {
              noFilters: true,
              noTitle: true,
            },
            yAxisKeys: [
              {
                key: "l",
                fields: [
                  ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                  ApiMetric.METRIC_PRODUCT_REVENUE,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                  ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                  ApiMetric.METRIC_SESSION_SALES_COUNT, // needs checking
                  ApiMetric.METRIC_SALES_COMPLETION_RATE,
                  ApiMetric.METRIC_SALES_REVENUE,
                  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                ],
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ReturningCustomerInsights;
