import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import {
  Grid,
  MenuItem,
  Portal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";
import { useState } from "react";

function LandingPagesInsights({
  organicChannelName,
}: {
  organicChannelName: string;
}) {
  const dimensionToName = useDimensionName();
  const [dimension, setDimension] = useState<ApiDimension>(
    ApiDimension.DIMENSION_LANDING_PAGE_LOCATION
  );
  const dimensions = [
    ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
    ApiDimension.DIMENSION_PRODUCT_NAME,
    ApiDimension.DIMENSION_PRODUCT_CATEGORY,
    ApiDimension.DIMENSION_PRODUCT_BRAND,
    ApiDimension.DIMENSION_PATH,
  ];

  return (
    <>
      <Portal container={() => document.getElementById("subhead-right")}>
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Typography noWrap variant="body1" fontWeight={700}>
            View results by
          </Typography>
          <TextField
            select
            size="small"
            value={dimension}
            sx={{ minWidth: 200 }}
            onChange={(e) => setDimension(e.target.value as ApiDimension)}
          >
            {dimensions.map((d) => (
              <MenuItem key={d} value={d}>
                {dimensionToName(d)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Portal>

      <Grid container spacing={AnalyticsSpacing}>
        <Grid item xs={8}>
          <AnalyticsCard
            chart={{
              name: `Organic Metrics by ${dimensionToName(dimension)}`,
              dimension: [dimension],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [
                {
                  key: ApiDimension.DIMENSION_CHANNEL,
                  value: organicChannelName,
                },
              ],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                    ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                    ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                    ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
                    ApiMetric.METRIC_SESSION_REVENUE,
                    ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
                rowsPerPage: 10,
              },
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={AnalyticsSpacing}>
            <Grid item xs={12} sx={{ minHeight: 400 }}>
              <AnalyticsCard
                chart={{
                  name: `Organic Sessions by ${dimensionToName(dimension)}`,
                  dimension: [dimension],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  limit: 10,
                  attributes: [
                    {
                      key: ApiDimension.DIMENSION_CHANNEL,
                      value: organicChannelName,
                    },
                  ],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                    pieTotalText: "Total Sessions",
                    showTotals: true,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ minHeight: 400 }}>
              <AnalyticsCard
                chart={{
                  name: `Session Revenue by Organic ${dimensionToName(
                    dimension
                  )}`,
                  dimension: [dimension],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  limit: 10,
                  attributes: [
                    {
                      key: ApiDimension.DIMENSION_CHANNEL,
                      value: organicChannelName,
                    },
                  ],
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSION_REVENUE],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                    pieTotalText: "Total Revenue",
                    showTotals: true,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default LandingPagesInsights;
