/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDimensionFromJSON, ApiDimensionToJSON, } from './ApiDimension';
import { ApiDimensionCategoryFromJSON, ApiDimensionCategoryToJSON, } from './ApiDimensionCategory';
export function ApiSimpleDimensionLabelFromJSON(json) {
    return ApiSimpleDimensionLabelFromJSONTyped(json, false);
}
export function ApiSimpleDimensionLabelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dimension': !exists(json, 'dimension') ? undefined : ApiDimensionFromJSON(json['dimension']),
        'label': !exists(json, 'label') ? undefined : json['label'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'article': !exists(json, 'article') ? undefined : json['article'],
        'category': !exists(json, 'category') ? undefined : ApiDimensionCategoryFromJSON(json['category']),
        'hasArticle': !exists(json, 'hasArticle') ? undefined : json['hasArticle'],
    };
}
export function ApiSimpleDimensionLabelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dimension': ApiDimensionToJSON(value.dimension),
        'label': value.label,
        'description': value.description,
        'article': value.article,
        'category': ApiDimensionCategoryToJSON(value.category),
        'hasArticle': value.hasArticle,
    };
}
