// todo: refactor into smartIframeProvider

import { ApiConversionStateLookupType } from "@incendium/api";
import { THighlight } from "@incendium/inc-ts-helpers";
import { createContext, useContext } from "react";

type IIFrameContext = {
  activateIframe: (
    targets?: string[],
    selector?: string,
    repeated?: boolean,
    targetText?: string,
    useTextMode?: boolean,
    highlightCollection?: THighlight[],
    lookup?: ApiConversionStateLookupType
  ) => void;
  deactivateIframe: () => void;
  selectedSelector: string;
  selectedText: string;
  productSchemaFound?: boolean;
  lookup?: ApiConversionStateLookupType;
};

export const IFrameContext = createContext<IIFrameContext | null>(null);
export const useIframeContext = () =>
  useContext(IFrameContext) as IIFrameContext;
