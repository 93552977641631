/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSourceCategoryValueFromJSON, ApiSourceCategoryValueToJSON, } from './ApiSourceCategoryValue';
export function ApiSourceCategoryFromJSON(json) {
    return ApiSourceCategoryFromJSONTyped(json, false);
}
export function ApiSourceCategoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'appliedAt': !exists(json, 'appliedAt') ? undefined : (new Date(json['appliedAt'])),
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'values': !exists(json, 'values') ? undefined : (json['values'].map(ApiSourceCategoryValueFromJSON)),
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (new Date(json['deletedAt'])),
    };
}
export function ApiSourceCategoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'appliedAt': value.appliedAt === undefined ? undefined : (value.appliedAt.toISOString()),
        'default': value._default,
        'projectId': value.projectId,
        'values': value.values === undefined ? undefined : (value.values.map(ApiSourceCategoryValueToJSON)),
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}
