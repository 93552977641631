import DataTable from "Components/UI/DataTable";
import { styled, Box, Divider, SxProps, Theme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { IChartData, TChartData } from "Interfaces";
import {
  attributesFromChartDataRow,
  buildTicks,
  isDimension,
  isJourneyDimension,
  isTrendDimension,
} from "..//utils/utils";
import AnalyticsCell from "../components/AnalyticsCell";
import AnalyticsUserJourneyColumns from "../components/AnalyticsUserJourneyColumns";
import { formatDimension } from "../services/dimensionsFormatter";
import {
  ApiChartAttribute,
  ApiDimension,
  ApiMetric,
  ApiTableGrouping,
} from "@incendium/api";
import { percentageChange } from "Helpers/percentage";
import moment from "moment";
import useMetricExplorerNavigation from "../hooks/useMetricExplorerNavigation";
import useFormatMetric from "../hooks/useFormatMetric";
import { ICompare, IOnClickMap, TTableCustomCellRender } from "../types/types";
import { useMetricName } from "features/analytics/hooks/useMetricName";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: -14,
  right: -14,
  top: 0,
  bottom: 0,
}));

interface IAnalyticsDataTableProps {
  data: TChartData[];
  chartAttributes: ApiChartAttribute[];
  totals?: TChartData;
  comparisonTotals?: TChartData;
  onClick?: {
    [field: string]:
      | ((v: string, o?: string) => void | Promise<void>)
      | IOnClickMap[];
  };
  pageSize?: number;
  comparison?: boolean;
  comparisonChartData?: IChartData;
  colWidths?: { [d: string]: number };
  pinAt?: number;
  disableMetricClick?: boolean;
  sx?: SxProps<Theme> | undefined;
  tableGroupings?: ApiTableGrouping[];
  tableCustomCellRender?: TTableCustomCellRender;
  tableCustomHeaderRender?: TTableCustomCellRender;
  hiddenCols?: (ApiMetric | ApiDimension)[];
}

function AnalyticsDataTable({
  data,
  chartAttributes,
  totals,
  onClick,
  comparison,
  comparisonChartData,
  comparisonTotals,
  pageSize,
  colWidths,
  pinAt,
  disableMetricClick,
  sx,
  tableGroupings,
  tableCustomCellRender,
  tableCustomHeaderRender,
  hiddenCols,
}: IAnalyticsDataTableProps) {
  const [columns, setColumns] = useState<(ColDef | ColGroupDef)[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<any[] | undefined>(undefined);
  const formatMetric = useFormatMetric();
  const metricToName = useMetricName();
  const dimensionToName = useDimensionName();
  const onMetricClick = useMetricExplorerNavigation();

  const tickedComparisonChartData = useMemo(() => {
    return buildTicks(comparisonChartData?.data || []);
  }, [comparisonChartData]);

  // map of Comparison ticks to improve performance for find comparison
  const tickedComparisonMap = useMemo(() => {
    const map = new Map();
    tickedComparisonChartData.forEach((d) => {
      map.set(d.tick, d);
    });
    return map;
  }, [tickedComparisonChartData]);

  const cellRenderer = useCallback(
    (key, { valueFormatted, value, colDef, data, ...f }, colIsDimension) => {
      let compare: ICompare = {};
      let found: TChartData | undefined;
      if (comparison && tickedComparisonMap.size > 0) {
        if (data.id === "Total") {
          found = comparisonTotals;
        } else {
          found = tickedComparisonMap.get(data.tick);
        }

        if (!colIsDimension && found && typeof value === "number") {
          let change = percentageChange(
            Number(found[colDef.field]) || 0,
            value || 0
          );
          compare = {
            direction: change > 0 ? "up" : change < 0 ? "down" : "same",
            value: Number(change) || 0,
            formatedValue: formatMetric(
              key as ApiMetric,
              Number(found[colDef.field]) || 0
            ),
          };
        }
      }

      const handler = onClick && onClick[key];
      const fn: IOnClickMap[] | undefined = (() => {
        if (!handler) {
          return [];
        }

        if (typeof handler === "function") {
          return [
            {
              text: "Click To Explore",
              fn: () => handler(value),
            },
          ];
        }

        if (Array.isArray(handler)) {
          return handler;
        }

        return [];
      })();

      if (
        !colIsDimension &&
        !disableMetricClick &&
        fn.findIndex((d) => d.text === "View in Explorer") < 0
      ) {
        fn.push({
          text: "View in Explorer",
          fn: () =>
            onMetricClick(key, [
              ...chartAttributes,
              ...attributesFromChartDataRow(data),
            ]),
        });
      }

      return colIsDimension && isJourneyDimension(key as ApiDimension) ? (
        <AnalyticsUserJourneyColumns
          formatedValue={valueFormatted}
          onClick={typeof handler === "function" ? handler : undefined}
        />
      ) : (
        <AnalyticsCell
          dimensionName={data.name}
          valueFormatted={valueFormatted}
          onClick={fn}
          compare={compare}
          headerName={colDef.headerName}
          isDimension={colIsDimension}
        />
      );
    },
    [
      onClick,
      formatMetric,
      onMetricClick,
      disableMetricClick,
      chartAttributes,
      tickedComparisonMap,
      comparisonTotals,
      comparison,
    ]
  );

  const tickedData = useMemo(() => buildTicks(data), [data]);

  useEffect(() => {
    if (!tickedData || !tickedData[0]) {
      return;
    }
    // Common function to create a column definition
    const createColDef = (field: string): ColDef => {
      const colIsDimension = isDimension(field);
      let headerName = colIsDimension
        ? dimensionToName(field as ApiDimension)
        : metricToName(field as ApiMetric);

      return {
        headerName,
        field,
        pinned: pinAt
          ? tickedData[0][field] < pinAt
            ? "left"
            : undefined
          : colIsDimension
          ? "left"
          : undefined,
        minWidth:
          colWidths && colWidths[`${field}`]
            ? colWidths[`${field}`]
            : colIsDimension
            ? isJourneyDimension(field as ApiDimension)
              ? 500
              : 200
            : 130,
        width:
          colWidths && colWidths[`${field}`]
            ? colWidths[`${field}`]
            : undefined,
        valueFormatter: ({ value }) => {
          return colIsDimension
            ? formatDimension(field as ApiDimension, value)
            : value === "..."
            ? "..."
            : formatMetric(field as ApiMetric, value);
        },
        headerComponent:
          tableCustomHeaderRender && tableCustomHeaderRender[field]
            ? tableCustomHeaderRender[field]
            : undefined,
        cellRenderer:
          tableCustomCellRender && tableCustomCellRender[field]
            ? tableCustomCellRender[field]
            : (params) => cellRenderer(field, params, colIsDimension),
        comparator: isTrendDimension(field as ApiDimension)
          ? (date1: string, date2: string) => moment(date1).diff(moment(date2))
          : undefined,
      } as ColDef;
    };

    // Generate all columns with order maintained
    const cols: (ColDef | ColGroupDef)[] = [];
    const usedFields = new Set<string>();

    Object.keys(tickedData[0])
      .filter((field) => !["name", "tick"].includes(field)) // Exclude unwanted fields
      .filter(
        (field) => !hiddenCols?.includes(field as ApiDimension | ApiMetric)
      )
      .forEach((field) => {
        // Check if the metric belongs to a group
        const group = (tableGroupings || []).find((group) =>
          (group.metrics || []).includes(field as ApiMetric)
        );
        if (group) {
          // Check if the group is already added
          let groupCol = cols.find(
            (col) => "headerName" in col && col.headerName === group.name
          ) as ColGroupDef;

          if (!groupCol) {
            // Create a new group if not present
            groupCol = {
              headerName: group.name,
              children: [],
              headerGroupComponent:
                tableCustomHeaderRender &&
                tableCustomHeaderRender[group.name as string]
                  ? tableCustomHeaderRender[group.name as string]
                  : undefined,
            } as ColGroupDef;
            cols.push(groupCol);
          }

          // Add the field to the group
          groupCol.children.push(createColDef(field));
          usedFields.add(field);
        } else if (!usedFields.has(field)) {
          // Add ungrouped fields directly
          cols.push(createColDef(field));
          usedFields.add(field);
        }
      });

    const rows = (tickedData || []).map((v, i) => {
      return {
        id: i,
        ...v,
      };
    });

    setColumns(cols);
    setRows(rows);
    if (totals) {
      setTotalRow([totals]);
    }
  }, [
    tickedData,
    totals,
    comparison,
    comparisonChartData,
    cellRenderer,
    colWidths,
    pinAt,
    formatMetric,
    comparisonTotals,
    tableGroupings,
    tableCustomCellRender,
    tableCustomHeaderRender,
    hiddenCols,
    metricToName,
  ]);

  return (
    <StyledWrapper sx={sx}>
      <Divider />
      <DataTable
        colDefs={columns}
        rows={rows}
        totalRow={totalRow}
        pageSize={pageSize}
      />
    </StyledWrapper>
  );
}

export default AnalyticsDataTable;
