/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * - VELOCITY_IMMEDIATE: This is used to speculate as soon as possible, that is, as soon as the speculation rules are observed.
 *  - VELOCITY_EAGER: This currently behaves identically to the immediate setting, but in future, we are looking to place this somewhere between immediate and moderate.
 *  - VELOCITY_MODERATE: This performs speculations if you hover over a link for 200 milliseconds (or on the pointerdown event if that is sooner, and on mobile where there is no hover event).
 *  - VELOCITY_CONSERVATIVE: This speculates on pointer or touch down.
 * @export
 * @enum {string}
 */
export var ApiLocationVelocityType;
(function (ApiLocationVelocityType) {
    ApiLocationVelocityType["DISABLED"] = "VELOCITY_DISABLED";
    ApiLocationVelocityType["IMMEDIATE"] = "VELOCITY_IMMEDIATE";
    ApiLocationVelocityType["EAGER"] = "VELOCITY_EAGER";
    ApiLocationVelocityType["MODERATE"] = "VELOCITY_MODERATE";
    ApiLocationVelocityType["CONSERVATIVE"] = "VELOCITY_CONSERVATIVE";
})(ApiLocationVelocityType || (ApiLocationVelocityType = {}));
export function ApiLocationVelocityTypeFromJSON(json) {
    return ApiLocationVelocityTypeFromJSONTyped(json, false);
}
export function ApiLocationVelocityTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiLocationVelocityTypeToJSON(value) {
    return value;
}
