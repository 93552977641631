import { CircularProgress, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { TabWrapper } from "Components/UI/TabContainer";
import { enumToArray } from "Helpers/enumToText";
import { useCallback, useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import Overview from "./Overview";
import LandingPagesInsights from "./LandingPagesInsights";
import CrawlerAnalysis from "./CrawlerAnalysis";
import TabbedAnalysesPage from "Pages/Analyse/TabbedAnalysesPage";
import StockImpactInsights from "./StockImpactInsights";
import { useChannels } from "Hooks/useChannels";
import { ApiChannelDefault } from "@incendium/api";

enum Insight {
  overview = "Overview",
  landing = "Landing Pages",
  crawler = "Crawler Analysis",
  stock = "Stock Impact",
}

function MarketingPerformanceOrganicPage() {
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);
  const { channels } = useChannels();

  const organicChannelName = useMemo(() => {
    return (
      channels.find((c) => c._default === ApiChannelDefault.ORGANIC_SEARCH)
        ?.name || ""
    );
  }, [channels]);

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case Insight.overview.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <Overview organicChannelName={organicChannelName} />
          </TabWrapper>
        );
      case Insight.landing.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <LandingPagesInsights organicChannelName={organicChannelName} />
          </TabWrapper>
        );
      case Insight.crawler.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <CrawlerAnalysis />
          </TabWrapper>
        );
      case Insight.stock.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <StockImpactInsights organicChannelName={organicChannelName} />
          </TabWrapper>
        );

      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight, organicChannelName]);

  if (!insight || insight === "") {
    return <Redirect to={`./organic/overview`} />;
  }

  if (!organicChannelName || organicChannelName === "") {
    return (
      <CenterPage calcHeight>
        <CircularProgress />
        <Typography variant="h3" mt={2}>
          Fetching organic channels
        </Typography>
      </CenterPage>
    );
  }

  return (
    <TabbedAnalysesPage
      title=" Marketing Performance - Organic Search"
      activeTab={activeInsight}
      setActiveTab={setActiveInsight}
      tabs={enumToArray(Insight)}
      renderSwitch={renderSwitch}
    />
  );
}

export default MarketingPerformanceOrganicPage;
