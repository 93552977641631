import { ApiLocation } from "@incendium/api";
import { QuestionMark } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Loading from "Components/Loading/Loading";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import VelocityDrawer from "features/velocity/components/VelocityDrawer";
import VelocityList from "features/velocity/components/VelocityList";
import { saveVelocity } from "features/velocity/services/velocityService";
import withInfoColumn from "HoC/withInfoColumn";
import { useLocations, useNotification, useSelectedProject } from "Hooks";
import { useSave } from "Hooks/useSave";
import produce from "immer";
import { useCallback, useState } from "react";

function VelocityPageInner() {
  const { selectedProject } = useSelectedProject();
  const { locations, loading, setLocations } = useLocations();
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<ApiLocation>({});
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { saving, save } = useSave();

  const onEdit = useCallback((location) => {
    setSelectedLocation(location);
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setSelectedLocation({});
    setOpen(false);
  }, []);

  const onSave = useCallback(
    async (location: ApiLocation) => {
      try {
        save(async () => {
          const res = await saveVelocity(
            selectedProject!.id as number,
            location
          );
          if (!res) {
            throw new Error("No res");
          }
          setLocations((locations) =>
            produce(locations, (draft) => {
              const idx = draft.findIndex((d) => d.id === res.id);
              if (idx >= 0) {
                draft[idx] = res;
              }
            })
          );
          showSuccessNotification(`Velocity for ${res.name} Set`);
          onClose();
        });
      } catch (error) {
        showErrorNotification("Failed to set velocity");
      }
    },
    [
      showSuccessNotification,
      showErrorNotification,
      selectedProject,
      onClose,
      setLocations,
      save,
    ]
  );

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <VelocityList locations={locations} onEdit={onEdit} />
      <VelocityDrawer
        open={open}
        onClose={onClose}
        location={selectedLocation}
        onSave={onSave}
        saving={saving}
      />
    </>
  );
}

function VelocityPage() {
  return withInfoColumn(VelocityPageInner)({
    infoColumn: (
      <>
        <AccordianChartBuilderSidebarBlock
          title="What is Velocity?"
          icon={QuestionMark}
        >
          <Typography>
            Velocity is a feature designed to make your website faster and more
            seamless for visitors. It works by preparing the pages a user is
            most likely to visit next, either by preloading or prerendering them
            in the background. This means when users navigate your site, those
            pages load almost instantly, creating a smoother and more engaging
            browsing experience.
          </Typography>
          <Typography variant="subtitle2" mt={2} mb={1}>
            How Does It Work?
          </Typography>
          <Typography component={"li"}>
            <strong>Prefetching</strong>: Downloads the HTML of the next likely
            page in advance but doesn’t load additional resources like images or
            scripts. This reduces the time it takes to start loading the page
            when the user clicks on it.
          </Typography>
          <Typography component={"li"}>
            <strong>Prerendering</strong>: Fully loads and prepares an entire
            page, so it’s ready to display immediately when the user navigates
            to it.
          </Typography>
          <Typography variant="subtitle2" mt={2} mb={1}>
            Why Use Velocity?
          </Typography>
          <Typography component={"li"}>
            <strong>Boost Conversions</strong>: Faster page loads reduce
            friction in the user journey, helping turn more visitors into
            customers.
          </Typography>
          <Typography component={"li"}>
            <strong>Better User Experience</strong>: Eliminates delays and makes
            browsing your website feel effortless.
          </Typography>
          <Typography component={"li"}>
            <strong>Customizable Performance</strong>: Tailor preloading and
            prerendering to your site's specific needs.
          </Typography>
        </AccordianChartBuilderSidebarBlock>
      </>
    ),
  });
}

export default VelocityPage;
