import { useSharedState } from "./useSharedState";
import { userService } from "Apis";
import { ApiUser } from "@incendium/api";

export const useUsers = () => {
  const fetchUsers = async () => {
    const users = await userService.userServiceListUsers();
    return users.result || [];
  };
  const { state, setState, refetch } = useSharedState("users", [], fetchUsers, {
    isGlobal: true,
  });

  return {
    users: state as ApiUser[],
    setUsers: setState,
    refetchUsers: refetch,
  };
};
