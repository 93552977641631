import { ApiChartAttribute } from "@incendium/api";
import { useSelectedProject } from "Hooks/useSelectedProject";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

function useMetricExplorerNavigation() {
  const history = useHistory();
  const { selectedClient, selectedProject } = useSelectedProject();

  const onMetricClick = useCallback(
    (metric: string, attributes?: ApiChartAttribute[]) => {
      if (!selectedClient || !selectedProject) {
        return;
      }

      let search = `m=${encodeURIComponent(metric)}`;
      if (attributes) {
        search += `&at=${encodeURIComponent(JSON.stringify(attributes))}`;
      }
      history.push({
        pathname: `/clients/${selectedClient.id}/projects/${selectedProject.id}/analyse/standard-reports/explorer`,
        search,
      });
    },
    [history, selectedClient, selectedProject]
  );

  return onMetricClick;
}

export default useMetricExplorerNavigation;
