import { ApiLocation, ApiLocationVelocityType } from "@incendium/api";
import {
  Alert,
  Box,
  Button,
  DrawerProps,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import SavingButton from "Components/UI/SavingButton";
import StyledDrawer, {
  StyledDrawerActions,
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { StyledSlider } from "Components/UI/StyledSlider";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { velocityMap } from "features/velocity/utils";
import produce from "immer";
import { useMemo, useState, useEffect, useCallback } from "react";

interface IVelocityDrawerProps extends DrawerProps {
  location: ApiLocation;
  onSave: (location: ApiLocation) => Promise<void>;
  saving: boolean;
}

function VelocityDrawer({
  open,
  onClose,
  location: initialLocation,
  onSave,
  saving,
}: IVelocityDrawerProps) {
  const [location, setLocation] = useState(initialLocation);
  const [typeSet, setTypeSet] = useState(
    initialLocation.velocityType !== ApiLocationVelocityType.DISABLED
  );
  const title = useMemo(
    () => `Setup ${location.name} Location Velocity`,
    [location]
  );

  // Map initial velocityType to slider value
  const initialSliderValue = useMemo(() => {
    return (
      Object.entries(velocityMap).find(
        ([, { type }]) => type === location.velocityType
      )?.[0] || 0
    );
  }, [location.velocityType]);

  const [eager, setEager] = useState<number>(Number(initialSliderValue));

  useEffect(() => {
    setEager(Number(initialSliderValue));
  }, [initialSliderValue]);

  const handleSliderChange = (_: any, value: number | number[]) => {
    const sliderValue = value as number;
    setEager(sliderValue);
    const newVelocityType = velocityMap[sliderValue].type;
    setLocation((l) =>
      produce(l, (draft) => {
        draft.velocityType = newVelocityType;
      })
    );
  };

  useEffect(() => {
    if (open) {
      setLocation(initialLocation);
      setTypeSet(
        initialLocation.velocityType !== ApiLocationVelocityType.DISABLED
      );
    }
  }, [open, initialLocation]);

  useEffect(() => {
    if (typeSet) {
      return;
    }
    if (location.velocityType !== ApiLocationVelocityType.DISABLED) {
      setTypeSet(true);
      setLocation((l) =>
        produce(l, (draft) => {
          draft.velocityLimit = 3;
          draft.velocityRender = true;
        })
      );
    }
  }, [location.velocityType, typeSet]);

  const save = useCallback(() => {
    onSave(location);
  }, [location, onSave]);

  return (
    <StyledDrawer open={open} onClose={onClose} maxWidth={600}>
      <StyledDrawerTitle>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="body2">
          Optimize navigation speed by preloading or prerendering the next
          likely pages.
        </Typography>
      </StyledDrawerTitle>
      <StyledDrawerContainer>
        {typeSet ? (
          <Stack spacing={1}>
            <Box>
              <Typography variant="subtitle2">Preloading Eagerness</Typography>
              <Typography variant="body2" mb={2}>
                Adjust the preloading behavior to control how aggressively
                resources are fetched for the next likely pages. Move the slider
                to choose between Immediate, Eager, Moderate, or Conservative
                settings. Higher eagerness improves navigation speed but may
                increase bandwidth and server load.
              </Typography>
              <StyledSlider
                title="Velocity Type"
                align="left"
                max={velocityMap.length - 1}
                step={1}
                value={eager}
                onChange={handleSliderChange}
              />
            </Box>
            <Alert severity={"info"}>
              <Typography variant="subtitle2">
                {velocityMap[eager].label}
              </Typography>
              <Typography variant="body2">
                {velocityMap[eager].description}
              </Typography>
            </Alert>
          </Stack>
        ) : (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Switch
                checked={
                  location.velocityType !== ApiLocationVelocityType.DISABLED
                }
                onChange={(e, checked) =>
                  setLocation((l) =>
                    produce(l, (draft) => {
                      if (checked) {
                        draft.velocityType = ApiLocationVelocityType.EAGER;
                      } else {
                        draft.velocityType = ApiLocationVelocityType.DISABLED;
                      }
                    })
                  )
                }
              />
            }
            label={`Enable Velocity for ${location.name}`}
          />
        )}
        {location.velocityType !== ApiLocationVelocityType.DISABLED && (
          <Stack mt={4} spacing={4}>
            <Stack>
              <Typography variant="subtitle2">
                Limit Number of Preloaded Pages
              </Typography>
              <Typography variant="body2" mb={2}>
                Set the maximum number of pages to preload, ranked by the most
                likely user navigation paths. Higher limits improve performance
                for more potential pages but can increase bandwidth usage and
                server load. Choose a value between 1 and 10 to balance
                performance and resource usage.
              </Typography>
              <StyledSlider
                align="left"
                step={1}
                min={1}
                max={8}
                valueLabelDisplay="auto"
                title={`Velocity Limit : ${location.velocityLimit}`}
                value={location.velocityLimit || 3}
                onChange={(_, n) => {
                  setLocation((l) =>
                    produce(l, (draft) => {
                      draft.velocityLimit = n as number;
                    })
                  );
                }}
              />
            </Stack>
            <Stack>
              <Typography variant="subtitle2" gutterBottom>
                Prerender Or Prefetch
              </Typography>
              <StyledFlexBox sx={{ maxWidth: 300 }} mb={2}>
                <Typography color={"secondary"}>Prefetch</Typography>
                <Switch
                  checked={location.velocityRender}
                  onChange={(e, checked) =>
                    setLocation((l) =>
                      produce(l, (draft) => {
                        draft.velocityRender = checked;
                      })
                    )
                  }
                />
                <Typography color={"primary"}>
                  Prerender (Recommended)
                </Typography>
              </StyledFlexBox>
              {location.velocityRender ? (
                <Typography variant="body2">
                  Prerendering not only downloads the HTML but also fully loads
                  and renders the entire page, including all its resources (CSS,
                  JavaScript, images, etc.), invisibly in the background. When
                  the user navigates, the page is instantly displayed as if it
                  were already open. While this provides the fastest possible
                  navigation experience, it uses more bandwidth and
                  computational resources.
                </Typography>
              ) : (
                <Typography variant="body2">
                  Prefetching downloads the HTML document of the next likely
                  pages ahead of time. However, it does not fetch additional
                  resources like CSS, JavaScript, or images until the user
                  navigates to the page. This approach reduces initial latency
                  but does not fully prepare the page for immediate interaction.
                  It is lightweight and suitable for conserving bandwidth and
                  server resources.
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </StyledDrawerContainer>
      {typeSet && (
        <StyledDrawerActions>
          <Button
            onClick={(e) => onClose!(e, "backdropClick")}
            color="secondary"
          >
            Cancel
          </Button>
          <SavingButton
            variant="contained"
            color="primary"
            onClick={save}
            saving={saving}
          >
            Save
          </SavingButton>
        </StyledDrawerActions>
      )}
    </StyledDrawer>
  );
}

export default VelocityDrawer;
