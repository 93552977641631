import { Box, Paper, Typography, alpha } from "@mui/material";
import { formatEnumVal } from "Helpers/enumToText";
import { IAnalyticsCardCustomTooltipProps, TKeyValue } from "Interfaces";
import { getCellFillByDimension } from "features/analytics/models/Chart.model";
import { TColourMap, useAnalyticsContext } from "Providers/AnalyticsProvider";
import { groupWithPrevious } from "../utils/utils";
import useFormatMetric from "../hooks/useFormatMetric";
import { useMemo } from "react";
import { dateFormat } from "cfg";
import moment from "moment";
import { friendlyDateExtended } from "Helpers/dates";
import { Moving } from "@mui/icons-material";
import { useLabelMetricToName } from "features/analytics/hooks/useLabelMetricToName";

const ToolTipRow = ({
  item,
  renderValueFn,
  colourMap,
  fills,
}: {
  item: any;
  renderValueFn:
    | ((
        key: any,
        value: any,
        payload?: any
      ) =>
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>)
    | undefined;
  colourMap: TColourMap;
  fills?: TKeyValue;
}) => {
  const formatMetric = useFormatMetric();
  const labelMetricToName = useLabelMetricToName();
  const fill = useMemo(() => {
    const fill =
      fills && fills[item.name]
        ? fills[item.name]
        : getCellFillByDimension(
            item.payload.name,
            colourMap,
            item.fill && item.fill !== "#fff" ? item.fill : item.stroke
          );

    return item.name.includes("previous") ? alpha(fill, 0.6) : fill;
  }, [item, colourMap, fills]);

  const label = useMemo(() => {
    return labelMetricToName(item.name);
  }, [item, labelMetricToName]);

  const metric = item.name.includes("\\")
    ? item.name.split("\\")[1]
    : item.name;

  return (
    <Typography
      sx={{
        position: "relative",
        paddingLeft: fill ? "18px" : 0,
        "&::before": {
          content: "''",
          position: "absolute",
          left: 0,
          top: 4,
          height: 10,
          width: 10,
          background: fill,
          borderRadius: "50%",
        },
      }}
      variant="body2"
      gutterBottom
    >
      {renderValueFn
        ? renderValueFn(metric, item.value, item)
        : `${label} : ${formatMetric(metric, item.value)}`}
      {item.previous && (
        <Typography
          sx={{
            color: fill,
          }}
          variant="body2"
          display={"inline"}
        >
          {" ( "}
          <Moving
            fontSize={"inherit"}
            sx={{
              fontSize: "inherit",
              transform: `translateY(2px) scale(1, ${
                item.value >= item.previous.value ? 1 : -1
              })`,
              color: fill,
            }}
          />{" "}
          {formatMetric(metric, item.previous.value)})
        </Typography>
      )}
    </Typography>
  );
};

const AnalyticsCardCustomTooltip = ({
  active,
  payload,
  label,
  noSort,
  renderLabelFn,
  renderValueFn,
  fills,
  ...rest
}: IAnalyticsCardCustomTooltipProps) => {
  const { colourMap } = useAnalyticsContext();
  const title = useMemo(() => {
    let s = "";
    if (label) {
      s = label;
    } else if (payload && payload?.length > 0) {
      s = payload[0].payload.name || payload[0].name;
    }
    // check if s is date and extend
    let parsedDate;
    parsedDate = moment(s, dateFormat, true); // Strict parsing so will fail if not right
    if (parsedDate.isValid()) {
      return friendlyDateExtended(s);
    }

    return s;
  }, [payload, label]);

  if (active && payload && payload.length > 0) {
    let sorted = noSort
      ? payload
      : payload.sort((a: any, b: any) => b.value - a.value);
    sorted = groupWithPrevious(sorted);

    return (
      <Paper>
        <Box p={1}>
          <Typography variant="subtitle2" gutterBottom>
            {renderLabelFn
              ? renderLabelFn(title || "")
              : (title || "").includes("_")
              ? formatEnumVal(title || "")
              : title}
          </Typography>
          {sorted.map((p: any, i: number) => (
            <ToolTipRow
              item={p}
              renderValueFn={renderValueFn}
              colourMap={colourMap}
              key={i}
              fills={fills}
            />
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

export default AnalyticsCardCustomTooltip;
