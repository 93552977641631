import { Add, Delete, Reorder } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
  OnDragStartResponder,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";

interface IItemRowProps<T, U> {
  index: number;
  onRemove: (i: number, value: T) => void;
  onEdit: (i: number) => (value: T) => void;
  items: T[];
  uniqueProp?: keyof T;
  droppableId?: string;
  RowComponent: React.ComponentType<{
    row: T;
    onEdit: (v: T) => void;
    rows: T[];
    rowProps?: U;
    index: number;
  }>;
  rowDisableDelete?: (v: T) => boolean;
  rowShowDivider?: boolean;
  rowProps?: U;
  row: T;
  allowDeleteAll?: boolean;
}

const ItemRow = forwardRef(
  <T, U>(
    {
      droppableId,
      index,
      row,
      uniqueProp,
      items,
      rowDisableDelete,
      onRemove,
      RowComponent,
      onEdit,
      rowProps,
      rowShowDivider,
      allowDeleteAll,
    }: IItemRowProps<T, U>,
    ref
  ) => {
    const uniqueField = useMemo(() => {
      return uniqueProp ? row[uniqueProp] : row;
    }, [uniqueProp, row]);

    return droppableId ? (
      <Draggable
        draggableId={`${droppableId}-${index}`}
        index={index}
        key={`outer-${uniqueField}`}
      >
        {(provided, snapshot) => {
          const children = (
            <>
              <Stack
                direction={"row"}
                spacing={2}
                component={motion.div}
                initial={{ x: -100, scale: 0.8, opacity: 0 }}
                animate={{ x: 0, scale: 1, opacity: 1 }}
                exit={{ x: -100, scale: 0.8, opacity: 0 }}
                ref={provided.innerRef}
                {...provided.draggableProps}
                key={`inner-${uniqueField}`}
                alignItems="center"
                sx={{
                  width: "100%",
                }}
              >
                <Box display={"flex"} {...provided.dragHandleProps} mr={1}>
                  <Reorder
                    color={items.length > 1 ? "secondary" : "disabled"}
                  />
                </Box>

                {(allowDeleteAll || items.length > 1) && (
                  <IconButton
                    disabled={rowDisableDelete && rowDisableDelete(row)}
                    size="small"
                    onClick={() => onRemove(index, row)}
                  >
                    <Delete />
                  </IconButton>
                )}

                <RowComponent
                  row={row}
                  onEdit={onEdit(index)}
                  rows={items}
                  rowProps={rowProps}
                  index={index}
                />
              </Stack>
              {rowShowDivider && index < items.length - 1 && <Divider />}
            </>
          );
          if (snapshot.isDragging) {
            return ReactDOM.createPortal(children, document.body);
          }
          return children;
        }}
      </Draggable>
    ) : (
      <Box key={`outer-${uniqueField}`}>
        <Stack
          direction={"row"}
          spacing={2}
          component={motion.div}
          initial={{ x: -100, scale: 0.8, opacity: 0 }}
          animate={{ x: 0, scale: 1, opacity: 1 }}
          exit={{ x: -100, scale: 0.8, opacity: 0 }}
          key={`inner-${uniqueField}`}
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          {(allowDeleteAll || items.length > 1) && (
            <IconButton
              disabled={rowDisableDelete && rowDisableDelete(row)}
              size="small"
              onClick={() => onRemove(index, row)}
            >
              <Delete />
            </IconButton>
          )}

          <RowComponent
            row={row}
            onEdit={onEdit(index)}
            rows={items}
            rowProps={rowProps}
            index={index}
          />
        </Stack>
        {rowShowDivider && index < items.length - 1 && <Divider />}
      </Box>
    );
  }
) as <T, U>(
  props: IItemRowProps<T, U> & { ref?: React.Ref<unknown> }
) => JSX.Element;

interface IAnimatedListProps<T, U> {
  onAdd: () => void;
  onRemove: (i: number, value: T) => void;
  onEdit: (i: number) => (value: T) => void;
  items: T[];
  uniqueProp?: keyof T;
  droppableId?: string;
  RowHead?: React.ComponentType<{}>;
  RowComponent: React.ComponentType<{
    row: T;
    onEdit: (v: T) => void;
    rows: T[];
    rowProps?: U;
    index: number;
  }>;
  rowSpacing?: number;
  rowDisableDelete?: (v: T) => boolean;
  rowShowDivider?: boolean;
  rowProps?: U;
  btnText?: string;
  allowDeleteAll?: boolean;
  isDisabled?: boolean;
  onDragStart?: OnDragStartResponder;
  onDragEnd?: OnDragEndResponder;
}

function AnimatedList<T, U>({
  onAdd,
  onRemove,
  onEdit,
  items,
  uniqueProp,
  droppableId,
  RowHead,
  RowComponent,
  rowSpacing,
  rowDisableDelete,
  rowShowDivider,
  rowProps,
  btnText,
  allowDeleteAll,
  isDisabled,
  onDragStart,
  onDragEnd,
}: IAnimatedListProps<T, U>) {
  const body = useMemo(() => {
    return (
      <AnimatePresence mode="popLayout" initial={false}>
        {items.map((row: T, i: number) => (
          <ItemRow<T, U>
            key={uniqueProp ? `${row[uniqueProp]}` : i}
            index={i}
            row={row}
            uniqueProp={uniqueProp}
            items={items}
            onRemove={onRemove}
            onEdit={onEdit}
            droppableId={droppableId}
            rowDisableDelete={rowDisableDelete}
            rowShowDivider={rowShowDivider}
            rowProps={rowProps}
            RowComponent={RowComponent}
            allowDeleteAll={allowDeleteAll}
          />
        ))}
      </AnimatePresence>
    );
  }, [
    RowComponent,
    allowDeleteAll,
    droppableId,
    items,
    onEdit,
    onRemove,
    rowDisableDelete,
    rowProps,
    rowShowDivider,
    uniqueProp,
  ]);
  return (
    <Box>
      {RowHead && (
        <Box mb={rowSpacing || 2}>
          <RowHead />
        </Box>
      )}
      {droppableId && onDragEnd ? (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId={droppableId}>
            {(prov) => (
              <Stack
                spacing={rowSpacing || 2}
                ref={prov.innerRef}
                {...prov.droppableProps}
              >
                {body}
                {prov.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Stack spacing={rowSpacing || 2}>{body}</Stack>
      )}

      <Box mt={3}>
        <Button
          color="secondary"
          size="extraSmall"
          endIcon={<Add />}
          onClick={onAdd}
          disabled={isDisabled}
        >
          {btnText || `Add additional item`}
        </Button>
      </Box>
    </Box>
  );
}

export default AnimatedList;
