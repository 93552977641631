import { ApiSimpleSourceCategory } from "@incendium/api";
import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Loading from "Components/Loading/Loading";
import SpacedList from "Components/UI/SpacedList";
import { cell2Icons } from "consts";
import { useSourceCategories } from "features/sourceCategories/hooks/useSourceCategories";

interface ISourceCategoriesListProps {
  onClick: (c?: ApiSimpleSourceCategory) => void;
  onEdit: (c: ApiSimpleSourceCategory) => void;
  onDelete: (c: ApiSimpleSourceCategory) => void;
}

function SourceCategoriesList({
  onClick,
  onEdit,
  onDelete,
}: ISourceCategoriesListProps) {
  const { loading, sourceCategories } = useSourceCategories();

  if (loading) {
    return <Loading fullHeight />;
  }
  return (
    <SpacedList
      title="Source Categories List"
      action={<Button onClick={() => onClick()}>New Source Category</Button>}
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Values</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(sourceCategories || []).map((sc) => (
          <TableRow key={sc.id}>
            <TableCell>{sc.name}</TableCell>
            <TableCell>
              {(sc.values || []).map((v) => v.value).join(", ")}
            </TableCell>
            <TableCell align="right" color="primary" sx={{ width: cell2Icons }}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => onEdit(sc)}
              >
                <Edit />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => onDelete(sc)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

export default SourceCategoriesList;
