import {
  ApiMetric,
  ApiMetricCategory,
  ApiSimpleMetricLabel,
  ApiYAxisChartType,
} from "@incendium/api";
import {
  BarChart,
  Delete,
  Eject,
  ShowChart,
  StackedBarChart,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import produce from "immer";
import { IChart } from "Interfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMetricName } from "features/analytics/hooks/useMetricName";
import { useMetricLabels } from "features/analytics/hooks/useMetricLabels";
import {
  metricCategoryTitle,
  metricOriginalNameToEnum,
  orderGroupedMetricLabels,
} from "features/analytics";
import { StyledTooltip } from "Components/UI/StyledTooltip";
import { useChartDescriptionDrawer } from "Providers/ChartDescriptionDrawerProvider";

function ChartBuilderMetric({
  chart,
  setChart,
  // metrics,
  index,
  yKey,
  noControls,
  noRemove,
}: {
  chart: IChart;
  setChart: React.Dispatch<React.SetStateAction<IChart>>;
  // metrics: IGroupedMetrics[];
  index: number;
  yKey: string;
  noControls?: boolean;
  noRemove?: boolean;
}) {
  const { openDrawer } = useChartDescriptionDrawer();
  const { metricLabels, loading } = useMetricLabels();
  const [selectedOption, setSelectedOption] = useState<
    ApiSimpleMetricLabel | undefined
  >(undefined);

  const yAxisIdx: any = useMemo(() => {
    return chart.yAxisKeys.findIndex((y: any) => y.key === yKey);
  }, [chart, yKey]);
  const metricToName = useMetricName();

  // todo: type better
  const yAxis: any = useMemo(() => {
    if (yAxisIdx < 0) {
      return null;
    }

    return chart.yAxisKeys[yAxisIdx];
  }, [yAxisIdx, chart.yAxisKeys]);

  const lineType = useMemo(() => {
    if (!yAxis) {
      return ApiYAxisChartType.LINE;
    }

    if (
      yAxis?.stackId &&
      yAxis?.chart[yAxis.fields[index]] === ApiYAxisChartType.BAR
    ) {
      return yAxis?.chart[yAxis.fields[index]] + "-stacked";
    }
    return yAxis?.chart && yAxis?.chart[yAxis.fields[index]]
      ? yAxis?.chart[yAxis.fields[index]]
      : ApiYAxisChartType.LINE;
  }, [yAxis, index]);

  const parsedMetrics = useMemo(() => {
    const sorted = orderGroupedMetricLabels(metricLabels);
    console.log(sorted, "sorted");

    return sorted.map((s) => ({
      ...s,
      category: metricCategoryTitle(
        s.category || ApiMetricCategory.METRIC_CATEGORY_UNKNOWN
      ) as ApiMetricCategory,
    }));
  }, [metricLabels]);

  const filteredMetrics = useMemo(() => {
    return parsedMetrics.filter(
      (m) => !(yAxis.fields || []).includes(m.metric)
    );
  }, [yAxis, parsedMetrics]);

  useEffect(() => {
    const idx = parsedMetrics.findIndex(
      (f) =>
        f.metric === yAxis?.fields[index] ||
        f.metric === metricOriginalNameToEnum(yAxis?.fields[index])
    );

    if (idx < 0) {
      return;
    }
    setSelectedOption(parsedMetrics[idx]);
  }, [yAxis, parsedMetrics, index]);

  const onRemove = useCallback(() => {
    setSelectedOption({});
    setChart(
      produce(chart, (draft) => {
        if (yAxisIdx >= 0) {
          (draft.yAxisKeys[yAxisIdx] as any).fields.splice(index, 1);

          return;
        }
      })
    );
  }, [chart, index, setChart, yAxisIdx]);

  const onChange = useCallback(
    (e, v) => {
      setChart(
        produce(chart, (draft) => {
          if (yAxisIdx >= 0) {
            (draft.yAxisKeys[yAxisIdx] as any).fields[index] = v?.metric;
            return;
          }
          draft.yAxisKeys.push({
            key: "l",
            fields: [v?.metric || ""],
          });
        })
      );
    },
    [chart, index, setChart, yAxisIdx]
  );

  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      {noRemove || (
        <Box mr={2}>
          <IconButton size="small" onClick={onRemove}>
            <Delete />
          </IconButton>
        </Box>
      )}

      <Autocomplete
        id="y"
        size="small"
        onChange={onChange}
        fullWidth
        loading={loading}
        options={filteredMetrics}
        groupBy={(option) =>
          option.category || ApiMetricCategory.METRIC_CATEGORY_UNKNOWN
        }
        getOptionLabel={(o) => metricToName(o.metric as ApiMetric)}
        value={selectedOption || { metric: "" as ApiMetric }}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.metric === value.metric}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Metric"
            variant="outlined"
            size="small"
          />
        )}
        renderOption={(props, option) => {
          return (
            <StyledTooltip
              wide
              title={
                <>
                  <Stack px={2} py={1}>
                    <Typography variant="subtitle1" color={"inherit"}>
                      {metricToName(option.metric as ApiMetric)}
                    </Typography>
                    <Typography variant="body1" color={"inherit"}>
                      {option.description || "No description available"}
                    </Typography>
                  </Stack>

                  <Box mt={1}>
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      onClick={() =>
                        openDrawer([], [option.metric as ApiMetric])
                      }
                    >
                      Click to see full description
                    </Button>
                  </Box>
                </>
              }
              placement="left"
              arrow
              enterDelay={500}
            >
              <Box component="li" {...props}>
                {metricToName(option.metric as ApiMetric)}
              </Box>
            </StyledTooltip>
          );
        }}
      />

      {noControls || (
        <Box ml={2}>
          <ToggleButtonGroup
            size="small"
            value={lineType}
            exclusive
            color="secondary"
            onChange={(e, v: string | null) => {
              if (!v) {
                return;
              }

              const value = (v || "").replace("-stacked", "");
              setChart(
                produce(chart, (draft) => {
                  if (yAxisIdx >= 0) {
                    if (!(draft.yAxisKeys[yAxisIdx] as any).chart) {
                      (draft.yAxisKeys[yAxisIdx] as any).chart = {};
                    }
                  }
                  (draft.yAxisKeys[yAxisIdx] as any).chart[
                    yAxis.fields[index]
                  ] = value;
                  if ((v || "").includes("-stacked")) {
                    (draft.yAxisKeys[yAxisIdx] as any).stackId = yKey;
                  } else {
                    (draft.yAxisKeys[yAxisIdx] as any).stackId = undefined;
                  }
                })
              );
            }}
          >
            <ToggleButton value={ApiYAxisChartType.LINE}>
              <ShowChart />
            </ToggleButton>
            <ToggleButton value={ApiYAxisChartType.AREA}>
              <Eject />
            </ToggleButton>
            <ToggleButton value={ApiYAxisChartType.BAR}>
              <BarChart />
            </ToggleButton>
            <ToggleButton value={ApiYAxisChartType.BAR + "-stacked"}>
              <StackedBarChart />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  );
}

export default ChartBuilderMetric;
