import { ApiMetric } from "@incendium/api";
import { BubbleChart } from "@mui/icons-material";
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import { useMetricName } from "features/analytics/hooks/useMetricName";

interface IReportBubbleTitleProps {
  metric: string | ApiMetric;
  showLabel: boolean;
  setShowLabel: (value: boolean) => void;
}

function ReportBubbleTitle({
  metric,
  showLabel,
  setShowLabel,
}: IReportBubbleTitleProps) {
  const metricToName = useMetricName();
  return (
    <Stack
      mt={1}
      direction="row"
      spacing={AnalyticsSpacing}
      alignItems="center"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <BubbleChart />
        <Typography>
          Bubble Size: {metricToName(metric as ApiMetric)}
        </Typography>
      </Stack>
      <FormControlLabel
        control={
          <Switch
            size="small"
            checked={showLabel}
            onChange={(e, checked) => setShowLabel(checked)}
          />
        }
        label="Show Labels"
      />
    </Stack>
  );
}

export default ReportBubbleTitle;
