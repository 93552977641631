import { ApiSimpleSourceCategory } from "@incendium/api";
import { sourceCategoryService } from "Apis";

export const listSourceCategories = async (projectId: number) => {
  const res =
    await sourceCategoryService.sourceCategoryServiceListSourceCategories({
      projectId,
    });
  return res.result || [];
};

export const saveSourceCategory = async (
  projectId: number,
  category: ApiSimpleSourceCategory,
  values: string[]
) => {
  const req = {
    projectId,
    categoryId: category.id as number,
    payload: {
      name: category.name,
      values: values.map((v) => ({
        value: v,
      })),
    },
  };

  const fn = category.id
    ? sourceCategoryService.sourceCategoryServiceUpdateSourceCategory(req)
    : sourceCategoryService.sourceCategoryServiceCreateSourceCategory(req);
  const res = await fn;
  return res;
};

export const deleteSourceCategory = async (projectId: number, id: number) => {
  return sourceCategoryService.sourceCategoryServiceDeleteSourceCategory({
    projectId,
    categoryId: id,
  });
};
