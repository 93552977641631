/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiListDimensionLabelsResponseFromJSON, ApiListMetricLabelsResponseFromJSON, ApiReadDimensionLabelResponseFromJSON, ApiReadMetricLabelResponseFromJSON, ApiSimpleDimensionLabelToJSON, ApiSimpleMetricLabelToJSON, } from '../models';
/**
 *
 */
export class LabelServiceApi extends runtime.BaseAPI {
    /**
     * ------------- Dimensions ----------------
     */
    labelServiceListDimensionLabelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.dimension !== undefined) {
                queryParameters['dimension'] = requestParameters.dimension;
            }
            if (requestParameters.payloadDimension !== undefined) {
                queryParameters['payload.dimension'] = requestParameters.payloadDimension;
            }
            if (requestParameters.payloadLabel !== undefined) {
                queryParameters['payload.label'] = requestParameters.payloadLabel;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadArticle !== undefined) {
                queryParameters['payload.article'] = requestParameters.payloadArticle;
            }
            if (requestParameters.payloadCategory !== undefined) {
                queryParameters['payload.category'] = requestParameters.payloadCategory;
            }
            if (requestParameters.payloadHasArticle !== undefined) {
                queryParameters['payload.hasArticle'] = requestParameters.payloadHasArticle;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/labels/v1/dimensions`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListDimensionLabelsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Dimensions ----------------
     */
    labelServiceListDimensionLabel(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.labelServiceListDimensionLabelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Metrics ----------------
     */
    labelServiceListMetricLabelRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/labels/v1/metrics`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListMetricLabelsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Metrics ----------------
     */
    labelServiceListMetricLabel(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.labelServiceListMetricLabelRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    labelServiceReadDimensionLabelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.dimension === null || requestParameters.dimension === undefined) {
                throw new runtime.RequiredError('dimension', 'Required parameter requestParameters.dimension was null or undefined when calling labelServiceReadDimensionLabel.');
            }
            const queryParameters = {};
            if (requestParameters.payloadDimension !== undefined) {
                queryParameters['payload.dimension'] = requestParameters.payloadDimension;
            }
            if (requestParameters.payloadLabel !== undefined) {
                queryParameters['payload.label'] = requestParameters.payloadLabel;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadArticle !== undefined) {
                queryParameters['payload.article'] = requestParameters.payloadArticle;
            }
            if (requestParameters.payloadCategory !== undefined) {
                queryParameters['payload.category'] = requestParameters.payloadCategory;
            }
            if (requestParameters.payloadHasArticle !== undefined) {
                queryParameters['payload.hasArticle'] = requestParameters.payloadHasArticle;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/labels/v1/dimensions/{dimension}`.replace(`{${"dimension"}}`, encodeURIComponent(String(requestParameters.dimension))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadDimensionLabelResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    labelServiceReadDimensionLabel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.labelServiceReadDimensionLabelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    labelServiceReadMetricLabelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.metric === null || requestParameters.metric === undefined) {
                throw new runtime.RequiredError('metric', 'Required parameter requestParameters.metric was null or undefined when calling labelServiceReadMetricLabel.');
            }
            const queryParameters = {};
            if (requestParameters.payloadMetric !== undefined) {
                queryParameters['payload.metric'] = requestParameters.payloadMetric;
            }
            if (requestParameters.payloadLabel !== undefined) {
                queryParameters['payload.label'] = requestParameters.payloadLabel;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadArticle !== undefined) {
                queryParameters['payload.article'] = requestParameters.payloadArticle;
            }
            if (requestParameters.payloadCategory !== undefined) {
                queryParameters['payload.category'] = requestParameters.payloadCategory;
            }
            if (requestParameters.payloadHasArticle !== undefined) {
                queryParameters['payload.hasArticle'] = requestParameters.payloadHasArticle;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/labels/v1/metrics/{metric}`.replace(`{${"metric"}}`, encodeURIComponent(String(requestParameters.metric))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadMetricLabelResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    labelServiceReadMetricLabel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.labelServiceReadMetricLabelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    labelServiceUpdateDimensionLabelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.dimension === null || requestParameters.dimension === undefined) {
                throw new runtime.RequiredError('dimension', 'Required parameter requestParameters.dimension was null or undefined when calling labelServiceUpdateDimensionLabel.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling labelServiceUpdateDimensionLabel.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/labels/v1/dimensions/{dimension}`.replace(`{${"dimension"}}`, encodeURIComponent(String(requestParameters.dimension))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleDimensionLabelToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadDimensionLabelResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    labelServiceUpdateDimensionLabel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.labelServiceUpdateDimensionLabelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    labelServiceUpdateMetricLabelRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.metric === null || requestParameters.metric === undefined) {
                throw new runtime.RequiredError('metric', 'Required parameter requestParameters.metric was null or undefined when calling labelServiceUpdateMetricLabel.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling labelServiceUpdateMetricLabel.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/labels/v1/metrics/{metric}`.replace(`{${"metric"}}`, encodeURIComponent(String(requestParameters.metric))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleMetricLabelToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadMetricLabelResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    labelServiceUpdateMetricLabel(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.labelServiceUpdateMetricLabelRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var LabelServiceListDimensionLabelDimensionEnum;
(function (LabelServiceListDimensionLabelDimensionEnum) {
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_OS"] = "DimensionOS";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_REGION"] = "DimensionRegion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CITY"] = "DimensionCity";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SOURCE"] = "DimensionSource";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CONTENT"] = "DimensionContent";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_TERM"] = "DimensionTerm";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_PATH"] = "DimensionPath";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_JOB"] = "DimensionJob";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_QS"] = "DimensionQS";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    LabelServiceListDimensionLabelDimensionEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(LabelServiceListDimensionLabelDimensionEnum || (LabelServiceListDimensionLabelDimensionEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceListDimensionLabelPayloadDimensionEnum;
(function (LabelServiceListDimensionLabelPayloadDimensionEnum) {
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_OS"] = "DimensionOS";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_REGION"] = "DimensionRegion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CITY"] = "DimensionCity";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SOURCE"] = "DimensionSource";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CONTENT"] = "DimensionContent";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_TERM"] = "DimensionTerm";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_PATH"] = "DimensionPath";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_JOB"] = "DimensionJob";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_QS"] = "DimensionQS";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    LabelServiceListDimensionLabelPayloadDimensionEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(LabelServiceListDimensionLabelPayloadDimensionEnum || (LabelServiceListDimensionLabelPayloadDimensionEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceListDimensionLabelPayloadCategoryEnum;
(function (LabelServiceListDimensionLabelPayloadCategoryEnum) {
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_UNKNOWN"] = "DimensionCategoryUnknown";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_AUDIENCE_SEGMENTS"] = "DimensionCategoryAudienceSegments";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CONTENT"] = "DimensionCategoryContent";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CONTENT_AND_CONVERSION_JOURNEYS"] = "DimensionCategoryContentAndConversionJourneys";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CONVERSIONS_AND_INTERACTIONS"] = "DimensionCategoryConversionsAndInteractions";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CRAWLERS"] = "DimensionCategoryCrawlers";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_DEVICE_AND_TECHNOLOGY"] = "DimensionCategoryDeviceAndTechnology";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_DOMAIN"] = "DimensionCategoryDomain";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_JOB_DATA"] = "DimensionCategoryJobData";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_LANDING_PAGES"] = "DimensionCategoryLandingPages";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_OTHER"] = "DimensionCategoryOther";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_PRODUCTS"] = "DimensionCategoryProducts";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_SESSION_TIMING"] = "DimensionCategorySessionTiming";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_TRAFFIC_AND_CAMPAIGNS"] = "DimensionCategoryTrafficAndCampaigns";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_INCENDIUM_CAMPAIGNS"] = "DimensionCategoryIncendiumCampaigns";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_TRANSACTIONS"] = "DimensionCategoryTransactions";
    LabelServiceListDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_USER_DATA_AND_DEMOGRAPHICS"] = "DimensionCategoryUserDataAndDemographics";
})(LabelServiceListDimensionLabelPayloadCategoryEnum || (LabelServiceListDimensionLabelPayloadCategoryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceReadDimensionLabelDimensionEnum;
(function (LabelServiceReadDimensionLabelDimensionEnum) {
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_OS"] = "DimensionOS";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_REGION"] = "DimensionRegion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CITY"] = "DimensionCity";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SOURCE"] = "DimensionSource";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CONTENT"] = "DimensionContent";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_TERM"] = "DimensionTerm";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_PATH"] = "DimensionPath";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_JOB"] = "DimensionJob";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_QS"] = "DimensionQS";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    LabelServiceReadDimensionLabelDimensionEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(LabelServiceReadDimensionLabelDimensionEnum || (LabelServiceReadDimensionLabelDimensionEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceReadDimensionLabelPayloadDimensionEnum;
(function (LabelServiceReadDimensionLabelPayloadDimensionEnum) {
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_OS"] = "DimensionOS";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_REGION"] = "DimensionRegion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CITY"] = "DimensionCity";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SOURCE"] = "DimensionSource";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CONTENT"] = "DimensionContent";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_TERM"] = "DimensionTerm";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_PATH"] = "DimensionPath";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_JOB"] = "DimensionJob";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_QS"] = "DimensionQS";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    LabelServiceReadDimensionLabelPayloadDimensionEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(LabelServiceReadDimensionLabelPayloadDimensionEnum || (LabelServiceReadDimensionLabelPayloadDimensionEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceReadDimensionLabelPayloadCategoryEnum;
(function (LabelServiceReadDimensionLabelPayloadCategoryEnum) {
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_UNKNOWN"] = "DimensionCategoryUnknown";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_AUDIENCE_SEGMENTS"] = "DimensionCategoryAudienceSegments";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CONTENT"] = "DimensionCategoryContent";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CONTENT_AND_CONVERSION_JOURNEYS"] = "DimensionCategoryContentAndConversionJourneys";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CONVERSIONS_AND_INTERACTIONS"] = "DimensionCategoryConversionsAndInteractions";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_CRAWLERS"] = "DimensionCategoryCrawlers";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_DEVICE_AND_TECHNOLOGY"] = "DimensionCategoryDeviceAndTechnology";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_DOMAIN"] = "DimensionCategoryDomain";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_JOB_DATA"] = "DimensionCategoryJobData";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_LANDING_PAGES"] = "DimensionCategoryLandingPages";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_OTHER"] = "DimensionCategoryOther";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_PRODUCTS"] = "DimensionCategoryProducts";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_SESSION_TIMING"] = "DimensionCategorySessionTiming";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_TRAFFIC_AND_CAMPAIGNS"] = "DimensionCategoryTrafficAndCampaigns";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_INCENDIUM_CAMPAIGNS"] = "DimensionCategoryIncendiumCampaigns";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_TRANSACTIONS"] = "DimensionCategoryTransactions";
    LabelServiceReadDimensionLabelPayloadCategoryEnum["DIMENSION_CATEGORY_USER_DATA_AND_DEMOGRAPHICS"] = "DimensionCategoryUserDataAndDemographics";
})(LabelServiceReadDimensionLabelPayloadCategoryEnum || (LabelServiceReadDimensionLabelPayloadCategoryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceReadMetricLabelMetricEnum;
(function (LabelServiceReadMetricLabelMetricEnum) {
    LabelServiceReadMetricLabelMetricEnum["METRIC_NOT_SET"] = "MetricNotSet";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    LabelServiceReadMetricLabelMetricEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    LabelServiceReadMetricLabelMetricEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    LabelServiceReadMetricLabelMetricEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SPEND"] = "MetricSpend";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CPA"] = "MetricCPA";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CAC"] = "MetricCAC";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ECR"] = "MetricECR";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CPNV"] = "MetricCPNV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    LabelServiceReadMetricLabelMetricEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RANK"] = "MetricRank";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    LabelServiceReadMetricLabelMetricEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    LabelServiceReadMetricLabelMetricEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    LabelServiceReadMetricLabelMetricEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    LabelServiceReadMetricLabelMetricEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    LabelServiceReadMetricLabelMetricEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_PUBLISHER_CLICKS_DIFFERENCE"] = "MetricPublisherClicksDifference";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VELOCITY_PAGEVIEW_COUNT"] = "MetricVelocityPageviewCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_COUNT"] = "MetricVelocitySessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NOT_VELOCITY_SESSION_COUNT"] = "MetricNotVelocitySessionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_REVENUE"] = "MetricVelocitySessionRevenue";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_REVENUE_IMPACT"] = "MetricVelocitySessionRevenueImpact";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNotVelocitySessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricVelocitySessionMacroConversionCount";
    LabelServiceReadMetricLabelMetricEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricNotVelocitySessionMacroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricVelocitySessionMacroConversionRate";
    LabelServiceReadMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE_UPLIFT"] = "MetricVelocitySessionMacroConversionRateUplift";
})(LabelServiceReadMetricLabelMetricEnum || (LabelServiceReadMetricLabelMetricEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceReadMetricLabelPayloadMetricEnum;
(function (LabelServiceReadMetricLabelPayloadMetricEnum) {
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NOT_SET"] = "MetricNotSet";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SPEND"] = "MetricSpend";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CPA"] = "MetricCPA";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CAC"] = "MetricCAC";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ECR"] = "MetricECR";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CPNV"] = "MetricCPNV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RANK"] = "MetricRank";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_PUBLISHER_CLICKS_DIFFERENCE"] = "MetricPublisherClicksDifference";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VELOCITY_PAGEVIEW_COUNT"] = "MetricVelocityPageviewCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VELOCITY_SESSION_COUNT"] = "MetricVelocitySessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NOT_VELOCITY_SESSION_COUNT"] = "MetricNotVelocitySessionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VELOCITY_SESSION_REVENUE"] = "MetricVelocitySessionRevenue";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VELOCITY_SESSION_REVENUE_IMPACT"] = "MetricVelocitySessionRevenueImpact";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNotVelocitySessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricVelocitySessionMacroConversionCount";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricNotVelocitySessionMacroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricVelocitySessionMacroConversionRate";
    LabelServiceReadMetricLabelPayloadMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE_UPLIFT"] = "MetricVelocitySessionMacroConversionRateUplift";
})(LabelServiceReadMetricLabelPayloadMetricEnum || (LabelServiceReadMetricLabelPayloadMetricEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceReadMetricLabelPayloadCategoryEnum;
(function (LabelServiceReadMetricLabelPayloadCategoryEnum) {
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_UNKNOWN"] = "MetricCategoryUnknown";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_COST_EFFICIENCY"] = "MetricCategoryCostEfficiency";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_PROFITABILITY"] = "MetricCategoryProfitability";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_ECOMMERCE"] = "MetricCategoryEcommerce";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_ATTRIBUTION"] = "MetricCategoryAttribution";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_RETENTION"] = "MetricCategoryRetention";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_CUSTOMER_LIFETIME_VALUE"] = "MetricCategoryCustomerLifetimeValue";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_PUBLISHER_DATA"] = "MetricCategoryPublisherData";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_TRAFFIC"] = "MetricCategoryTraffic";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_PREDICTIVE_ANALYTICS"] = "MetricCategoryPredictiveAnalytics";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_PERFORMANCE"] = "MetricCategoryPerformance";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_ENGAGEMENT"] = "MetricCategoryEngagement";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_REVENUE"] = "MetricCategoryRevenue";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_INTERACTIONS"] = "MetricCategoryInteractions";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_CONVERSION"] = "MetricCategoryConversion";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_ADVANCED"] = "MetricCategoryAdvanced";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_INVENTORY"] = "MetricCategoryInventory";
    LabelServiceReadMetricLabelPayloadCategoryEnum["METRIC_CATEGORY_VELOCITY"] = "MetricCategoryVelocity";
})(LabelServiceReadMetricLabelPayloadCategoryEnum || (LabelServiceReadMetricLabelPayloadCategoryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceUpdateDimensionLabelDimensionEnum;
(function (LabelServiceUpdateDimensionLabelDimensionEnum) {
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_OS"] = "DimensionOS";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_REGION"] = "DimensionRegion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CITY"] = "DimensionCity";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SOURCE"] = "DimensionSource";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CONTENT"] = "DimensionContent";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_TERM"] = "DimensionTerm";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_PATH"] = "DimensionPath";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_JOB"] = "DimensionJob";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_QS"] = "DimensionQS";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    LabelServiceUpdateDimensionLabelDimensionEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(LabelServiceUpdateDimensionLabelDimensionEnum || (LabelServiceUpdateDimensionLabelDimensionEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LabelServiceUpdateMetricLabelMetricEnum;
(function (LabelServiceUpdateMetricLabelMetricEnum) {
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NOT_SET"] = "MetricNotSet";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    LabelServiceUpdateMetricLabelMetricEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    LabelServiceUpdateMetricLabelMetricEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SPEND"] = "MetricSpend";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CPA"] = "MetricCPA";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CAC"] = "MetricCAC";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ECR"] = "MetricECR";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CPNV"] = "MetricCPNV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RANK"] = "MetricRank";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_PUBLISHER_CLICKS_DIFFERENCE"] = "MetricPublisherClicksDifference";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VELOCITY_PAGEVIEW_COUNT"] = "MetricVelocityPageviewCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_COUNT"] = "MetricVelocitySessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NOT_VELOCITY_SESSION_COUNT"] = "MetricNotVelocitySessionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_REVENUE"] = "MetricVelocitySessionRevenue";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_REVENUE_IMPACT"] = "MetricVelocitySessionRevenueImpact";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNotVelocitySessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricVelocitySessionMacroConversionCount";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricNotVelocitySessionMacroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricVelocitySessionMacroConversionRate";
    LabelServiceUpdateMetricLabelMetricEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE_UPLIFT"] = "MetricVelocitySessionMacroConversionRateUplift";
})(LabelServiceUpdateMetricLabelMetricEnum || (LabelServiceUpdateMetricLabelMetricEnum = {}));
