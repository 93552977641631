import { ApiOperator } from "@incendium/api";
import { exhaustiveCheck } from "@incendium/inc-ts-helpers";
import { formatEnumVal } from "./enumToText";

// todo: improve language
export const friendlyOperator = (o: ApiOperator | undefined): string => {
  if (!o) {
    o = ApiOperator.OPERATOR_UNASSIGNED;
  }
  // backwards compatabilty
  if (`${o}` === `${o}`.toLowerCase()) {
    o = `${o}`.toUpperCase() as ApiOperator;
  }

  switch (o) {
    case ApiOperator.OPERATOR_UNASSIGNED:
      return "";
    case ApiOperator.EQUAL:
    case ApiOperator.NOT_EQUAL:
    case ApiOperator.IS_IN:
    case ApiOperator.NOT_IN:
    case ApiOperator.STARTS_WITH:
    case ApiOperator.CONTAINS:
    case ApiOperator.IS_GREATER_THAN:
    case ApiOperator.IS_LESS_THAN:
    case ApiOperator.IS_GREATER_THAN_OR_EQUAL:
    case ApiOperator.IS_LESS_THAN_OR_EQUAL:
    case ApiOperator.ONE_OF:
    case ApiOperator.NOT_CONTAINS:
    case ApiOperator.REGEX:
      return formatEnumVal(o);
    default:
      exhaustiveCheck(o);
  }
};
