import { ApiMetric } from "@incendium/api";
import { Autocomplete, OutlinedTextFieldProps, TextField } from "@mui/material";
import { useMetricName } from "features/analytics/hooks/useMetricName";
import { enumToArray } from "Helpers/enumToText";
import { CallbackOrVal } from "Interfaces";

interface IMetricSelectorProps
  extends Omit<OutlinedTextFieldProps, "onChange" | "variant"> {
  metric: ApiMetric | null;
  setMetric: (v: CallbackOrVal<ApiMetric | null>) => void;
  initialMetric?: ApiMetric;
  label?: string;
}

function MetricSelector({
  metric,
  setMetric,
  initialMetric,
  label,
  fullWidth,
  ...rest
}: IMetricSelectorProps) {
  const metricToName = useMetricName();
  const options = enumToArray(ApiMetric).filter(
    (m) => m !== ApiMetric.METRIC_NOT_SET
  );

  return (
    <Autocomplete
      options={options}
      value={metric}
      getOptionLabel={(o) => metricToName(o)}
      onChange={(e, v) => {
        setMetric(v || initialMetric || ApiMetric.METRIC_PAGE_VIEWS_COUNT);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || "Select Metric To Explore"}
          {...rest}
        />
      )}
    />
  );
}

export default MetricSelector;
