/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiChannelDefault;
(function (ApiChannelDefault) {
    ApiChannelDefault["CHANNEL_UNKNOWN"] = "DEFAULT_CHANNEL_UNKNOWN";
    ApiChannelDefault["CHANNEL_DIRECT"] = "DEFAULT_CHANNEL_DIRECT";
    ApiChannelDefault["CROSS_NETWORK"] = "DEFAULT_CROSS_NETWORK";
    ApiChannelDefault["PAID_SHOPPING"] = "DEFAULT_PAID_SHOPPING";
    ApiChannelDefault["PAID_SEARCH"] = "DEFAULT_PAID_SEARCH";
    ApiChannelDefault["PAID_SOCIAL"] = "DEFAULT_PAID_SOCIAL";
    ApiChannelDefault["PAID_VIDEO"] = "DEFAULT_PAID_VIDEO";
    ApiChannelDefault["DISPLAY"] = "DEFAULT_DISPLAY";
    ApiChannelDefault["PAID_OTHER"] = "DEFAULT_PAID_OTHER";
    ApiChannelDefault["ORGANIC_SHOPPING"] = "DEFAULT_ORGANIC_SHOPPING";
    ApiChannelDefault["ORGANIC_SOCIAL"] = "DEFAULT_ORGANIC_SOCIAL";
    ApiChannelDefault["ORGANIC_VIDEO"] = "DEFAULT_ORGANIC_VIDEO";
    ApiChannelDefault["ORGANIC_SEARCH"] = "DEFAULT_ORGANIC_SEARCH";
    ApiChannelDefault["REFERRAL"] = "DEFAULT_REFERRAL";
    ApiChannelDefault["EMAIL"] = "DEFAULT_EMAIL";
    ApiChannelDefault["AFFILIATES"] = "DEFAULT_AFFILIATES";
    ApiChannelDefault["AUDIO"] = "DEFAULT_AUDIO";
    ApiChannelDefault["SMS"] = "DEFAULT_SMS";
    ApiChannelDefault["MOBILE_PUSH_NOTIFICATIONS"] = "DEFAULT_MOBILE_PUSH_NOTIFICATIONS";
})(ApiChannelDefault || (ApiChannelDefault = {}));
export function ApiChannelDefaultFromJSON(json) {
    return ApiChannelDefaultFromJSONTyped(json, false);
}
export function ApiChannelDefaultFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiChannelDefaultToJSON(value) {
    return value;
}
