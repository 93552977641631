import {
  ApiDimensionCategory,
  ApiMetricCategory,
  ApiSimpleDimensionLabel,
  ApiSimpleMetricLabel,
} from "@incendium/api";
import { Box, Button, DrawerProps, MenuItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import StyledDrawer, {
  StyledDrawerActions,
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { SimpleTextEditor } from "features/uiBuilder";
import { camelToText } from "Helpers/camelToText";
import { enumToArray } from "Helpers/enumToText";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";

interface IAnalyticsLabelDrawerProps extends Omit<DrawerProps, "onSubmit"> {
  label: ApiSimpleMetricLabel | ApiSimpleDimensionLabel;
  type: "metric" | "dimension";
  onSubmit: (l: ApiSimpleMetricLabel | ApiSimpleDimensionLabel) => void;
}

function AnalyticsLabelDrawer({
  open,
  onClose,
  label,
  type,
  onSubmit,
}: IAnalyticsLabelDrawerProps) {
  const [localLabel, setLocalLabel] = useState(label);

  useEffect(() => {
    setLocalLabel(label);
  }, [label]);

  const submit = useCallback(() => {
    onSubmit(localLabel);
  }, [localLabel, onSubmit]);

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">
          Edit {localLabel?.[type === "metric" ? "metric" : "dimension"]}
        </Typography>
      </StyledDrawerTitle>
      <ValidatorForm onSubmit={submit}>
        <StyledDrawerContainer>
          <Stack spacing={2}>
            <TextValidator
              label="Label"
              value={localLabel.label || ""}
              variant="outlined"
              name="label"
              fullWidth
              size="small"
              validators={["required"]}
              errorMessages={["Label is required"]}
              onChange={(e: any) =>
                setLocalLabel((s) =>
                  produce(s, (draft) => {
                    draft!.label = e.target.value;
                  })
                )
              }
            />
            <SelectValidator
              variant="outlined"
              fullWidth
              label="Grouping / Category"
              name="category"
              size="small"
              value={localLabel?.category || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, v) => {
                setLocalLabel((s) =>
                  produce(s, (draft) => {
                    draft!.category = e.target.value as
                      | ApiDimensionCategory
                      | ApiMetricCategory;
                  })
                );
              }}
            >
              {enumToArray(
                type === "metric" ? ApiMetricCategory : ApiDimensionCategory
              ).map((t) => (
                <MenuItem key={t} value={t}>
                  {camelToText(t)
                    .replace("Metric Category", "")
                    .replace("Dimension Category", "")}
                </MenuItem>
              ))}
            </SelectValidator>
            <TextValidator
              label="Short Description"
              value={localLabel?.description || ""}
              variant="outlined"
              name="description"
              size="small"
              multiline
              minRows={2}
              maxRows={4}
              fullWidth
              validators={["required", "maxStringLength:250"]}
              errorMessages={[
                "Short Description is required",
                "Max 250 characters",
              ]}
              onChange={(e: any) =>
                setLocalLabel((s) =>
                  produce(s, (draft) => {
                    draft!.description = e.target.value;
                  })
                )
              }
            />
            <Box>
              <Typography variant="subtitle2" mb={1}>
                Article
              </Typography>
              <SimpleTextEditor
                height={700}
                content={localLabel.article || ""}
                setContent={(v: string) =>
                  setLocalLabel((s) =>
                    produce(s, (draft) => {
                      draft!.article = v;
                    })
                  )
                }
              />
            </Box>
          </Stack>
        </StyledDrawerContainer>
        <StyledDrawerActions>
          <Button
            onClick={(e) => onClose!(e, "backdropClick")}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </StyledDrawerActions>
      </ValidatorForm>
    </StyledDrawer>
  );
}

export default AnalyticsLabelDrawer;
