import {
  StatCard,
  StatCardCenterContainer,
  StatCardTypography,
} from "Components/UI/StatCard";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import useChartData from "features/analytics/hooks/useChartData";
import { IAnalyticsStatCardProps } from "features/analytics/types/types";
import { ApiChartTemplate, ApiDimension, ApiMetric } from "@incendium/api";
import { useDebounce } from "react-use";
import { useCallback } from "react";
import { uniqueMetricsFromChart } from "features/analytics/utils/utils";
import { useChartDescriptionDrawer } from "Providers/ChartDescriptionDrawerProvider";

function AnalyticsStatCard({
  chart,
  renderBody,
  setDirection,
  chartOutput: propChartOutput,
  includeTrendedSpark,
  noBg,
  ...rest
}: IAnalyticsStatCardProps) {
  const { chartOutput, chartComparisonOutput, calcNumberOfDays, recordedIn } =
    useFromToContext();
  const { openDrawer } = useChartDescriptionDrawer();
  const { selectedProject } = useSelectedProject();

  const { chartData, loading } = useChartData(
    selectedProject,
    {
      ...chart,
      template: ApiChartTemplate.SNAPSHOT,
    },
    propChartOutput || chartOutput
  );

  const { chartData: trendedChartData, loading: trendedLoading } = useChartData(
    selectedProject,
    {
      ...chart,
      dimension: [
        calcNumberOfDays(propChartOutput || chartOutput) > 200
          ? ApiDimension.DIMENSION_SESSION_START_BY_WEEK
          : ApiDimension.DIMENSION_SESSION_START_BY_DAY,
      ],
      template: ApiChartTemplate.TREND,
    },
    includeTrendedSpark ? propChartOutput || chartOutput : null
  );
  const { chartData: comparismentChartData, loading: comparsimentLoading } =
    useChartData(selectedProject, chart, chartComparisonOutput);

  const showData = useDebounce(
    () =>
      chartData?.data &&
      chartData?.data.length > 0 &&
      (!chartComparisonOutput || comparismentChartData)
        ? true
        : false,
    2000,
    [chartData, chartComparisonOutput, comparismentChartData]
  );

  const onDescribe = useCallback(() => {
    const metrics = uniqueMetricsFromChart(chart);
    openDrawer(
      (chart.dimension || []) as ApiDimension[],
      metrics as ApiMetric[],
      chart.name && chart.name !== "" ? `${chart.name} Metrics` : undefined
    );
  }, [chart, openDrawer]);

  return (
    <StatCard
      bgIndex={chart.displayOptions?.statDesignIndex}
      noBg={noBg}
      loading={loading || comparsimentLoading || trendedLoading}
      recordedIn={recordedIn(propChartOutput || chartOutput)}
      onDescribe={onDescribe}
      direction={
        setDirection && chartData?.data
          ? setDirection(chartData.data, comparismentChartData?.data)
          : rest.direction
      }
      {...rest}
    >
      <StatCardCenterContainer>
        {chartData && showData ? (
          renderBody(
            chartData!.data,
            comparismentChartData?.data,
            trendedChartData?.data
          )
        ) : (
          <StatCardTypography size="small">No Data</StatCardTypography>
        )}
      </StatCardCenterContainer>
    </StatCard>
  );
}

export default AnalyticsStatCard;
