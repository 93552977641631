import { ApiLocation } from "@incendium/api";
import { locationService } from "Apis";

export const saveVelocity = async (
  projectId: number,
  location: ApiLocation
) => {
  const res = await locationService.locationServicePatchLocationVelocity({
    projectId,
    locationId: location.id as number,
    payload: {
      velocityType: location.velocityType,
      velocityLimit: location.velocityLimit,
      velocityRender: location.velocityRender,
    },
  });

  return res.result;
};
