import { Box, Button, styled } from "@mui/material";
import { useTheme } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import produce from "immer";
import React from "react";
import ConditionalGroupCard from "./ConditionalGroupCard";
import { useConditionGroupContext } from "./ConditionGroupProvider";

const StyledOrBox = styled(Box)(({ theme }) => ({
  background: theme.palette.info.dark,
  color: theme.palette.common.white,
  width: 34,
  height: 34,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  fontSize: 12,
  margin: theme.spacing(2.5),
  position: "relative",
  zIndex: 1,

  "& > span": {
    background: theme.palette.info.dark,
    zIndex: 1,
  },
  "&::before": {
    content: "''",
    position: "absolute",
    left: 16,
    borderLeft: `1px solid ${theme.palette.info.dark}`,
    height: 74,
    width: 32,
    zIndex: 0,
  },
}));

function ConditionalGroup() {
  const theme = useTheme();
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();

  const addConditionGroup = () => {
    setConditionGroups(
      produce(conditionGroups, (draft) => {
        draft.push({
          conditions: [{}],
        });
      })
    );
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <AnimatePresence>
        {conditionGroups.map((group, i) => (
          <React.Fragment key={i}>
            <ConditionalGroupCard idx={i} />
            {i === conditionGroups.length - 1 ? (
              <Box
                key={"single-or"}
                my={theme.spacing(2.5)}
                component={motion.div}
                animate={{ scale: 1, opacity: 1 }}
                initial={{ scale: 0.8, opacity: 0 }}
                exit={{ opacity: 0, transition: { duration: 0 } }}
              >
                <Button
                  color="primary"
                  disableElevation
                  size="small"
                  variant="contained"
                  sx={{ minWidth: 100 }}
                  onClick={addConditionGroup}
                >
                  + or
                </Button>
              </Box>
            ) : (
              <Box
                component={motion.div}
                animate={{
                  scale: 1,
                  opacity: 1,
                }}
                initial={{ scale: 0.8, opacity: 0 }}
                exit={{ y: -50, opacity: 0 }}
                transition={{
                  delay: 0.2,
                }}
                key={`or-${i}`}
              >
                <StyledOrBox>
                  <span>OR</span>
                </StyledOrBox>
              </Box>
            )}
          </React.Fragment>
        ))}
      </AnimatePresence>
    </Box>
  );
}

export default ConditionalGroup;
