import { Typography } from "@mui/material";
import { useSelectedProject } from "Hooks";
import { useEffect } from "react";
import ChartBuilderAttributes from "./ChartBuilderAttributes";
import ChartBuilderName from "./ChartBuilderName";
import ChartBuilderOrderLimit from "./ChartBuilderOrderLimit";
import {
  ChartBuilderSidebarBlock,
  ChartBuilderSidebarContainer,
} from "./ChartBuilderSidebarBlock";
import ChartBuilderYAxis from "./ChartBuilderYAxis";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import produce from "immer";
import { ApiChartAttribute, ApiDimension } from "@incendium/api";

function ChartBuilderMap() {
  const { selectedProject } = useSelectedProject();
  const { chart, setChart } = useReportBuilderContext();

  useEffect(() => {
    setChart((oldChart) =>
      produce(oldChart, (draft) => {
        draft.dimension = [
          ApiDimension.DIMENSION_LON_LAT,
          ApiDimension.DIMENSION_COUNTRY,
          ApiDimension.DIMENSION_CITY,
        ];
      })
    );
  }, [setChart]);

  return (
    <ChartBuilderSidebarContainer>
      <ChartBuilderSidebarBlock>
        <Typography variant="h3">Map Setup!</Typography>
      </ChartBuilderSidebarBlock>
      <ChartBuilderName />

      <ChartBuilderYAxis
        noControls
        noRemove
        noDrag
        y={"l"}
        title="Map Metric"
        subTitle="This is the value the data on your map to display"
      />
      <ChartBuilderOrderLimit
        title="Limit (See description)"
        subTitle="This limits the number of results returned, results are then grouped by nearist 300 km, this means that if you set a limit of 5 it does not mean you will have 5 points on the map."
        hideOrder
      />
      {selectedProject && (
        <ChartBuilderAttributes
          attributes={chart.attributes}
          setAttributes={(attributes) => {
            setChart((chart) =>
              produce(chart, (draft) => {
                draft.attributes = attributes as ApiChartAttribute[];
              })
            );
          }}
        />
      )}
    </ChartBuilderSidebarContainer>
  );
}

export default ChartBuilderMap;
