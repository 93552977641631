import { ApiDimension } from "@incendium/api";
import { useDimensionLabels } from "features/analytics/hooks/useDimensionLabels";
import { useCallback } from "react";

export const useDimensionName = () => {
  const { dimensionLabels } = useDimensionLabels();

  return useCallback(
    (dimension: ApiDimension, plural?: boolean) => {
      const found = dimensionLabels.find((ml) => ml.dimension === dimension);
      const name = found?.label && found.label !== "" ? found.label : dimension;

      return plural ? `${name}s` : name;
    },
    [dimensionLabels]
  );
};
