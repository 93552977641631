import {
  ApiSimpleSourceCategory,
  ApiSimpleSourceCategoryValue,
} from "@incendium/api";
import {
  Button,
  DrawerProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AnimatedList from "Components/AnimatedList/AnimatedList";
import GlassCard from "Components/GlassCard/GlassCard";
import Loading from "Components/Loading/Loading";
import StyledDrawer, {
  StyledDrawerActions,
  StyledDrawerContainer,
  StyledDrawerTitle,
} from "Components/UI/StyledDrawer";
import { saveSourceCategory } from "features/sourceCategories/services/sourceCategoryService";
import { useNotification } from "Hooks";
import produce from "immer";
import { useCallback, useEffect, useState, useTransition } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function Row({
  row,
  onEdit,
}: {
  row: ApiSimpleSourceCategoryValue;
  onEdit: (v: ApiSimpleSourceCategoryValue) => void;
}) {
  const handleChange = useCallback(
    (value: string) => {
      onEdit(
        produce(row, (draft) => {
          draft.value = value;
        })
      );
    },
    [onEdit, row]
  );

  return (
    <TextField
      fullWidth
      name="value"
      size="small"
      label="Type Value"
      value={row.value || ""}
      onChange={(e: any) => {
        handleChange(e.target.value);
      }}
    />
  );
}

interface ISourceCategoriesDrawerProps extends DrawerProps {
  projectId: number;
  category: ApiSimpleSourceCategory;
  onSaved: (c: ApiSimpleSourceCategory) => void;
}

function SourceCategoriesDrawer({
  open,
  onClose,
  projectId,
  category,
  onSaved,
}: ISourceCategoriesDrawerProps) {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [isPending, startTransition] = useTransition();
  const [internalCategory, setInternalCategory] =
    useState<ApiSimpleSourceCategory>(category);
  const [internalCategoryValues, setInternalCategoryValues] = useState<
    ApiSimpleSourceCategoryValue[]
  >(category.values || []);

  useEffect(() => {
    setInternalCategory(category);
    startTransition(() => {
      setInternalCategoryValues(category.values || []);
    });
  }, [category]);

  const onSubmit = useCallback(async () => {
    try {
      const res = await saveSourceCategory(
        projectId,
        internalCategory,
        internalCategoryValues
          .filter((v) => v.value && v.value !== "")
          .map((v) => v.value || "")
      );
      showSuccessNotification(`${res.category!.name} Saved`);
      onSaved(res.category!);
    } catch (error) {
      showErrorNotification("Failed to save category");
    }
  }, [
    projectId,
    internalCategory,
    internalCategoryValues,
    onSaved,
    showErrorNotification,
    showSuccessNotification,
  ]);

  useEffect(() => {
    if (!internalCategory.values || internalCategory.values.length === 0) {
      setInternalCategory((c) =>
        produce(c, (draft) => {
          draft.values = [{}];
        })
      );
    }
  }, [setInternalCategory, internalCategory]);

  const onAdd = useCallback(() => {
    setInternalCategory((c) =>
      produce(c, (draft) => {
        if (!draft.values) {
          draft.values = [];
        }
        draft.values.push({});
      })
    );
  }, [setInternalCategory]);

  const onRemove = useCallback(
    (idx: number) => {
      setInternalCategory((c) =>
        produce(c, (draft) => {
          draft.values?.splice(idx, 1);
        })
      );
    },
    [setInternalCategory]
  );
  const onEdit = useCallback(
    (idx: number) => (value: any) => {
      setInternalCategoryValues((v) =>
        produce(v, (draft) => {
          draft[idx] = value;
        })
      );
    },
    [setInternalCategoryValues]
  );

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <StyledDrawerTitle>
        <Typography variant="subtitle1">
          {category?.id ? `Edit ${category.name}` : "New Source Category"}
        </Typography>
      </StyledDrawerTitle>
      <ValidatorForm onSubmit={onSubmit}>
        <StyledDrawerContainer>
          <Stack spacing={4}>
            <TextValidator
              label="Category Name"
              value={internalCategory.name || ""}
              variant="outlined"
              name="name"
              fullWidth
              validators={["required"]}
              errorMessages={["Name is required"]}
              onChange={(e: any) =>
                setInternalCategory((c) =>
                  produce(c, (draft) => {
                    draft.name = e.target.value;
                  })
                )
              }
            />
            <GlassCard noShadow>
              <Typography variant="subtitle2" mb={2}>
                Add Unique Values for this Category
              </Typography>
              {isPending ? (
                <Loading />
              ) : (
                <AnimatedList
                  onAdd={onAdd}
                  onRemove={onRemove}
                  onEdit={onEdit}
                  items={internalCategoryValues}
                  RowComponent={Row}
                />
              )}
            </GlassCard>
          </Stack>
        </StyledDrawerContainer>
        <StyledDrawerActions>
          <Button
            onClick={(e) => onClose!(e, "backdropClick")}
            color="secondary"
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </StyledDrawerActions>
      </ValidatorForm>
    </StyledDrawer>
  );
}

export default SourceCategoriesDrawer;
