import { ApiLocation, ApiLocationVelocityType } from "@incendium/api";
import { Edit } from "@mui/icons-material";
import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SpacedList from "Components/UI/SpacedList";
import { cell1Icon } from "consts";
import { velocityMapByType } from "features/velocity/utils";

interface IVelocityListProps {
  locations: ApiLocation[];
  onEdit: (v: ApiLocation) => void;
}

function VelocityList({ locations, onEdit }: IVelocityListProps) {
  return (
    <SpacedList title="List of Locations" height={"calc(100vh - 170px)"}>
      <TableHead>
        <TableRow>
          <TableCell>Location Name</TableCell>
          <TableCell>Velocity Settings</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {locations.map((location) => (
          <TableRow key={location.id}>
            <TableCell sx={{ width: 250 }}>{location.name}</TableCell>
            <TableCell>
              <span>
                Velocity :{" "}
                {
                  velocityMapByType(
                    location.velocityType || ApiLocationVelocityType.DISABLED
                  )?.label
                }
              </span>
              {location.velocityType !== ApiLocationVelocityType.DISABLED && (
                <>
                  {" - Limit : "}
                  {location.velocityLimit}
                  {" - "}
                  {location.velocityRender ? "Prerender" : "Prefetch"}
                </>
              )}
            </TableCell>

            <TableCell align="right" sx={{ width: cell1Icon }}>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => onEdit(location)}
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </SpacedList>
  );
}

export default VelocityList;
