import { ApiChartAttribute, ApiDimension, ApiOperator } from "@incendium/api";
import { Chip, Typography } from "@mui/material";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";
import { formatEnumVal } from "Helpers/enumToText";
import produce from "immer";
import { useCallback } from "react";

interface IChartBuilderAttributionPillProps {
  attribute: ApiChartAttribute;
  attributes: ApiChartAttribute[];
  setAttributes: (val: ApiChartAttribute[]) => void;
  onClick?: (val: ApiChartAttribute) => void;
}
function ChartBuilderAttributionPill({
  attribute,
  attributes,
  setAttributes,
  onClick,
}: IChartBuilderAttributionPillProps) {
  const dimensionToName = useDimensionName();
  const onDelete = useCallback(() => {
    setAttributes(
      produce(attributes, (draft) => {
        const idx = (draft || []).findIndex(
          (att) => att.key === attribute.key && att.value === attribute.value
        );
        if (idx >= 0) {
          draft.splice(idx, 1);
        }
      })
    );
  }, [attribute, attributes, setAttributes]);

  return (
    <Chip
      sx={{ maxWidth: 300 }}
      label={
        <Typography variant="body2" color={"inherit"}>
          <strong>{dimensionToName(attribute.key as ApiDimension)}</strong> - [
          {formatEnumVal(attribute.operator || ApiOperator.EQUAL)}] -{" "}
          {attribute.value}
        </Typography>
      }
      onDelete={onDelete}
      onClick={onClick ? () => onClick(attribute) : undefined}
    />
  );
}

export default ChartBuilderAttributionPill;
