import { checkLogin, initializeClientsThunk } from "Actions";
import { IStoreState } from "Interfaces";
import { Login } from "Pages/Login";
import { Logout } from "Pages/Logout";
import { ForgotPassword } from "Pages/Password/ForgotPassword";
import ResetPassword from "Pages/Password/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { useMount } from "Hooks";
import { ProtectedRoute } from "Components/ProtectedRoute";
import React, { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { usePrevious } from "react-use";
import { ApiACLRole } from "@incendium/api";
import { useUser } from "Hooks/useUser";
import { IProtectedRoute, useProtectedRoutes } from "Hooks/useProtectedRoutes";
import { clientListSelector } from "Selectors/clientListSelector";
import SingleReportPage from "Pages/Analyse/SingleReportPage";
import AnalyticsProvider from "Providers/AnalyticsProvider";
import DashboardLayout from "Components/DashboardLayout/DashboardLayout";
import withPagetitle from "HoC/withPagetitle";
import withDatepicker from "HoC/withDatepicker";
import withDocumentTitle from "HoC/withDocumentTitle";
import { withClientorProjects } from "HoC/withClientorProjects";
import Loading from "Components/Loading/Loading";
import { waitForOnWindow } from "@incendium/inc-ts-helpers";
import AnalysePage from "Pages/Analyse/AnalysePage";
import FromToProvider from "Providers/FromToProvider";
import InitialPassword from "Pages/Password/InitialPassword";
import { ChartDescriptionDrawerProvider } from "Providers/ChartDescriptionDrawerProvider";
// import FromToProvider from "Providers/FromToProvider";
// import withFromTo from "HoC/withFromToProvider";

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    waitForOnWindow("inc", (error) => {
      if (error) {
        return;
      }
      if (typeof (window as any).inc === "function") {
        (window as any).inc("run");
      } else {
        console.warn("`inc` is not defined or not a function.");
      }
    });
  }, [location]);

  return null;
};

const ProjectAnalysisCatch = () => {
  const { user } = useUser();
  const location = useLocation();
  const firstPermision = useMemo(() => {
    const pParts = location.pathname.match(/\/projects\/[0-9]./g);
    if (pParts && pParts.length > 0) {
      const projectIdParts = pParts[0].split("/projects/");
      if (projectIdParts.length > 1) {
        const projectID = projectIdParts[1];
        const foundPermision = (user.permissions || []).find(
          (p) => p.projectId === Number(projectID)
        );
        if (foundPermision) {
          return foundPermision;
        }
      }
    }

    return (user.permissions || [])[0];
  }, [user, location]);
  const state = useSelector(clientListSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.hasLoaded) {
      return;
    }
    dispatch(initializeClientsThunk());
  }, [dispatch, state]);

  const client = useMemo(() => {
    if (!state.hasLoaded) {
      return null;
    }

    return state.list.find((c) =>
      (c.projects || []).map((p) => p.id).includes(firstPermision.projectId)
    );
  }, [state, firstPermision]);

  if (!state.hasLoaded || !client) {
    return <Loading fullHeight />;
  }

  if (
    [ApiACLRole.PROJECT_ADMIN, ApiACLRole.PROJECT_OBSERVER].includes(
      firstPermision.role!
    )
  ) {
    return (
      <Redirect
        to={`/clients/${client.id}/projects/${firstPermision.projectId}`}
      />
    );
  }

  return (
    <Redirect
      to={`/clients/${client.id}/projects/${firstPermision.projectId}/analyse`}
    />
  );
};

const CatchAll = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
  const { user } = useUser();
  const firstPermision = (user?.permissions || [])[0];
  if (!firstPermision) {
    return <Loading fullHeight />;
  }

  if (
    [
      ApiACLRole.ANALYTICS_ADMIN,
      ApiACLRole.ANALYTICS_OBSERVER,
      ApiACLRole.PROJECT_ADMIN,
      ApiACLRole.PROJECT_OBSERVER,
    ].includes(firstPermision.role!)
  ) {
    return <ProjectAnalysisCatch />;
  }

  return <>{children}</>;
};

export const NRoute = (props: IProtectedRoute) => {
  const tokens = useSelector((state: IStoreState) => state.auth.tokens);
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const { user, hasPermission } = useUser(tokens);

  const singleReportPage = matchPath(location.pathname, {
    path: "/reports/:hash",
    exact: true,
    strict: false,
  });

  const isAccountPath = matchPath(location.pathname, {
    path: [
      "/clients/:clientId/projects/:projectId/account",
      "/clients/:clientId/projects/:projectId/account/payment",
      "/account",
      "/accounts/payment/thank-you/:projectId",
    ],
    exact: true,
    strict: false,
  });

  const PageComponent = useMemo(() => {
    let c = props.component as React.ComponentType<any>;
    c = withDocumentTitle(c);
    if (props.pageTitle) {
      c = withPagetitle(c);
    }

    if (props.withDatepicker) {
      c = withDatepicker(c);
    }

    if (props.withClients) {
      c = withClientorProjects(c);
    }
    return c;
  }, [
    props.withClients,
    props.component,
    props.pageTitle,
    props.withDatepicker,
  ]);

  const renderRoute = useCallback(() => {
    const route = (
      <Route {...props}>
        <PageComponent
          title={props.pageTitle}
          subTitle={props.pageSubTitle}
          documentTitle={props.documentTitle}
        />
      </Route>
    );

    // if is single report page, we manage its auth from within
    if (!props.minRole || !!singleReportPage || !!isAccountPath) {
      return route;
    }

    return tokens && user && hasPermission(props.minRole) ? (
      route
    ) : tokens && user ? (
      <CatchAll>{route}</CatchAll>
    ) : (
      <Loading fullHeight text="..." />
    );
  }, [
    props,
    tokens,
    PageComponent,
    hasPermission,
    user,
    singleReportPage,
    isAccountPath,
  ]);

  return (
    <motion.div
      style={{ height: "100%" }}
      initial={{ opacity: 0 }}
      exit={
        !prevLocation ||
        (!(
          prevLocation.pathname.includes("addlocation") &&
          location.pathname.includes("tagpages")
        ) &&
          !(
            prevLocation.pathname.includes("tagpages") &&
            location.pathname.includes("addlocation")
          ))
          ? {
              opacity: 0,
              x: 200,
              transition: {
                x: {
                  delay: 0.12,
                },
              },
            }
          : { opacity: 0 }
      }
      animate={{ opacity: 1 }}
    >
      {renderRoute()}
    </motion.div>
  );
};

const DashboardRoutes = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  return (
    <DashboardLayout>
      <AnimatePresence mode="wait">
        {/* <FromToProvider> */}
        <AnalyticsProvider>
          <ChartDescriptionDrawerProvider>
            <Switch location={location} key={location.key}>
              {children}
            </Switch>
          </ChartDescriptionDrawerProvider>
        </AnalyticsProvider>
        {/* </FromToProvider> */}
      </AnimatePresence>
    </DashboardLayout>
  );
};

export const Router = () => {
  const loginStatus = useSelector((state: IStoreState) => state.auth.status);
  const dispatch = useDispatch();
  const protectedRoutes = useProtectedRoutes();
  useMount(() => {
    if (loginStatus === "INIT") {
      dispatch(checkLogin());
    }
  });

  return (
    <>
      <BrowserRouter>
        <PageTracker />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/password/forgot-password"
            component={ForgotPassword}
          />
          <Route
            path="/password/reset-password/:code"
            component={ResetPassword}
          />
          <Route path="/verify-email/:code" component={InitialPassword} />
          <Route exact path="/logout" component={Logout} />
          <NRoute
            exact={true}
            path="/reports/:hash"
            withClients
            component={SingleReportPage}
            minRole={ApiACLRole.ANALYTICS_OBSERVER}
          />
          <ProtectedRoute path="/">
            <DashboardRoutes>
              <NRoute
                path="/clients/:clientId/projects/:projectId/analyse/*"
                component={() => (
                  <FromToProvider>
                    <AnalysePage />
                  </FromToProvider>
                )}
              />
              {(protectedRoutes || [])
                .filter((p) => !p.path?.includes("analyse/"))
                .map((pr, i) => (
                  <NRoute
                    key={i}
                    exact={pr.exact}
                    path={pr.path}
                    withClients={pr.withClients}
                    component={pr.component}
                    minRole={pr.minRole}
                    pageTitle={pr.pageTitle}
                    pageSubTitle={pr.pageSubTitle}
                    documentTitle={pr.documentTitle}
                    withDatepicker={pr.withDatepicker}
                    withFromTo={pr.withFromTo}
                  />
                ))}
            </DashboardRoutes>
          </ProtectedRoute>
        </Switch>
      </BrowserRouter>
    </>
  );
};
