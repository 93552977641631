/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiPageStatus;
(function (ApiPageStatus) {
    ApiPageStatus["UNSPECIFIED"] = "PAGE_STATUS_UNSPECIFIED";
    ApiPageStatus["LOCATED"] = "PAGE_STATUS_LOCATED";
    ApiPageStatus["TAGGED"] = "PAGE_STATUS_TAGGED";
    ApiPageStatus["PRODUCTS_CRAWLED"] = "PAGE_STATUS_PRODUCTS_CRAWLED";
    ApiPageStatus["JOBS_CRAWLED"] = "PAGE_STATUS_JOBS_CRAWLED";
    ApiPageStatus["STATUS_CODE"] = "PAGE_STATUS_STATUS_CODE";
})(ApiPageStatus || (ApiPageStatus = {}));
export function ApiPageStatusFromJSON(json) {
    return ApiPageStatusFromJSONTyped(json, false);
}
export function ApiPageStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiPageStatusToJSON(value) {
    return value;
}
