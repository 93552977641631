/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ApiCreatePageViewPayloadFromJSON(json) {
    return ApiCreatePageViewPayloadFromJSONTyped(json, false);
}
export function ApiCreatePageViewPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'page': !exists(json, 'page') ? undefined : json['page'],
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
        'broprintjsKey': !exists(json, 'broprintjsKey') ? undefined : json['broprintjsKey'],
        'fingerprintjsKey': !exists(json, 'fingerprintjsKey') ? undefined : json['fingerprintjsKey'],
        'isPreloaded': !exists(json, 'isPreloaded') ? undefined : json['isPreloaded'],
    };
}
export function ApiCreatePageViewPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'page': value.page,
        'referrer': value.referrer,
        'broprintjsKey': value.broprintjsKey,
        'fingerprintjsKey': value.fingerprintjsKey,
        'isPreloaded': value.isPreloaded,
    };
}
