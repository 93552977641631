import { Grid } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import {
  table5RowsWithComp,
  useSelectedAttributes,
  useSelectedDimension,
  useSelectedMetric,
} from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import Loading from "Components/Loading/Loading";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";

function InsightsBlock() {
  const { selectedDimension } = useSelectedDimension();
  const { selectedMetric } = useSelectedMetric();
  const { selectedAttributes } = useSelectedAttributes();
  const dimensionToName = useDimensionName();
  if (!selectedMetric) {
    return <Loading fullHeight />;
  }
  return (
    <Grid container spacing={AnalyticsSpacing}>
      <Grid item xs={5}>
        <AnalyticsCard
          height={table5RowsWithComp}
          chart={{
            name: `${dimensionToName(selectedDimension)} Breakdown`,
            dimension: [selectedDimension],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: selectedAttributes || [],
            comparison: true,
            yAxisKeys: [
              {
                key: "l",
                fields:
                  selectedMetric === ApiMetric.METRIC_SESSIONS_COUNT
                    ? [ApiMetric.METRIC_SESSIONS_COUNT]
                    : [ApiMetric.METRIC_SESSIONS_COUNT, selectedMetric],
              },
            ],
            displayOptions: {
              noAttributionToggle: true,
              rowsPerPage: 5,
              showTotals: true,
              disabledMetricClick: true,
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <AnalyticsCard
          height={table5RowsWithComp}
          chart={{
            name: `Trending ${dimensionToName(selectedDimension, true)}`,
            dimension: [
              ApiDimension.DIMENSION_SESSION_START_BY_DAY,
              selectedDimension,
            ],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: selectedAttributes || [],
            limit: 5,
            yAxisKeys: [
              {
                key: "l",
                fields: [selectedMetric],
                chart: {
                  [selectedMetric]: ApiYAxisChartType.AREA,
                },
              },
            ],
            displayOptions: {
              noAttributionToggle: true,
              disabledMetricClick: true,
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <AnalyticsCard
          height={table5RowsWithComp}
          chart={{
            name: `Top ${
              selectedDimension === ApiDimension.DIMENSION_DEVICE ? "2" : "5"
            } ${dimensionToName(selectedDimension, true)}`,
            dimension: [selectedDimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.PIE,
            attributes: selectedAttributes || [],
            limit: 5,
            yAxisKeys: [
              {
                key: "l",
                fields: [selectedMetric],
              },
            ],
            displayOptions: {
              noAttributionToggle: true,
              showTotals: true,
              disabledMetricClick: true,
              noFilters: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default InsightsBlock;
