/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiSimpleDimensionLabelFromJSON, ApiSimpleDimensionLabelToJSON, } from './ApiSimpleDimensionLabel';
export function ApiReadDimensionLabelResponseFromJSON(json) {
    return ApiReadDimensionLabelResponseFromJSONTyped(json, false);
}
export function ApiReadDimensionLabelResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': !exists(json, 'label') ? undefined : ApiSimpleDimensionLabelFromJSON(json['label']),
    };
}
export function ApiReadDimensionLabelResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': ApiSimpleDimensionLabelToJSON(value.label),
    };
}
