import {
  ApiChartAttribute,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Stack, capitalize, Box } from "@mui/material";
import { StatCardTypography } from "Components/UI/StatCard";
import { useCallback, useMemo, useState } from "react";
import { formatNumber } from "Helpers/numbers";
import {
  AnalyticsIncreaseDecreaseStatCard,
  AnalyticsStatCard,
  metricByAttribution,
  metricConfigByName,
  percentageTopOfTotal,
} from "features/analytics";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";
import { useEffect } from "react";
import { AnalyticsSpacing } from "consts";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { useMetricName } from "features/analytics/hooks/useMetricName";

interface IAttributionHeaderProps {
  dimension: ApiDimension;
  title: string;
  attributes: ApiChartAttribute[];
}

function AttributionHeader({
  dimension,
  title,
  attributes,
}: IAttributionHeaderProps) {
  const metricToName = useMetricName();
  const [metric, setMetric] = useState<ApiMetric>(
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT
  );
  const [attributionMetric, setAttributionMetric] = useState<ApiMetric>(
    ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT
  );
  const [models, setModels] = useState([AttributionType.FIRST]);
  const availableMetrics = useMemo(() => {
    switch (models[0]) {
      case undefined:
      case AttributionType.FIRST:
        return [
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV,
        ];
      case AttributionType.LAST:
        return [
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROILTV,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV,
        ];
      case AttributionType.LAST_NON:
        return [
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV,
        ];
      case AttributionType.LINEAR:
        return [
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROILTV,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROASLTV,
        ];
      case AttributionType.POSITION:
        return [
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV,
        ];
      case AttributionType.IMPACTED:
        return [
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS,
          ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV,
        ];

      default:
        return [];
    }
  }, [models]);

  const onModelChange = useCallback((models: AttributionType[]) => {
    setModels(models);
  }, []);

  useEffect(() => {
    if (models[0] === undefined) {
      setAttributionMetric(metric);
      return;
    }
    const map = metricConfigByName(metric);
    const attMetric = metricByAttribution(
      models[0],
      map?.attribtionMetric as AttributionMetric
    );
    if (attMetric) {
      setAttributionMetric(attMetric);
    }
  }, [models, metric]);

  return (
    <Box
      sx={{
        display: "grid",
        columnGap: AnalyticsSpacing,
        gridTemplateColumns: `3fr 9fr`,
        // height: "100%",
      }}
    >
      <Stack spacing={AnalyticsSpacing}>
        <AnalyticsStatCard
          minHeight={131}
          chart={{
            name: "",
            dimension: [dimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [attributionMetric],
              },
            ],
          }}
          renderBody={(data) => (
            <>
              <StatCardTypography size="xs" opactity={0.8} mb={1}>
                Highest Performing <br />
                {title} by {metricToName(attributionMetric)}:
              </StatCardTypography>
              <StatCardTypography size="large" lines={1}>
                {data[0]?.name}
              </StatCardTypography>
            </>
          )}
        />
        <AnalyticsStatCard
          chart={{
            name: "",
            dimension: [dimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [attributionMetric],
              },
            ],
          }}
          renderBody={(data) => (
            <>
              <StatCardTypography size="xs" opactity={0.8}>
                Generating
              </StatCardTypography>
              <StatCardTypography size="large" mb={1}>
                {formatNumber(percentageTopOfTotal(data, attributionMetric))}%
              </StatCardTypography>
              <StatCardTypography size="xs" opactity={0.8}>
                of Total {metricToName(attributionMetric)}:
              </StatCardTypography>
            </>
          )}
        />
        <AnalyticsIncreaseDecreaseStatCard
          metric={attributionMetric}
          chart={{
            name: "",
            dimension: [dimension],
            template: ApiChartTemplate.SNAPSHOT,
            type: ApiChartType.GRAPH,
            attributes,
            yAxisKeys: [
              {
                key: "l",
                fields: [attributionMetric as ApiMetric],
              },
            ],
          }}
        />
      </Stack>

      <AnalyticsCard
        onChangeMetric={(m) => setMetric(m)}
        onModelsChange={onModelChange}
        chart={{
          name: `Top 5 ${capitalize(title)} by`,
          dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY, dimension],
          template: ApiChartTemplate.TREND,
          type: ApiChartType.GRAPH,
          attributes,
          limit: 5,
          yAxisKeys: [
            {
              key: "l",
              fields: [attributionMetric],
              stackId: "l",
              chart: {
                [attributionMetric]: ApiYAxisChartType.BAR,
              },
            },
          ],
          displayOptions: {
            noFilters: true,
            availableMetrics: [
              ...availableMetrics,
              ApiMetric.METRIC_SESSIONS_COUNT,
              ApiMetric.METRIC_PAGE_VIEWS_COUNT,
              ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT,
              ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
            ],
          },
        }}
      />
    </Box>
  );
}

export default AttributionHeader;
