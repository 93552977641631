/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiListSourceCategoriesResponseFromJSON, ApiReadSourceCategoryResponseFromJSON, ApiSimpleSourceCategoryToJSON, } from '../models';
/**
 *
 */
export class SourceCategoryServiceApi extends runtime.BaseAPI {
    /**
     */
    sourceCategoryServiceCreateSourceCategoryRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sourceCategoryServiceCreateSourceCategory.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling sourceCategoryServiceCreateSourceCategory.');
            }
            const queryParameters = {};
            if (requestParameters.categoryId !== undefined) {
                queryParameters['categoryId'] = requestParameters.categoryId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/category/v1/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleSourceCategoryToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadSourceCategoryResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    sourceCategoryServiceCreateSourceCategory(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sourceCategoryServiceCreateSourceCategoryRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sourceCategoryServiceDeleteSourceCategoryRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sourceCategoryServiceDeleteSourceCategory.');
            }
            if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
                throw new runtime.RequiredError('categoryId', 'Required parameter requestParameters.categoryId was null or undefined when calling sourceCategoryServiceDeleteSourceCategory.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/category/v1/projects/{projectId}/category/{categoryId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    sourceCategoryServiceDeleteSourceCategory(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sourceCategoryServiceDeleteSourceCategoryRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Source Categories ----------------
     */
    sourceCategoryServiceListSourceCategoriesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sourceCategoryServiceListSourceCategories.');
            }
            const queryParameters = {};
            if (requestParameters.categoryId !== undefined) {
                queryParameters['categoryId'] = requestParameters.categoryId;
            }
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/category/v1/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListSourceCategoriesResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Source Categories ----------------
     */
    sourceCategoryServiceListSourceCategories(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sourceCategoryServiceListSourceCategoriesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sourceCategoryServiceReadSourceCategoryRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sourceCategoryServiceReadSourceCategory.');
            }
            if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
                throw new runtime.RequiredError('categoryId', 'Required parameter requestParameters.categoryId was null or undefined when calling sourceCategoryServiceReadSourceCategory.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/category/v1/projects/{projectId}/category/{categoryId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadSourceCategoryResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    sourceCategoryServiceReadSourceCategory(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sourceCategoryServiceReadSourceCategoryRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    sourceCategoryServiceUpdateSourceCategoryRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling sourceCategoryServiceUpdateSourceCategory.');
            }
            if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
                throw new runtime.RequiredError('categoryId', 'Required parameter requestParameters.categoryId was null or undefined when calling sourceCategoryServiceUpdateSourceCategory.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling sourceCategoryServiceUpdateSourceCategory.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/category/v1/projects/{projectId}/category/{categoryId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleSourceCategoryToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadSourceCategoryResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    sourceCategoryServiceUpdateSourceCategory(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sourceCategoryServiceUpdateSourceCategoryRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
