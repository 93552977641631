import { ApiSimpleSubscription } from "@incendium/api";
import { listSubscriptions } from "features/subscription";
import { useSharedState } from "Hooks/useSharedState";

const fetchSubscriptions = async () => {
  const subscriptions = await listSubscriptions();
  return subscriptions || [];
};
const useSubscriptions = () => {
  const { state, setState, loading, refetch } = useSharedState<
    ApiSimpleSubscription,
    ApiSimpleSubscription[]
  >("subscriptions", [], fetchSubscriptions, {
    isGlobal: true,
  });

  return {
    subscriptions: state,
    setSubscriptions: setState,
    loading,
    refetch,
  };
};
export default useSubscriptions;
