import {
  ApiAdditionalMetricsList,
  ApiChartAttribute,
  ApiMetric,
} from "@incendium/api";
import { Box, Stack, styled, Typography } from "@mui/material";
import { FunnelDatum } from "@nivo/funnel";
import IncreaseDecrease from "../components/IncreaseDecrease";
import { ClickableTypography } from "Components/UI/ClickableTypography";
import StyledFunnel from "Components/UI/StyledFunnel";
import {
  getUniqueMetrics,
  percentageTopChangeByMetric,
  useFormatMetric,
} from "features/analytics";
import useMetricExplorerNavigation from "features/analytics/hooks/useMetricExplorerNavigation";
import { motion } from "framer-motion";
import { TChartData } from "Interfaces";
import { useCallback, useMemo, useState } from "react";
import { useMetricName } from "features/analytics/hooks/useMetricName";

const StyledMetricCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme, active }) => ({
  padding: theme.spacing(1),
  position: "absolute",
  background: active ? "white" : "transparent",
  zIndex: 1,
  width: "calc(100% - 16px)",
  border: active ? `2px solid ${theme.palette.primary.main}` : undefined,
  borderRadius: 6,
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  transition: "background 0.3s",
  willChange: ["height", "background"],
  top: theme.spacing(1),
  "&:hover": {
    border: `2px solid ${theme.palette.primary.main}`,
    background: "white",
    transition: "all 0.1s",
    color: theme.palette.primary.main,
  },
}));

interface IAnalyticsFunnelProps {
  chartData: TChartData[];
  comparisonChartData?: TChartData[];
  useLogFn?: boolean;
  additionalMetrics?: ApiAdditionalMetricsList[];
  chartAttributes?: ApiChartAttribute[];
}

interface TData extends FunnelDatum {
  comparison?: number;
  realValue: number;
}

function AnalyticsFunnel({
  chartData,
  comparisonChartData,
  useLogFn,
  additionalMetrics,
  chartAttributes,
}: IAnalyticsFunnelProps) {
  const [activeMetric, setActiveMetric] = useState<ApiMetric | null>(null);
  const formatMetric = useFormatMetric();
  const onMetricClick = useMetricExplorerNavigation();
  const metricToName = useMetricName();

  const metrics = useMemo(() => {
    // check non of these metrics are part of additional
    const arr = getUniqueMetrics(chartData);
    if (additionalMetrics) {
      const additional = (additionalMetrics || [])
        .map((ad) => ad.metrics)
        .flat();
      return arr.filter((a) => !additional.includes(a as ApiMetric));
    }

    return arr;
  }, [chartData, additionalMetrics]);

  const data = useMemo(() => {
    return metrics.map((k) => {
      const o: TData = {
        id: k,
        label: metricToName(k as ApiMetric),
        value: useLogFn
          ? Math.log10(Number(chartData[0][k]))
          : Number(chartData[0][k]),
        realValue: Number(chartData[0][k]),
      };
      const foundKey = Object.keys(
        comparisonChartData ? comparisonChartData[0] : []
      ).findIndex((ck) => ck === k);
      if (foundKey) {
        const change = comparisonChartData
          ? percentageTopChangeByMetric(
              chartData,
              comparisonChartData!,
              k as ApiMetric
            )
          : undefined;
        o.comparison = change;
      }
      return o;
    });
  }, [chartData, comparisonChartData, metrics, useLogFn]);

  const onFunnelPartClick = useCallback(
    (part, show: boolean) => {
      const metric = part.data.id as ApiMetric;
      if (show) {
        setActiveMetric(metric);
      } else {
        setActiveMetric(null);
      }
    },
    [setActiveMetric]
  );

  const additionalData = useMemo(() => {
    if (!additionalMetrics || !activeMetric) {
      return [];
    }
    const found = additionalMetrics.find((am) => am.key === activeMetric);
    if (!found) {
      return [];
    }
    return Object.keys(chartData[0])
      .filter((d) => found.metrics?.includes(d as ApiMetric))
      .map((d) => ({
        metric: d,
        value: Number(chartData[0][d]),
      }));
  }, [chartData, additionalMetrics, activeMetric]);

  return (
    <Stack sx={{ height: "100%", position: "relative", maxHeight: 600 }}>
      <Stack
        component={motion.div}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.2,
          scale: {
            type: "spring",
            bounce: 0.5,
            damping: 9,
            restDelta: 0.001,
          },
        }}
        direction={"row"}
        sx={{
          position: "absolute",
          width: "100%",
          top: 0,
        }}
      >
        {data.map((d) => (
          <Box
            key={d.id}
            sx={{ flex: 1, position: "relative", cursor: "pointer" }}
            p={1}
            onMouseEnter={() => setActiveMetric(d.id as ApiMetric)}
            onMouseLeave={() => setActiveMetric(null)}
          >
            <StyledMetricCard active={activeMetric === d.id}>
              <ClickableTypography
                variant="body1"
                color={"inherit"}
                fontWeight={activeMetric === d.id ? 600 : "initial"}
                onClick={() =>
                  onMetricClick(d.id as ApiMetric, chartAttributes)
                }
              >
                {d.label}
              </ClickableTypography>
              <Stack direction={"row"} alignItems="baseline" spacing={1}>
                <Typography fontSize={28} variant="subtitle1">
                  {formatMetric(d.id as ApiMetric, d.realValue)}
                </Typography>
                {d.comparison && (
                  <IncreaseDecrease
                    direction={d.comparison >= 0 ? "up" : "down"}
                    value={d.comparison}
                    fontSize={16}
                    useThemeColours
                  />
                )}
              </Stack>
              {activeMetric === d.id && (
                <Stack alignItems={"flex-start"}>
                  {additionalData.map((ad) => (
                    <ClickableTypography
                      key={ad.metric}
                      onClick={() =>
                        onMetricClick(ad.metric as ApiMetric, chartAttributes)
                      }
                    >
                      {metricToName(ad.metric as ApiMetric)}:{" "}
                      {formatMetric(ad.metric as ApiMetric, ad.value)}
                    </ClickableTypography>
                  ))}
                </Stack>
              )}
            </StyledMetricCard>
          </Box>
        ))}
      </Stack>
      <StyledFunnel
        data={data}
        onHover={additionalMetrics ? onFunnelPartClick : undefined}
      />
    </Stack>
  );
}

export default AnalyticsFunnel;
