import {
  ApiConversion,
  ApiOperator,
  ApiConditionValueType,
  ApiConditionCustom,
  ApiCondition,
  ApiConditionGroup,
} from "@incendium/api";
import { Box, Grid, TextField, MenuItem } from "@mui/material";
import ConditionalGroup from "Components/ConditionalGroup/ConditionalGroup";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import { useEffect, useMemo, useState } from "react";
import produce from "immer";
import { exhaustiveCheck } from "@incendium/inc-ts-helpers";
import { useTheme } from "@mui/styles";

type TAlertType = {
  key: string;
  label: string;
};

const RowOperators = (operator: ApiOperator) => {
  switch (operator) {
    case ApiOperator.IS_LESS_THAN:
      return "decreased by over";
    case ApiOperator.IS_GREATER_THAN:
      return "increased by over";
    case ApiOperator.CONTAINS:
    case ApiOperator.EQUAL:
    case ApiOperator.NOT_EQUAL:
    case ApiOperator.NOT_IN:
    case ApiOperator.IS_IN:
    case ApiOperator.STARTS_WITH:
    case ApiOperator.OPERATOR_UNASSIGNED:
    case ApiOperator.IS_LESS_THAN_OR_EQUAL:
    case ApiOperator.IS_GREATER_THAN_OR_EQUAL:
    case ApiOperator.ONE_OF:
    case ApiOperator.NOT_CONTAINS:
    case ApiOperator.REGEX:
      return "";
    default:
      exhaustiveCheck(operator);
  }
};
const RowConditionValueType = (type: ApiConditionValueType) => {
  switch (type) {
    case ApiConditionValueType.PERCENTAGE_DIFFRENCE:
      return "%";
    case ApiConditionValueType.NUMBER_DIFFRENCE:
      return "#";
    case ApiConditionValueType.UNSPECIFIED:
      return "";
    default:
      exhaustiveCheck(type);
  }
};

const Row = ({
  conversions,
  groupIdx,
  rowIdx,
}: {
  conversions: ApiConversion[];
  groupIdx: number;
  rowIdx: number;
}) => {
  const theme = useTheme();

  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const [types] = useState<TAlertType[]>([
    {
      key: "conversion",
      label: "conversion rate",
    },
    {
      key: ApiConditionCustom.AVERGAGE_SESSIONS_COUNT,
      label: "average number of sessions",
    },
  ]);
  const [operators] = useState<ApiOperator[]>([
    ApiOperator.IS_LESS_THAN,
    ApiOperator.IS_GREATER_THAN,
  ]);
  const [units] = useState<ApiConditionValueType[]>([
    ApiConditionValueType.PERCENTAGE_DIFFRENCE,
    ApiConditionValueType.NUMBER_DIFFRENCE,
  ]);
  const condition: ApiCondition = useMemo(() => {
    return (
      ((conditionGroups as ApiConditionGroup[])[groupIdx]?.conditions || [])[
        rowIdx
      ] || {}
    );
  }, [conditionGroups, groupIdx, rowIdx]);

  const [type, setType] = useState(
    condition.custom && condition.custom !== ApiConditionCustom.UNSPECIFIED
      ? condition.custom
      : condition.conversionId
      ? "conversion"
      : ""
  );

  const conversionId = useMemo(() => {
    return condition?.conversionId || "";
  }, [condition]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={8} container>
        <Grid
          item
          xs={7}
          container
          spacing={1}
          sx={{
            paddingRight: theme.spacing(3),
          }}
        >
          <Grid item xs={type === "conversion" ? 3 : 12}>
            <TextField
              label="type"
              fullWidth
              select
              value={type || ""}
              onChange={(e) => {
                setConditionGroups(
                  produce(conditionGroups, (draft: ApiConditionGroup[]) => {
                    (draft[groupIdx]?.conditions || [])[rowIdx].custom =
                      e.target.value === "conversion"
                        ? ApiConditionCustom.UNSPECIFIED
                        : (e.target.value as ApiConditionCustom);
                  })
                );
                setType(e.target.value);
              }}
            >
              {types.map((t) => (
                <MenuItem key={t.key} value={t.key}>
                  {t.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {type === "conversion" && (
            <Grid item xs={9}>
              <TextField
                select
                variant="outlined"
                fullWidth
                label="conversion"
                value={conversionId}
                onChange={(e) => {
                  setConditionGroups(
                    produce(conditionGroups, (draft: ApiConditionGroup[]) => {
                      (draft[groupIdx]?.conditions || [])[rowIdx].conversionId =
                        Number(e.target.value);
                    })
                  );
                }}
              >
                {conversions.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Grid item xs={5}>
          <TextField
            select
            variant="outlined"
            fullWidth
            label="nature of change"
            value={condition.operator || ""}
            onChange={(e) => {
              setConditionGroups(
                produce(conditionGroups, (draft: ApiConditionGroup[]) => {
                  (draft[groupIdx]?.conditions || [])[rowIdx].operator = e
                    .target.value as ApiOperator;
                })
              );
            }}
          >
            {operators.map((o) => (
              <MenuItem key={o} value={o}>
                {RowOperators(o)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <TextField
          label="amount"
          fullWidth
          type={"number"}
          value={condition.value || ""}
          onChange={(e) => {
            setConditionGroups(
              produce(conditionGroups, (draft: ApiConditionGroup[]) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].value = e.target
                  .value as ApiOperator;
              })
            );
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          select
          variant="outlined"
          fullWidth
          label="units"
          value={condition.valueType || ""}
          onChange={(e) => {
            setConditionGroups(
              produce(conditionGroups as ApiConditionGroup[], (draft) => {
                (draft[groupIdx]?.conditions || [])[rowIdx].valueType = e.target
                  .value as ApiConditionValueType;
              })
            );
          }}
        >
          {units.map((o) => (
            <MenuItem key={o} value={o}>
              {RowConditionValueType(o)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

function AlertRules({ conversions }: { conversions: ApiConversion[] }) {
  const { setRowComponent } = useConditionGroupContext();

  useEffect(() => {
    setRowComponent(() => (groupIdx, rowIdx) => {
      return (
        <Row groupIdx={groupIdx} rowIdx={rowIdx} conversions={conversions} />
      );
    });
  }, [conversions]);

  return (
    <Box>
      <ConditionalGroup />
    </Box>
  );
}

export default AlertRules;
