import { Box, Switch, Typography } from "@mui/material";
import AnalyticsCard from "features/analytics/components/AnalyticsCard";
import { StatCardRound, StatCardRoundTypography } from "Components/UI/StatCard";
import { IChart, TChartData } from "Interfaces";
import {
  formatPercentage,
  percentageChange,
  percentageOf,
} from "Helpers/percentage";
import { useCallback, useMemo, useState } from "react";

import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { useLocalstorageState } from "Hooks/useLocalstorageState";

interface IProductTopCategoryBrandCompProps {
  showCategory: boolean;
  showBrand: boolean;
}

function ProductTopCategoryBrandComp({
  showCategory,
  showBrand,
}: IProductTopCategoryBrandCompProps) {
  const [mode, setMode] = useLocalstorageState<"category" | "brand">(
    "ProductTopCategoryBrandComp-mode",
    showCategory ? "category" : "brand"
  );
  const [currentChartData, setCurrentChartData] = useState<TChartData[] | null>(
    null
  );
  const [compChartData, setCompChartData] = useState<TChartData[] | null>(null);

  const chart: IChart = useMemo(
    () => ({
      name: `Top Product ${
        mode === "category" ? "Categories" : "Brands"
      } Comparison`,
      description: `See which product ${
        mode === "category" ? "categories" : "brands"
      } performed best`,
      dimension: [
        mode === "category"
          ? ApiDimension.DIMENSION_PRODUCT_CATEGORY
          : ApiDimension.DIMENSION_PRODUCT_BRAND,
      ],
      yAxisKeys: [
        {
          key: "l",
          fields: [ApiMetric.METRIC_PRODUCT_REVENUE],
          chart: {
            [ApiMetric.METRIC_PRODUCT_REVENUE]: ApiYAxisChartType.BAR,
          },
        },
      ],
      attributes: [],
      template: ApiChartTemplate.SNAPSHOT,
      type: ApiChartType.GRAPH,
    }),
    [mode]
  );

  const onDataChange = useCallback((a, b) => {
    setCurrentChartData(a);
    setCompChartData(b);
  }, []);

  const chartData: TChartData[] = useMemo(() => {
    if (!currentChartData) {
      return [];
    }

    return (currentChartData || []).map((d) => {
      const o: TChartData = {
        name: d.name || "",
        current: d[ApiMetric.METRIC_PRODUCT_REVENUE],
      };

      const f = (compChartData || []).find((v) => v.name === d.name);
      if (f) {
        o.previous = f[ApiMetric.METRIC_PRODUCT_REVENUE];
      }
      return o;
    });
  }, [currentChartData, compChartData]);

  const topRevenueCurrent = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.current as number) || 0) - ((a.current as number) || 0)
      )[0],
    [chartData]
  );
  const topRevenuePrevious = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.previous as number) || 0) - ((a.previous as number) || 0)
      )[0],
    [chartData]
  );

  const topShareOfRevenue = useMemo(() => {
    const total = chartData.reduce((agg, v) => agg + (v.current as number), 0);
    return percentageOf((topRevenueCurrent?.current as number) || 0, total);
  }, [topRevenueCurrent, chartData]);

  const biggestDifference = useMemo(() => {
    return chartData
      .filter((v) => v.current || v.previous)
      .map((v) => {
        const diff = percentageChange(
          (v.previous as number) || 0,
          (v.current as number) || 0
        );
        return {
          ...v,
          diff,
        };
      })
      .sort((a, b) => {
        return b.diff - a.diff;
      })[0];
  }, [chartData]);

  return (
    <AnalyticsCard
      chart={chart}
      onDataChange={onDataChange}
      customRightTop={
        showCategory && showBrand ? (
          <StyledFlexBox compact sx={{ maxWidth: 300 }}>
            <Box display="flex" alignItems="center">
              <Typography>Categories</Typography>
              <Switch
                checked={mode === "brand"}
                onChange={(e, checked) =>
                  setMode(checked ? "brand" : "category")
                }
              />
              <Typography>Brands</Typography>
            </Box>
          </StyledFlexBox>
        ) : undefined
      }
      customFullRight={
        <Box
          ml={5}
          sx={{
            minWidth: 330,
            height: 390,
            position: "relative",
          }}
        >
          <StatCardRound
            size={217}
            sx={{ position: "absolute", top: 5, left: -20 }}
          >
            <StatCardRoundTypography size="small">
              Looks like
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={2}>
              {topRevenueCurrent?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small" lines={3}>
              {topRevenueCurrent?.name === topRevenuePrevious?.name ? (
                <>performed best</>
              ) : (
                <>
                  overtook <br /> {topRevenuePrevious?.name}
                </>
              )}
              <br /> in this period
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={179}
            backgroundColor={2}
            sx={{ position: "absolute", bottom: 0, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              Revenue from
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={1}>
              {biggestDifference?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              {biggestDifference?.diff >= 0 ? "increased" : "decreased"} by
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(Math.abs(biggestDifference?.diff))}
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={155}
            backgroundColor={1}
            sx={{ position: "absolute", top: 217 - 155 + 5, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              generating
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(topShareOfRevenue)}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              of the total revenue
            </StatCardRoundTypography>
          </StatCardRound>
        </Box>
      }
    />
  );
}

export default ProductTopCategoryBrandComp;
