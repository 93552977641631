import { ApiDimension } from "@incendium/api";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Autocomplete, IconButton, Stack, TextField } from "@mui/material";
import { IChart } from "Interfaces";
import { NOT_SET, useFilterableDimensions } from "features/analytics";
import produce from "immer";

import { useCallback, useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";
import { uniqueArray } from "Helpers/arrays";

interface IAnalyticsFilterDropdownProps {
  dimension: ApiDimension;
  setChart?: React.Dispatch<React.SetStateAction<IChart>>;
  defaultFirstFilter?: { [key in ApiDimension]?: string };
  isReady?: () => void;
  onChange?: (e: string) => void;
  defaultIndex?: number | ((a: string[]) => number);
  maxWidth?: number;
  disableBlank?: boolean;
}

function AnalyticsFilterDropdown({
  dimension,
  setChart,
  defaultFirstFilter,
  isReady,
  onChange,
  defaultIndex,
  maxWidth,
  disableBlank,
}: IAnalyticsFilterDropdownProps) {
  const dimensionToName = useDimensionName();
  const { getDimensionValues, filterableDimensions, loading } =
    useFilterableDimensions();
  const [selectedValue, setSelectedValue] = useState("");
  const [index, setIndex] = useState(
    typeof defaultIndex === "number" ? defaultIndex : -1
  );
  const [defaultSet, setDefaultSet] = useState(false);
  const [availableOptions, setAvailableOptions] = useState<string[]>([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const fetchOptions = useCallback(
    async (dimension) => {
      setLoadingOptions(true);
      try {
        const dimensionValues = await getDimensionValues(dimension);
        const filteredOptions = (dimensionValues || []).filter(
          (f) => f !== NOT_SET
        );

        setAvailableOptions(uniqueArray(filteredOptions));
      } catch (error) {
        console.error("Error fetching dimension values:", error);
        setAvailableOptions([]);
      } finally {
        setLoadingOptions(false);
      }
    },
    [getDimensionValues]
  );

  useEffect(() => {
    if (!dimension || !filterableDimensions[dimension]) {
      return;
    }
    if (filterableDimensions[dimension].status === "FETCHED") {
      setAvailableOptions(
        (filterableDimensions[dimension].names || []).filter(
          (f) => f !== NOT_SET
        )
      );

      return;
    }

    fetchOptions(dimension);
  }, [dimension, fetchOptions, filterableDimensions]);

  useUpdateEffect(() => {
    if (
      typeof defaultIndex === "function" &&
      defaultIndex(availableOptions) !== index
    ) {
      setIndex(defaultIndex(availableOptions));
    }
  }, [defaultIndex]);

  useEffect(() => {
    if (loading || loadingOptions || index >= 0 || defaultSet) {
      return;
    }
    if (defaultFirstFilter && defaultFirstFilter[dimension]) {
      setIndex(
        availableOptions.findIndex((a) => a === defaultFirstFilter[dimension])
      );
    } else if (typeof defaultIndex === "function") {
      setIndex(defaultIndex(availableOptions));
    }
    setDefaultSet(true);
    isReady && isReady();
  }, [
    loading,
    loadingOptions,
    availableOptions,
    defaultFirstFilter,
    defaultIndex,
    dimension,
    index,
    isReady,
    defaultSet,
  ]);

  useUpdateEffect(() => {
    if (!onChange && !setChart) {
      return;
    }
    if (onChange) {
      onChange(selectedValue);
      return;
    }
    if (setChart) {
      setChart((chart) =>
        produce(chart, (draft) => {
          const idx = (draft.attributes || []).findIndex(
            (f) => f.key === dimension
          );
          if (idx >= 0) {
            if (!selectedValue) {
              draft.attributes.splice(idx, 1);
              return;
            }

            draft.attributes[idx] = {
              ...draft.attributes[idx],
              value: selectedValue,
            };
          } else {
            draft.attributes.push({
              key: dimension,
              value: selectedValue,
            });
          }
        })
      );
    }
  }, [selectedValue]);

  const onCycle = useCallback(
    (direction: "left" | "right") => {
      setIndex((prev) => {
        const newIndex = direction === "right" ? prev + 1 : prev - 1;
        if (newIndex > availableOptions.length - 1) {
          return 0;
        }
        if (newIndex < 0) {
          return availableOptions.length - 1;
        }
        return newIndex;
      });
    },
    [availableOptions]
  );

  useEffect(() => {
    const value = availableOptions[index];
    setSelectedValue(value !== "All Users" ? value : "");
  }, [index, availableOptions]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        maxWidth: maxWidth || "100%",
        width: maxWidth || 264,
        minWidth: 160,
      }}
    >
      <IconButton
        size="small"
        onClick={() => onCycle("left")}
        color="secondary"
        disabled={loading}
      >
        <ChevronLeft />
      </IconButton>
      <Autocomplete
        id="att-value"
        loading={loading || loadingOptions}
        disabled={loading}
        onChange={(e, v) => {
          const index = availableOptions.findIndex((a) => a === v);
          setIndex(index);
        }}
        value={
          loadingOptions ? "" : selectedValue ? selectedValue : "All Users"
        }
        filterSelectedOptions
        disableClearable={disableBlank}
        sx={{ flex: 1 }}
        fullWidth
        options={
          disableBlank ? availableOptions : ["All Users", ...availableOptions]
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Select ${dimensionToName(dimension)}`}
            variant="outlined"
            size="small"
          />
        )}
      />
      <IconButton
        size="small"
        onClick={() => onCycle("right")}
        color="secondary"
        disabled={loading}
      >
        <ChevronRight />
      </IconButton>
    </Stack>
  );
}

export default AnalyticsFilterDropdown;
