import {
  ApiDimension,
  ApiLocationPageTagger,
  ApiOperator,
  ApiProject,
} from "@incendium/api";
import { Add, Delete, Reorder } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { usePageTaggers } from "Hooks/useLocationPageTaggers";
import produce from "immer";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { AccordianChartBuilderSidebarBlock } from "./ChartBuilderSidebarBlock";
import {
  isPairingDecorator,
  NOT_SET,
  pairingDecorator,
  useFilterableDimensions,
  useNonTrendDimensions,
} from "features/analytics";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { useDebounce } from "react-use";
import Loading from "Components/Loading/Loading";
import ChartBuilderDimension from "features/chartLibrary/components/ChartBuilderDimension";
import { useDimensionName } from "features/analytics/hooks/useDimensionName";
import { uniqueArray } from "Helpers/arrays";

interface IChartBuilderXAxisProps {
  dimensions: ApiDimension[];
  title?: string;
  subTitle?: string;
  defaultClosed?: boolean;
  isMultiple?: boolean;
  project: ApiProject;
  noDrag?: boolean;
}

interface IChartBuilderXAxisRowProps {
  dimensions: ApiDimension[];
  pageTaggers: ApiLocationPageTagger[];
  dimension: string;
}

const DimensionAccordian = ({
  dimension,
  isChecked,
  onCheck,
  getDimensionValues,
  loading,
}: {
  dimension: ApiDimension;
  isChecked: (attr: string, dimension: ApiDimension) => boolean;
  onCheck: (attr: string, dimension: ApiDimension) => void;
  getDimensionValues: (
    dimension: ApiDimension
  ) => Promise<string[] | undefined>;
  loading: boolean;
}) => {
  const [options, setOptions] = useState<string[]>([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const dimensionValues = await getDimensionValues(dimension);
        const filteredOptions = (dimensionValues || []).filter(
          (d) => d !== NOT_SET
        );
        setOptions(uniqueArray(filteredOptions));
      } catch (error) {
        console.error("Error fetching dimension values:", error);
        setOptions([]);
      } finally {
        setLoadingOptions(false);
      }
    };

    fetchOptions();
  }, [dimension, getDimensionValues]);

  return (
    <Accordion
      disableGutters
      sx={{
        background: "transparent",
        padding: 0,
        border: 0,
        "&:before": {
          backgroundColor: "transparent",
        },
      }}
      elevation={0}
    >
      <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
        <Typography variant="caption">Which Conversions to include?</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading || loadingOptions ? (
          <Loading />
        ) : (
          options.map((c) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked(c, dimension)}
                  onChange={() => onCheck(c, dimension)}
                />
              }
              label={c}
              value={c}
            />
          ))
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const ChartBuilderXAxisRow = ({
  dimensions,
  pageTaggers,
  dimension,
}: IChartBuilderXAxisRowProps) => {
  const dimensionToName = useDimensionName();
  const { chart, setChart } = useReportBuilderContext();
  const decoractorDimension = useMemo(() => {
    return pairingDecorator(dimension);
  }, [dimension]);

  useEffect(() => {
    // check if we should still have decorators
    setChart((chart) =>
      produce(chart, (draft) => {
        draft.attributes = (draft.attributes || []).filter((d) => {
          return (
            !isPairingDecorator(d.key as ApiDimension) ||
            (draft.dimension || [])
              .map(pairingDecorator)
              .includes((d.key as ApiDimension) || "")
          );
        });
      })
    );
  }, [chart.dimension, setChart]);

  return (
    <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
      <Stack sx={{ width: "100%" }} spacing={1}>
        <ChartBuilderDimension
          selectedDimension={dimension as ApiDimension}
          chart={chart}
          setChart={setChart}
        />
        {dimension === ApiDimension.DIMENSION_DOMAIN && (
          <Alert severity="info">Sets include subdomains as default</Alert>
        )}
      </Stack>

      {decoractorDimension && (
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            size="small"
            options={pageTaggers.map((pt) => ({
              key: "",
              operator: ApiOperator.EQUAL as string,
              value: pt.name,
            }))}
            getOptionLabel={(o) => o.value || ""}
            value={(chart.attributes || []).find(
              (dd) => dd.key === decoractorDimension
            )}
            isOptionEqualToValue={(option) => {
              return (
                option.value ===
                (chart.attributes || []).find(
                  (dd) => dd.key === decoractorDimension
                )?.value
              );
            }}
            onChange={(e, v) => {
              setChart((chart) =>
                produce(chart, (draft) => {
                  const idx = (chart.attributes || []).findIndex(
                    (dd) => dd.key === decoractorDimension
                  );

                  if (idx >= 0) {
                    draft.attributes.splice(idx, 1);
                  } else {
                    draft.attributes.push({
                      key: decoractorDimension,
                      operator: v?.operator,
                      value: v?.value,
                    });
                  }
                })
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dimensionToName(decoractorDimension)}
                variant="outlined"
              />
            )}
          />
        </Box>
      )}
    </Stack>
  );
};

function ChartBuilderXAxis({
  dimensions,
  title,
  subTitle,
  defaultClosed,
  isMultiple,
  project,
  noDrag,
}: IChartBuilderXAxisProps) {
  const { chart, setChart } = useReportBuilderContext();
  const { loading, getDimensionValues } = useFilterableDimensions();
  const { pageTaggers } = usePageTaggers();
  const nonTrendDimensions = useNonTrendDimensions(chart);
  useDebounce(
    () => {
      if (nonTrendDimensions.length === 0) {
        setChart(
          produce(chart, (draft) => {
            draft.dimension = [""];
          })
        );
      }
    },
    100,
    [chart, setChart, nonTrendDimensions]
  );

  // todo: make not only for conversions
  const isChecked = useCallback(
    (attr: string, dimension: ApiDimension) => {
      const conversionAttrs = chart.attributes.filter(
        (a) => a.key === dimension
      );
      if (!conversionAttrs || conversionAttrs.length === 0) {
        return true;
      }
      return [...conversionAttrs.map((a) => a.value)].includes(attr);
    },
    [chart.attributes]
  );

  const onCheck = (attr: string, dimension: ApiDimension) => {
    setChart(
      produce(chart, (draft) => {
        const idx = draft.attributes.findIndex(
          (a) => a.key === dimension && a.value === attr
        );
        if (idx >= 0) {
          draft.attributes.splice(idx, 1);
        } else {
          draft.attributes.push({
            key: dimension,
            operator: ApiOperator.EQUAL,
            value: attr,
          });
        }
      })
    );
  };

  return (
    <AccordianChartBuilderSidebarBlock
      title={title || `Horizontal (X) Axis`}
      subTitle={
        subTitle ||
        "Select Dimension to display on horizontal axis, e.g channel etc"
      }
      defaultClosed={defaultClosed}
    >
      <Droppable droppableId={`xAxis`}>
        {(prov) => (
          <Box ref={prov.innerRef} {...prov.droppableProps}>
            {nonTrendDimensions.map((d, i) => (
              <Draggable
                draggableId={`xAxis-${i}`}
                index={i}
                key={i}
                isDragDisabled={noDrag}
              >
                {(provided, snapshot) => {
                  const children = (
                    <Stack
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      mb={nonTrendDimensions.length > 1 ? 1.5 : 0}
                      key={`${i}-in`}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        {!noDrag && (
                          <Box {...provided.dragHandleProps} mr={1} mt={0.5}>
                            <Reorder color="secondary" />
                          </Box>
                        )}
                        {isMultiple && (
                          <Box mr={2}>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setChart(
                                  produce(chart, (draft) => {
                                    if (!draft.dimension) {
                                      return;
                                    }
                                    draft.dimension.splice(i, 1);
                                  })
                                );
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Box>
                        )}
                        <ChartBuilderXAxisRow
                          dimensions={dimensions}
                          pageTaggers={pageTaggers}
                          dimension={d}
                        />
                      </Box>

                      {[
                        ApiDimension.DIMENSION_MACRO_CONVERSION,
                        ApiDimension.DIMENSION_MICRO_CONVERSION,
                      ].includes(d) && (
                        <DimensionAccordian
                          dimension={d}
                          onCheck={onCheck}
                          isChecked={isChecked}
                          getDimensionValues={getDimensionValues}
                          loading={loading}
                        />
                      )}
                    </Stack>
                  );

                  if (snapshot.isDragging) {
                    return ReactDOM.createPortal(children, document.body);
                  }
                  return children;
                }}
              </Draggable>
            ))}
            {prov.placeholder}
          </Box>
        )}
      </Droppable>
      {isMultiple && nonTrendDimensions.length < 5 && (
        <Box mt={3}>
          <Button
            color="secondary"
            size="extraSmall"
            endIcon={<Add />}
            onClick={() => {
              setChart(
                produce(chart, (draft) => {
                  if (!draft.dimension) {
                    draft.dimension = [];
                  }
                  draft.dimension.push("");
                })
              );
            }}
          >
            Add additional dimension
          </Button>
        </Box>
      )}
    </AccordianChartBuilderSidebarBlock>
  );
}

export default ChartBuilderXAxis;
