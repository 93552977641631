/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiElementReorderPayloadToJSON, ApiListReportResponseFromJSON, ApiListTabElementsResponseFromJSON, ApiListTabResponseFromJSON, ApiReportPayloadToJSON, ApiReportResponseFromJSON, ApiReportZIPPayloadToJSON, ApiTabChartCSVPayloadToJSON, ApiTabChartPayloadToJSON, ApiTabChartResponseFromJSON, ApiTabElementPayloadToJSON, ApiTabElementResponseFromJSON, ApiTabPayloadToJSON, ApiTabResponseFromJSON, StreamResultOfApiFileResponseFromJSON, } from '../models';
/**
 *
 */
export class ReportServiceApi extends runtime.BaseAPI {
    /**
     */
    reportServiceAddElementToElementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceAddElementToElement.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceAddElementToElement.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceAddElementToElement.');
            }
            if (requestParameters.parentId === null || requestParameters.parentId === undefined) {
                throw new runtime.RequiredError('parentId', 'Required parameter requestParameters.parentId was null or undefined when calling reportServiceAddElementToElement.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceAddElementToElement.');
            }
            const queryParameters = {};
            if (requestParameters.elementId !== undefined) {
                queryParameters['elementId'] = requestParameters.elementId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/elements/{parentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))).replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters.parentId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTabElementPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabElementResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceAddElementToElement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceAddElementToElementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceAddElementToTabRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceAddElementToTab.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceAddElementToTab.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceAddElementToTab.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceAddElementToTab.');
            }
            const queryParameters = {};
            if (requestParameters.elementId !== undefined) {
                queryParameters['elementId'] = requestParameters.elementId;
            }
            if (requestParameters.parentId !== undefined) {
                queryParameters['parentId'] = requestParameters.parentId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/elements`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTabElementPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabElementResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceAddElementToTab(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceAddElementToTabRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceCreateReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceCreateReport.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceCreateReport.');
            }
            const queryParameters = {};
            if (requestParameters.reportId !== undefined) {
                queryParameters['reportId'] = requestParameters.reportId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiReportPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReportResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceCreateReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceCreateReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceCreateTabRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceCreateTab.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceCreateTab.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceCreateTab.');
            }
            const queryParameters = {};
            if (requestParameters.tabId !== undefined) {
                queryParameters['tabId'] = requestParameters.tabId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTabPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceCreateTab(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceCreateTabRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceDeleteElementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceDeleteElement.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceDeleteElement.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceDeleteElement.');
            }
            if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
                throw new runtime.RequiredError('elementId', 'Required parameter requestParameters.elementId was null or undefined when calling reportServiceDeleteElement.');
            }
            const queryParameters = {};
            if (requestParameters.parentId !== undefined) {
                queryParameters['parentId'] = requestParameters.parentId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadChartChartId !== undefined) {
                queryParameters['payload.chart.chartId'] = requestParameters.payloadChartChartId;
            }
            if (requestParameters.payloadChartOrder !== undefined) {
                queryParameters['payload.chart.order'] = requestParameters.payloadChartOrder;
            }
            if (requestParameters.payloadChartOverridingChartName !== undefined) {
                queryParameters['payload.chart.overridingChart.name'] = requestParameters.payloadChartOverridingChartName;
            }
            if (requestParameters.payloadChartOverridingChartType !== undefined) {
                queryParameters['payload.chart.overridingChart.type'] = requestParameters.payloadChartOverridingChartType;
            }
            if (requestParameters.payloadChartOverridingChartDimension) {
                queryParameters['payload.chart.overridingChart.dimension'] = requestParameters.payloadChartOverridingChartDimension;
            }
            if (requestParameters.payloadChartOverridingChartOrderBy !== undefined) {
                queryParameters['payload.chart.overridingChart.orderBy'] = requestParameters.payloadChartOverridingChartOrderBy;
            }
            if (requestParameters.payloadChartOverridingChartLimit !== undefined) {
                queryParameters['payload.chart.overridingChart.limit'] = requestParameters.payloadChartOverridingChartLimit;
            }
            if (requestParameters.payloadChartOverridingChartTemplate !== undefined) {
                queryParameters['payload.chart.overridingChart.template'] = requestParameters.payloadChartOverridingChartTemplate;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsRowsPerPage !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.rowsPerPage'] = requestParameters.payloadChartOverridingChartDisplayOptionsRowsPerPage;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoPagination !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noPagination'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoPagination;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsAvailableMetrics) {
                queryParameters['payload.chart.overridingChart.displayOptions.availableMetrics'] = requestParameters.payloadChartOverridingChartDisplayOptionsAvailableMetrics;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalText !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.pieTotalText'] = requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalText;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoLegend !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noLegend'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoLegend;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoReorder !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noReorder'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoReorder;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsMetricsOrder) {
                queryParameters['payload.chart.overridingChart.displayOptions.metricsOrder'] = requestParameters.payloadChartOverridingChartDisplayOptionsMetricsOrder;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoFilters !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noFilters'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoFilters;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsShowLabels !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.showLabels'] = requestParameters.payloadChartOverridingChartDisplayOptionsShowLabels;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsAvailableDimensions) {
                queryParameters['payload.chart.overridingChart.displayOptions.availableDimensions'] = requestParameters.payloadChartOverridingChartDisplayOptionsAvailableDimensions;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsFilterDropdowns) {
                queryParameters['payload.chart.overridingChart.displayOptions.filterDropdowns'] = requestParameters.payloadChartOverridingChartDisplayOptionsFilterDropdowns;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsLayout !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.layout'] = requestParameters.payloadChartOverridingChartDisplayOptionsLayout;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoTitle !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noTitle'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoTitle;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoAttributionToggle !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noAttributionToggle'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoAttributionToggle;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoAxis !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noAxis'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoAxis;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalFontSize !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.pieTotalFontSize'] = requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalFontSize;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsShowTotals !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.showTotals'] = requestParameters.payloadChartOverridingChartDisplayOptionsShowTotals;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatDesignIndex !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statDesignIndex'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatDesignIndex;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statTitle'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle2 !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statTitle2'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle2;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatNoBg !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statNoBg'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatNoBg;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsFunnelUseLogFn !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.funnelUseLogFn'] = requestParameters.payloadChartOverridingChartDisplayOptionsFunnelUseLogFn;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsFullAttributesList) {
                queryParameters['payload.chart.overridingChart.displayOptions.fullAttributesList'] = requestParameters.payloadChartOverridingChartDisplayOptionsFullAttributesList;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsTablePinAt !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.tablePinAt'] = requestParameters.payloadChartOverridingChartDisplayOptionsTablePinAt;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsDisabledMetricClick !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.disabledMetricClick'] = requestParameters.payloadChartOverridingChartDisplayOptionsDisabledMetricClick;
            }
            if (requestParameters.payloadChartOverridingChartCrawler !== undefined) {
                queryParameters['payload.chart.overridingChart.crawler'] = requestParameters.payloadChartOverridingChartCrawler;
            }
            if (requestParameters.payloadChartOverridingChartComparison !== undefined) {
                queryParameters['payload.chart.overridingChart.comparison'] = requestParameters.payloadChartOverridingChartComparison;
            }
            if (requestParameters.payloadChartOverridingChartIncludeEmpty !== undefined) {
                queryParameters['payload.chart.overridingChart.includeEmpty'] = requestParameters.payloadChartOverridingChartIncludeEmpty;
            }
            if (requestParameters.payloadChartOverridingChartDescription !== undefined) {
                queryParameters['payload.chart.overridingChart.description'] = requestParameters.payloadChartOverridingChartDescription;
            }
            if (requestParameters.payloadChartOverridingChartIncludeSubdomains !== undefined) {
                queryParameters['payload.chart.overridingChart.includeSubdomains'] = requestParameters.payloadChartOverridingChartIncludeSubdomains;
            }
            if (requestParameters.payloadChartOverridingChartWithTotals !== undefined) {
                queryParameters['payload.chart.overridingChart.withTotals'] = requestParameters.payloadChartOverridingChartWithTotals;
            }
            if (requestParameters.payloadChartRemoveOverridingChart !== undefined) {
                queryParameters['payload.chart.removeOverridingChart'] = requestParameters.payloadChartRemoveOverridingChart;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/elements/{elementId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))).replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    reportServiceDeleteElement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceDeleteElementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceDeleteReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceDeleteReport.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceDeleteReport.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    reportServiceDeleteReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceDeleteReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceDeleteTabRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceDeleteTab.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceDeleteTab.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceDeleteTab.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadOrder !== undefined) {
                queryParameters['payload.order'] = requestParameters.payloadOrder;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    reportServiceDeleteTab(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceDeleteTabRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Tab Elements ----------------
     */
    reportServiceListElementsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceListElements.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceListElements.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceListElements.');
            }
            const queryParameters = {};
            if (requestParameters.pageSize !== undefined) {
                queryParameters['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.pageToken !== undefined) {
                queryParameters['pageToken'] = requestParameters.pageToken;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/elements`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListTabElementsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Tab Elements ----------------
     */
    reportServiceListElements(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceListElementsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Reports ----------------
     */
    reportServiceListReportsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceListReports.');
            }
            const queryParameters = {};
            if (requestParameters.pageSize !== undefined) {
                queryParameters['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.pageToken !== undefined) {
                queryParameters['pageToken'] = requestParameters.pageToken;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListReportResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Reports ----------------
     */
    reportServiceListReports(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceListReportsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceListTabsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceListTabs.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceListTabs.');
            }
            const queryParameters = {};
            if (requestParameters.pageSize !== undefined) {
                queryParameters['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.pageToken !== undefined) {
                queryParameters['pageToken'] = requestParameters.pageToken;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListTabResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceListTabs(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceListTabsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServicePinReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServicePinReport.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServicePinReport.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/pin`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReportResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServicePinReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServicePinReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceReadElementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceReadElement.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceReadElement.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceReadElement.');
            }
            if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
                throw new runtime.RequiredError('elementId', 'Required parameter requestParameters.elementId was null or undefined when calling reportServiceReadElement.');
            }
            const queryParameters = {};
            if (requestParameters.parentId !== undefined) {
                queryParameters['parentId'] = requestParameters.parentId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadChartChartId !== undefined) {
                queryParameters['payload.chart.chartId'] = requestParameters.payloadChartChartId;
            }
            if (requestParameters.payloadChartOrder !== undefined) {
                queryParameters['payload.chart.order'] = requestParameters.payloadChartOrder;
            }
            if (requestParameters.payloadChartOverridingChartName !== undefined) {
                queryParameters['payload.chart.overridingChart.name'] = requestParameters.payloadChartOverridingChartName;
            }
            if (requestParameters.payloadChartOverridingChartType !== undefined) {
                queryParameters['payload.chart.overridingChart.type'] = requestParameters.payloadChartOverridingChartType;
            }
            if (requestParameters.payloadChartOverridingChartDimension) {
                queryParameters['payload.chart.overridingChart.dimension'] = requestParameters.payloadChartOverridingChartDimension;
            }
            if (requestParameters.payloadChartOverridingChartOrderBy !== undefined) {
                queryParameters['payload.chart.overridingChart.orderBy'] = requestParameters.payloadChartOverridingChartOrderBy;
            }
            if (requestParameters.payloadChartOverridingChartLimit !== undefined) {
                queryParameters['payload.chart.overridingChart.limit'] = requestParameters.payloadChartOverridingChartLimit;
            }
            if (requestParameters.payloadChartOverridingChartTemplate !== undefined) {
                queryParameters['payload.chart.overridingChart.template'] = requestParameters.payloadChartOverridingChartTemplate;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsRowsPerPage !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.rowsPerPage'] = requestParameters.payloadChartOverridingChartDisplayOptionsRowsPerPage;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoPagination !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noPagination'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoPagination;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsAvailableMetrics) {
                queryParameters['payload.chart.overridingChart.displayOptions.availableMetrics'] = requestParameters.payloadChartOverridingChartDisplayOptionsAvailableMetrics;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalText !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.pieTotalText'] = requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalText;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoLegend !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noLegend'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoLegend;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoReorder !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noReorder'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoReorder;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsMetricsOrder) {
                queryParameters['payload.chart.overridingChart.displayOptions.metricsOrder'] = requestParameters.payloadChartOverridingChartDisplayOptionsMetricsOrder;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoFilters !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noFilters'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoFilters;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsShowLabels !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.showLabels'] = requestParameters.payloadChartOverridingChartDisplayOptionsShowLabels;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsAvailableDimensions) {
                queryParameters['payload.chart.overridingChart.displayOptions.availableDimensions'] = requestParameters.payloadChartOverridingChartDisplayOptionsAvailableDimensions;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsFilterDropdowns) {
                queryParameters['payload.chart.overridingChart.displayOptions.filterDropdowns'] = requestParameters.payloadChartOverridingChartDisplayOptionsFilterDropdowns;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsLayout !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.layout'] = requestParameters.payloadChartOverridingChartDisplayOptionsLayout;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoTitle !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noTitle'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoTitle;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoAttributionToggle !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noAttributionToggle'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoAttributionToggle;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsNoAxis !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.noAxis'] = requestParameters.payloadChartOverridingChartDisplayOptionsNoAxis;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalFontSize !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.pieTotalFontSize'] = requestParameters.payloadChartOverridingChartDisplayOptionsPieTotalFontSize;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsShowTotals !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.showTotals'] = requestParameters.payloadChartOverridingChartDisplayOptionsShowTotals;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatDesignIndex !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statDesignIndex'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatDesignIndex;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statTitle'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle2 !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statTitle2'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatTitle2;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsStatNoBg !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.statNoBg'] = requestParameters.payloadChartOverridingChartDisplayOptionsStatNoBg;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsFunnelUseLogFn !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.funnelUseLogFn'] = requestParameters.payloadChartOverridingChartDisplayOptionsFunnelUseLogFn;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsFullAttributesList) {
                queryParameters['payload.chart.overridingChart.displayOptions.fullAttributesList'] = requestParameters.payloadChartOverridingChartDisplayOptionsFullAttributesList;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsTablePinAt !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.tablePinAt'] = requestParameters.payloadChartOverridingChartDisplayOptionsTablePinAt;
            }
            if (requestParameters.payloadChartOverridingChartDisplayOptionsDisabledMetricClick !== undefined) {
                queryParameters['payload.chart.overridingChart.displayOptions.disabledMetricClick'] = requestParameters.payloadChartOverridingChartDisplayOptionsDisabledMetricClick;
            }
            if (requestParameters.payloadChartOverridingChartCrawler !== undefined) {
                queryParameters['payload.chart.overridingChart.crawler'] = requestParameters.payloadChartOverridingChartCrawler;
            }
            if (requestParameters.payloadChartOverridingChartComparison !== undefined) {
                queryParameters['payload.chart.overridingChart.comparison'] = requestParameters.payloadChartOverridingChartComparison;
            }
            if (requestParameters.payloadChartOverridingChartIncludeEmpty !== undefined) {
                queryParameters['payload.chart.overridingChart.includeEmpty'] = requestParameters.payloadChartOverridingChartIncludeEmpty;
            }
            if (requestParameters.payloadChartOverridingChartDescription !== undefined) {
                queryParameters['payload.chart.overridingChart.description'] = requestParameters.payloadChartOverridingChartDescription;
            }
            if (requestParameters.payloadChartOverridingChartIncludeSubdomains !== undefined) {
                queryParameters['payload.chart.overridingChart.includeSubdomains'] = requestParameters.payloadChartOverridingChartIncludeSubdomains;
            }
            if (requestParameters.payloadChartOverridingChartWithTotals !== undefined) {
                queryParameters['payload.chart.overridingChart.withTotals'] = requestParameters.payloadChartOverridingChartWithTotals;
            }
            if (requestParameters.payloadChartRemoveOverridingChart !== undefined) {
                queryParameters['payload.chart.removeOverridingChart'] = requestParameters.payloadChartRemoveOverridingChart;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/elements/{elementId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))).replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabElementResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceReadElement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceReadElementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceReadReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceReadReport.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceReadReport.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReportResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceReadReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceReadReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceReadReportZIPRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceReadReportZIP.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceReadReportZIP.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceReadReportZIP.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/zip`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiReportZIPPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => StreamResultOfApiFileResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceReadReportZIP(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceReadReportZIPRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceReadTabRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceReadTab.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceReadTab.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceReadTab.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadOrder !== undefined) {
                queryParameters['payload.order'] = requestParameters.payloadOrder;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceReadTab(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceReadTabRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceReadTabChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceReadTabChart.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceReadTabChart.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceReadTabChart.');
            }
            if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
                throw new runtime.RequiredError('chartId', 'Required parameter requestParameters.chartId was null or undefined when calling reportServiceReadTabChart.');
            }
            const queryParameters = {};
            if (requestParameters.payloadChartId !== undefined) {
                queryParameters['payload.chartId'] = requestParameters.payloadChartId;
            }
            if (requestParameters.payloadOrder !== undefined) {
                queryParameters['payload.order'] = requestParameters.payloadOrder;
            }
            if (requestParameters.payloadOverridingChartName !== undefined) {
                queryParameters['payload.overridingChart.name'] = requestParameters.payloadOverridingChartName;
            }
            if (requestParameters.payloadOverridingChartType !== undefined) {
                queryParameters['payload.overridingChart.type'] = requestParameters.payloadOverridingChartType;
            }
            if (requestParameters.payloadOverridingChartDimension) {
                queryParameters['payload.overridingChart.dimension'] = requestParameters.payloadOverridingChartDimension;
            }
            if (requestParameters.payloadOverridingChartOrderBy !== undefined) {
                queryParameters['payload.overridingChart.orderBy'] = requestParameters.payloadOverridingChartOrderBy;
            }
            if (requestParameters.payloadOverridingChartLimit !== undefined) {
                queryParameters['payload.overridingChart.limit'] = requestParameters.payloadOverridingChartLimit;
            }
            if (requestParameters.payloadOverridingChartTemplate !== undefined) {
                queryParameters['payload.overridingChart.template'] = requestParameters.payloadOverridingChartTemplate;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsRowsPerPage !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.rowsPerPage'] = requestParameters.payloadOverridingChartDisplayOptionsRowsPerPage;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsNoPagination !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.noPagination'] = requestParameters.payloadOverridingChartDisplayOptionsNoPagination;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsAvailableMetrics) {
                queryParameters['payload.overridingChart.displayOptions.availableMetrics'] = requestParameters.payloadOverridingChartDisplayOptionsAvailableMetrics;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsPieTotalText !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.pieTotalText'] = requestParameters.payloadOverridingChartDisplayOptionsPieTotalText;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsNoLegend !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.noLegend'] = requestParameters.payloadOverridingChartDisplayOptionsNoLegend;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsNoReorder !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.noReorder'] = requestParameters.payloadOverridingChartDisplayOptionsNoReorder;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsMetricsOrder) {
                queryParameters['payload.overridingChart.displayOptions.metricsOrder'] = requestParameters.payloadOverridingChartDisplayOptionsMetricsOrder;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsNoFilters !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.noFilters'] = requestParameters.payloadOverridingChartDisplayOptionsNoFilters;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsShowLabels !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.showLabels'] = requestParameters.payloadOverridingChartDisplayOptionsShowLabels;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsAvailableDimensions) {
                queryParameters['payload.overridingChart.displayOptions.availableDimensions'] = requestParameters.payloadOverridingChartDisplayOptionsAvailableDimensions;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsFilterDropdowns) {
                queryParameters['payload.overridingChart.displayOptions.filterDropdowns'] = requestParameters.payloadOverridingChartDisplayOptionsFilterDropdowns;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsLayout !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.layout'] = requestParameters.payloadOverridingChartDisplayOptionsLayout;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsNoTitle !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.noTitle'] = requestParameters.payloadOverridingChartDisplayOptionsNoTitle;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsNoAttributionToggle !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.noAttributionToggle'] = requestParameters.payloadOverridingChartDisplayOptionsNoAttributionToggle;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsNoAxis !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.noAxis'] = requestParameters.payloadOverridingChartDisplayOptionsNoAxis;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsPieTotalFontSize !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.pieTotalFontSize'] = requestParameters.payloadOverridingChartDisplayOptionsPieTotalFontSize;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsShowTotals !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.showTotals'] = requestParameters.payloadOverridingChartDisplayOptionsShowTotals;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsStatDesignIndex !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.statDesignIndex'] = requestParameters.payloadOverridingChartDisplayOptionsStatDesignIndex;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsStatTitle !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.statTitle'] = requestParameters.payloadOverridingChartDisplayOptionsStatTitle;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsStatTitle2 !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.statTitle2'] = requestParameters.payloadOverridingChartDisplayOptionsStatTitle2;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsStatNoBg !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.statNoBg'] = requestParameters.payloadOverridingChartDisplayOptionsStatNoBg;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsFunnelUseLogFn !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.funnelUseLogFn'] = requestParameters.payloadOverridingChartDisplayOptionsFunnelUseLogFn;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsFullAttributesList) {
                queryParameters['payload.overridingChart.displayOptions.fullAttributesList'] = requestParameters.payloadOverridingChartDisplayOptionsFullAttributesList;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsTablePinAt !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.tablePinAt'] = requestParameters.payloadOverridingChartDisplayOptionsTablePinAt;
            }
            if (requestParameters.payloadOverridingChartDisplayOptionsDisabledMetricClick !== undefined) {
                queryParameters['payload.overridingChart.displayOptions.disabledMetricClick'] = requestParameters.payloadOverridingChartDisplayOptionsDisabledMetricClick;
            }
            if (requestParameters.payloadOverridingChartCrawler !== undefined) {
                queryParameters['payload.overridingChart.crawler'] = requestParameters.payloadOverridingChartCrawler;
            }
            if (requestParameters.payloadOverridingChartComparison !== undefined) {
                queryParameters['payload.overridingChart.comparison'] = requestParameters.payloadOverridingChartComparison;
            }
            if (requestParameters.payloadOverridingChartIncludeEmpty !== undefined) {
                queryParameters['payload.overridingChart.includeEmpty'] = requestParameters.payloadOverridingChartIncludeEmpty;
            }
            if (requestParameters.payloadOverridingChartDescription !== undefined) {
                queryParameters['payload.overridingChart.description'] = requestParameters.payloadOverridingChartDescription;
            }
            if (requestParameters.payloadOverridingChartIncludeSubdomains !== undefined) {
                queryParameters['payload.overridingChart.includeSubdomains'] = requestParameters.payloadOverridingChartIncludeSubdomains;
            }
            if (requestParameters.payloadOverridingChartWithTotals !== undefined) {
                queryParameters['payload.overridingChart.withTotals'] = requestParameters.payloadOverridingChartWithTotals;
            }
            if (requestParameters.payloadRemoveOverridingChart !== undefined) {
                queryParameters['payload.removeOverridingChart'] = requestParameters.payloadRemoveOverridingChart;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/charts/{chartId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabChartResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceReadTabChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceReadTabChartRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceReadTabChartCSVRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceReadTabChartCSV.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceReadTabChartCSV.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceReadTabChartCSV.');
            }
            if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
                throw new runtime.RequiredError('chartId', 'Required parameter requestParameters.chartId was null or undefined when calling reportServiceReadTabChartCSV.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceReadTabChartCSV.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/charts/{chartId}/csv`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTabChartCSVPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => StreamResultOfApiFileResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceReadTabChartCSV(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceReadTabChartCSVRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Tab Elements ----------------
     */
    reportServiceReorderElementsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceReorderElements.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceReorderElements.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceReorderElements.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceReorderElements.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/elements`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiElementReorderPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListTabElementsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Tab Elements ----------------
     */
    reportServiceReorderElements(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceReorderElementsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceToggleFavouriteReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceToggleFavouriteReport.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceToggleFavouriteReport.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/favourite`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReportResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceToggleFavouriteReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceToggleFavouriteReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceUpdateElementRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceUpdateElement.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceUpdateElement.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceUpdateElement.');
            }
            if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
                throw new runtime.RequiredError('elementId', 'Required parameter requestParameters.elementId was null or undefined when calling reportServiceUpdateElement.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceUpdateElement.');
            }
            const queryParameters = {};
            if (requestParameters.parentId !== undefined) {
                queryParameters['parentId'] = requestParameters.parentId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/elements/{elementId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))).replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTabElementPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabElementResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceUpdateElement(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceUpdateElementRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceUpdateReportRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceUpdateReport.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceUpdateReport.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceUpdateReport.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiReportPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReportResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceUpdateReport(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceUpdateReportRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceUpdateTabRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceUpdateTab.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceUpdateTab.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceUpdateTab.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceUpdateTab.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTabPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceUpdateTab(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceUpdateTabRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    reportServiceUpdateTabChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling reportServiceUpdateTabChart.');
            }
            if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
                throw new runtime.RequiredError('reportId', 'Required parameter requestParameters.reportId was null or undefined when calling reportServiceUpdateTabChart.');
            }
            if (requestParameters.tabId === null || requestParameters.tabId === undefined) {
                throw new runtime.RequiredError('tabId', 'Required parameter requestParameters.tabId was null or undefined when calling reportServiceUpdateTabChart.');
            }
            if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
                throw new runtime.RequiredError('chartId', 'Required parameter requestParameters.chartId was null or undefined when calling reportServiceUpdateTabChart.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling reportServiceUpdateTabChart.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/report/v1/projects/{projectId}/reports/{reportId}/tabs/{tabId}/charts/{chartId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"tabId"}}`, encodeURIComponent(String(requestParameters.tabId))).replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiTabChartPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiTabChartResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    reportServiceUpdateTabChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.reportServiceUpdateTabChartRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadTypeEnum;
(function (ReportServiceDeleteElementPayloadTypeEnum) {
    ReportServiceDeleteElementPayloadTypeEnum["UNSPECIFIED"] = "ELEMENT_TYPE_UNSPECIFIED";
    ReportServiceDeleteElementPayloadTypeEnum["BLOCK"] = "ELEMENT_TYPE_BLOCK";
    ReportServiceDeleteElementPayloadTypeEnum["CHART"] = "ELEMENT_TYPE_CHART";
    ReportServiceDeleteElementPayloadTypeEnum["STAT"] = "ELEMENT_TYPE_STAT";
})(ReportServiceDeleteElementPayloadTypeEnum || (ReportServiceDeleteElementPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["UNDEFINED"] = "CHART_TYPE_UNDEFINED";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["PIE"] = "CHART_TYPE_PIE";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["GRAPH"] = "CHART_TYPE_GRAPH";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["RADAR"] = "CHART_TYPE_RADAR";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["TABLE"] = "CHART_TYPE_TABLE";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["BUBBLE"] = "CHART_TYPE_BUBBLE";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["TREE_MAP"] = "CHART_TYPE_TREE_MAP";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["STAT_CARD"] = "CHART_TYPE_STAT_CARD";
    ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum["FUNNEL"] = "CHART_TYPE_FUNNEL";
})(ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum || (ReportServiceDeleteElementPayloadChartOverridingChartTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum["UNDEFINED"] = "CHART_TEMPLATE_UNDEFINED";
    ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum["TREND"] = "CHART_TEMPLATE_TREND";
    ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum["MAP"] = "CHART_TEMPLATE_MAP";
    ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum["SNAPSHOT"] = "CHART_TEMPLATE_SNAPSHOT";
    ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum["TABLE"] = "CHART_TEMPLATE_TABLE";
    ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum["ATTRIBUTION"] = "CHART_TEMPLATE_ATTRIBUTION";
})(ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum || (ReportServiceDeleteElementPayloadChartOverridingChartTemplateEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_SET"] = "MetricNotSet";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SPEND"] = "MetricSpend";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CPA"] = "MetricCPA";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CAC"] = "MetricCAC";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ECR"] = "MetricECR";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CPNV"] = "MetricCPNV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RANK"] = "MetricRank";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS_DIFFERENCE"] = "MetricPublisherClicksDifference";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_PAGEVIEW_COUNT"] = "MetricVelocityPageviewCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_COUNT"] = "MetricVelocitySessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_COUNT"] = "MetricNotVelocitySessionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_REVENUE"] = "MetricVelocitySessionRevenue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_REVENUE_IMPACT"] = "MetricVelocitySessionRevenueImpact";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNotVelocitySessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricVelocitySessionMacroConversionCount";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricNotVelocitySessionMacroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricVelocitySessionMacroConversionRate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE_UPLIFT"] = "MetricVelocitySessionMacroConversionRateUplift";
})(ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum || (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum || (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum || (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["HORIZONTAL"] = "horizontal";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["VERTICAL"] = "vertical";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["CENTRIC"] = "centric";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["RADIAL"] = "radial";
})(ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum || (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsLayoutEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum || (ReportServiceDeleteElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceDeleteElementPayloadChartOverridingChartCrawlerEnum;
(function (ReportServiceDeleteElementPayloadChartOverridingChartCrawlerEnum) {
    ReportServiceDeleteElementPayloadChartOverridingChartCrawlerEnum["NO_CRAWLERS"] = "NO_CRAWLERS";
    ReportServiceDeleteElementPayloadChartOverridingChartCrawlerEnum["ONLY_CRAWLERS"] = "ONLY_CRAWLERS";
    ReportServiceDeleteElementPayloadChartOverridingChartCrawlerEnum["WITH_CRAWLERS"] = "WITH_CRAWLERS";
})(ReportServiceDeleteElementPayloadChartOverridingChartCrawlerEnum || (ReportServiceDeleteElementPayloadChartOverridingChartCrawlerEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadTypeEnum;
(function (ReportServiceReadElementPayloadTypeEnum) {
    ReportServiceReadElementPayloadTypeEnum["UNSPECIFIED"] = "ELEMENT_TYPE_UNSPECIFIED";
    ReportServiceReadElementPayloadTypeEnum["BLOCK"] = "ELEMENT_TYPE_BLOCK";
    ReportServiceReadElementPayloadTypeEnum["CHART"] = "ELEMENT_TYPE_CHART";
    ReportServiceReadElementPayloadTypeEnum["STAT"] = "ELEMENT_TYPE_STAT";
})(ReportServiceReadElementPayloadTypeEnum || (ReportServiceReadElementPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartTypeEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartTypeEnum) {
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["UNDEFINED"] = "CHART_TYPE_UNDEFINED";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["PIE"] = "CHART_TYPE_PIE";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["GRAPH"] = "CHART_TYPE_GRAPH";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["RADAR"] = "CHART_TYPE_RADAR";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["TABLE"] = "CHART_TYPE_TABLE";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["BUBBLE"] = "CHART_TYPE_BUBBLE";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["TREE_MAP"] = "CHART_TYPE_TREE_MAP";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["STAT_CARD"] = "CHART_TYPE_STAT_CARD";
    ReportServiceReadElementPayloadChartOverridingChartTypeEnum["FUNNEL"] = "CHART_TYPE_FUNNEL";
})(ReportServiceReadElementPayloadChartOverridingChartTypeEnum || (ReportServiceReadElementPayloadChartOverridingChartTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartTemplateEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartTemplateEnum) {
    ReportServiceReadElementPayloadChartOverridingChartTemplateEnum["UNDEFINED"] = "CHART_TEMPLATE_UNDEFINED";
    ReportServiceReadElementPayloadChartOverridingChartTemplateEnum["TREND"] = "CHART_TEMPLATE_TREND";
    ReportServiceReadElementPayloadChartOverridingChartTemplateEnum["MAP"] = "CHART_TEMPLATE_MAP";
    ReportServiceReadElementPayloadChartOverridingChartTemplateEnum["SNAPSHOT"] = "CHART_TEMPLATE_SNAPSHOT";
    ReportServiceReadElementPayloadChartOverridingChartTemplateEnum["TABLE"] = "CHART_TEMPLATE_TABLE";
    ReportServiceReadElementPayloadChartOverridingChartTemplateEnum["ATTRIBUTION"] = "CHART_TEMPLATE_ATTRIBUTION";
})(ReportServiceReadElementPayloadChartOverridingChartTemplateEnum || (ReportServiceReadElementPayloadChartOverridingChartTemplateEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum) {
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_SET"] = "MetricNotSet";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SPEND"] = "MetricSpend";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CPA"] = "MetricCPA";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CAC"] = "MetricCAC";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ECR"] = "MetricECR";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CPNV"] = "MetricCPNV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RANK"] = "MetricRank";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS_DIFFERENCE"] = "MetricPublisherClicksDifference";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_PAGEVIEW_COUNT"] = "MetricVelocityPageviewCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_COUNT"] = "MetricVelocitySessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_COUNT"] = "MetricNotVelocitySessionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_REVENUE"] = "MetricVelocitySessionRevenue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_REVENUE_IMPACT"] = "MetricVelocitySessionRevenueImpact";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNotVelocitySessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricVelocitySessionMacroConversionCount";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricNotVelocitySessionMacroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricVelocitySessionMacroConversionRate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE_UPLIFT"] = "MetricVelocitySessionMacroConversionRateUplift";
})(ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum || (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableMetricsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum) {
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum || (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsAvailableDimensionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum) {
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum || (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFilterDropdownsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum) {
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["HORIZONTAL"] = "horizontal";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["VERTICAL"] = "vertical";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["CENTRIC"] = "centric";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum["RADIAL"] = "radial";
})(ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum || (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsLayoutEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum) {
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum || (ReportServiceReadElementPayloadChartOverridingChartDisplayOptionsFullAttributesListEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadElementPayloadChartOverridingChartCrawlerEnum;
(function (ReportServiceReadElementPayloadChartOverridingChartCrawlerEnum) {
    ReportServiceReadElementPayloadChartOverridingChartCrawlerEnum["NO_CRAWLERS"] = "NO_CRAWLERS";
    ReportServiceReadElementPayloadChartOverridingChartCrawlerEnum["ONLY_CRAWLERS"] = "ONLY_CRAWLERS";
    ReportServiceReadElementPayloadChartOverridingChartCrawlerEnum["WITH_CRAWLERS"] = "WITH_CRAWLERS";
})(ReportServiceReadElementPayloadChartOverridingChartCrawlerEnum || (ReportServiceReadElementPayloadChartOverridingChartCrawlerEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartTypeEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartTypeEnum) {
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["UNDEFINED"] = "CHART_TYPE_UNDEFINED";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["PIE"] = "CHART_TYPE_PIE";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["GRAPH"] = "CHART_TYPE_GRAPH";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["RADAR"] = "CHART_TYPE_RADAR";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["TABLE"] = "CHART_TYPE_TABLE";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["BUBBLE"] = "CHART_TYPE_BUBBLE";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["TREE_MAP"] = "CHART_TYPE_TREE_MAP";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["STAT_CARD"] = "CHART_TYPE_STAT_CARD";
    ReportServiceReadTabChartPayloadOverridingChartTypeEnum["FUNNEL"] = "CHART_TYPE_FUNNEL";
})(ReportServiceReadTabChartPayloadOverridingChartTypeEnum || (ReportServiceReadTabChartPayloadOverridingChartTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartTemplateEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartTemplateEnum) {
    ReportServiceReadTabChartPayloadOverridingChartTemplateEnum["UNDEFINED"] = "CHART_TEMPLATE_UNDEFINED";
    ReportServiceReadTabChartPayloadOverridingChartTemplateEnum["TREND"] = "CHART_TEMPLATE_TREND";
    ReportServiceReadTabChartPayloadOverridingChartTemplateEnum["MAP"] = "CHART_TEMPLATE_MAP";
    ReportServiceReadTabChartPayloadOverridingChartTemplateEnum["SNAPSHOT"] = "CHART_TEMPLATE_SNAPSHOT";
    ReportServiceReadTabChartPayloadOverridingChartTemplateEnum["TABLE"] = "CHART_TEMPLATE_TABLE";
    ReportServiceReadTabChartPayloadOverridingChartTemplateEnum["ATTRIBUTION"] = "CHART_TEMPLATE_ATTRIBUTION";
})(ReportServiceReadTabChartPayloadOverridingChartTemplateEnum || (ReportServiceReadTabChartPayloadOverridingChartTemplateEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum) {
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_SET"] = "MetricNotSet";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXIT_RATE"] = "MetricExitRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SPEND"] = "MetricSpend";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CPA"] = "MetricCPA";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CAC"] = "MetricCAC";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ECR"] = "MetricECR";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CPNV"] = "MetricCPNV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RANK"] = "MetricRank";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_COUNT"] = "MetricRepeatCustomerCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_RATE"] = "MetricRepeatCustomerRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_REVENUE"] = "MetricRepeatCustomerRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_REPEAT_CUSTOMER_SALES_COUNT"] = "MetricRepeatCustomerSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_CALCULATED_CTR"] = "MetricCalculatedCTR";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_SALES_COUNT"] = "MetricSalesCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_PUBLISHER_CLICKS_DIFFERENCE"] = "MetricPublisherClicksDifference";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_PAGEVIEW_COUNT"] = "MetricVelocityPageviewCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_COUNT"] = "MetricVelocitySessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_COUNT"] = "MetricNotVelocitySessionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_REVENUE"] = "MetricVelocitySessionRevenue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_REVENUE_IMPACT"] = "MetricVelocitySessionRevenueImpact";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNotVelocitySessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricVelocitySessionMacroConversionCount";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_NOT_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricNotVelocitySessionMacroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE"] = "MetricVelocitySessionMacroConversionRate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum["METRIC_VELOCITY_SESSION_MACRO_CONVERSION_RATE_UPLIFT"] = "MetricVelocitySessionMacroConversionRateUplift";
})(ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum || (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableMetricsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum) {
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum || (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsAvailableDimensionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum) {
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum || (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFilterDropdownsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum) {
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum["HORIZONTAL"] = "horizontal";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum["VERTICAL"] = "vertical";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum["CENTRIC"] = "centric";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum["RADIAL"] = "radial";
})(ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum || (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsLayoutEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum) {
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NOT_SET"] = "DimensionNotSet";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY"] = "DimensionSessionStartByDay";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION"] = "DimensionLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER"] = "DimensionBrowser";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_OS"] = "DimensionOS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DEVICE"] = "DimensionDevice";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL"] = "DimensionChannel";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_USER_TYPE"] = "DimensionUserType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_COUNTRY"] = "DimensionCountry";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REGION"] = "DimensionRegion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CITY"] = "DimensionCity";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LON_LAT"] = "DimensionLonLat";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_AUDIENCE"] = "DimensionSessionAudience";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_AUDIENCE"] = "DimensionLeadAudience";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE"] = "DimensionSource";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MEDIUM"] = "DimensionMedium";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_EXTERNAL_CAMPAIGN"] = "DimensionExternalCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CONTENT"] = "DimensionContent";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TERM"] = "DimensionTerm";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PATH"] = "DimensionPath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_NAME"] = "DimensionProductName";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_CATEGORY"] = "DimensionProductCategory";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT_BRAND"] = "DimensionProductBrand";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_TITLE"] = "DimensionJobTitle";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB_CATEGORY"] = "DimensionJobCategory";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PRODUCT"] = "DimensionProduct";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_JOB"] = "DimensionJob";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_PAGE_TYPE"] = "DimensionLocationPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_PATH"] = "DimensionLandingPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingPageLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION"] = "DimensionLandingPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_TYPE"] = "DimensionLandingPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER_VALUE"] = "DimensionLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_PATH"] = "DimensionLandingNextPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER_VALUE"] = "DimensionLandingNextPageLocationTaggerValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION"] = "DimensionLandingNextPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_TYPE"] = "DimensionLandingNextPageType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR"] = "DimensionSessionStartByHour";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEK"] = "DimensionSessionStartByWeek";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_MONTH"] = "DimensionSessionStartByMonth";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_WEEKDAY"] = "DimensionSessionStartByWeekday";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MICRO_CONVERSION"] = "DimensionMicroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MACRO_CONVERSION"] = "DimensionMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CRAWLER"] = "DimensionCrawler";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_SECOND_VISIT"] = "DimensionDaysToSecondVisit";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_JOURNEY"] = "DimensionSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LEAD_STATUS"] = "DimensionLeadStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LOCATION_TAGGER"] = "DimensionLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_BUCKET"] = "DimensionSourceBucket";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_LOCATION"] = "DimensionSessionLandingPageLocation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_VERSION"] = "DimensionBrowserVersion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_BROWSER_WITH_VERSION"] = "DimensionBrowserWithVersion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY"] = "DimensionChannelMacroConversionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CONVERTING_SESSION_JOURNEY"] = "DimensionConvertingSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionVisitsSincePriorMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_SINCE_PRIOR_MACRO_CONVERSION"] = "DimensionDaysSincePriorMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CHANNEL_SALES_JOURNEY"] = "DimensionChannelSalesJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SALES_SESSION_JOURNEY"] = "DimensionSalesSessionJourney";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_LOCATION_TAGGER"] = "DimensionLandingPageLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_NEXT_PAGE_LOCATION_TAGGER"] = "DimensionLandingNextPageLocationTagger";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_LANDING_PAGE_PATH"] = "DimensionSessionLandingPagePath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN"] = "DimensionCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_LEAD_STATUS"] = "DimensionCampaignLeadStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_CAMPAIGN_TYPE"] = "DimensionCampaignType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_AB_CAMPAIGN"] = "DimensionABCampaign";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DOMAIN"] = "DimensionDomain";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PERSONALISATION"] = "DimensionPersonalisation";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VISITS_TO_FIRST_MACRO_CONVERSION"] = "DimensionVisitsToFirstMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_DAYS_TO_FIRST_MACRO_CONVERSION"] = "DimensionDaysToFirstMacroConversion";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_IS_RETURNING_CUSTOMER"] = "DimensionIsReturningCustomer";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_ID"] = "DimensionSessionId";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_DATE"] = "DimensionSessionStartDate";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TRANSACTION_ID"] = "DimensionTransactionId";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PARAM"] = "DimensionQSParam";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_VALUE"] = "DimensionQSValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS_PAIR"] = "DimensionQSPair";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_QS"] = "DimensionQS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_FULL_URL"] = "DimensionFullURL";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PARAM"] = "DimensionLandingPageQSParam";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_VALUE"] = "DimensionLandingPageQSValue";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS_PAIR"] = "DimensionLandingPageQSPair";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_QS"] = "DimensionLandingPageQS";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_LANDING_PAGE_FULL_URL"] = "DimensionLandingPageFullURL";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER"] = "DimensionReferrer";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_PATH"] = "DimensionReferrerPath";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_REFERRER_DOMAIN"] = "DimensionReferrerDomain";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_MATCH_TYPE"] = "DimensionMatchType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_TERM_WITH_MATCH_TYPE"] = "DimensionTermWithMatchType";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_PAGE_STATUS_CODE"] = "DimensionPageStatusCode";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_HOUR_OF_DAY"] = "DimensionSessionStartByHourOfDay";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY_OF_MONTH"] = "DimensionSessionStartByDayOfMonth";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SESSION_START_BY_DAY_OF_YEAR"] = "DimensionSessionStartByDayOfYear";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NEXT_UNIQUE_PAGE"] = "DimensionNextUniquePage";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_NEXT_UNIQUE_LOCATIONS"] = "DimensionNextUniqueLocations";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_VELOCITY_STATUS"] = "DimensionVelocityStatus";
    ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum["DIMENSION_SOURCE_CATEGORY"] = "DimensionSourceCategory";
})(ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum || (ReportServiceReadTabChartPayloadOverridingChartDisplayOptionsFullAttributesListEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReportServiceReadTabChartPayloadOverridingChartCrawlerEnum;
(function (ReportServiceReadTabChartPayloadOverridingChartCrawlerEnum) {
    ReportServiceReadTabChartPayloadOverridingChartCrawlerEnum["NO_CRAWLERS"] = "NO_CRAWLERS";
    ReportServiceReadTabChartPayloadOverridingChartCrawlerEnum["ONLY_CRAWLERS"] = "ONLY_CRAWLERS";
    ReportServiceReadTabChartPayloadOverridingChartCrawlerEnum["WITH_CRAWLERS"] = "WITH_CRAWLERS";
})(ReportServiceReadTabChartPayloadOverridingChartCrawlerEnum || (ReportServiceReadTabChartPayloadOverridingChartCrawlerEnum = {}));
