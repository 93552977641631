/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiListContractsResponseFromJSON, ApiListProjectSubscriptionsResponseFromJSON, ApiListSubscriptionProductsResponseFromJSON, ApiListSubscriptionsResponseFromJSON, ApiSimpleContractFromJSON, ApiSimpleContractToJSON, ApiSimpleProductFromJSON, ApiSimpleProductToJSON, ApiSimpleProjectSubscriptionFromJSON, ApiSimpleSubscriptionFromJSON, ApiSimpleSubscriptionToJSON, InlineObject3ToJSON, } from '../models';
/**
 *
 */
export class SubscriptionServiceApi extends runtime.BaseAPI {
    /**
     */
    subscriptionServiceActivateSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
                throw new runtime.RequiredError('subscriptionId', 'Required parameter requestParameters.subscriptionId was null or undefined when calling subscriptionServiceActivateSubscription.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceActivateSubscription.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription/{subscriptionId}/activate`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleSubscriptionToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceActivateSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceActivateSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceCreateContractRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceCreateContract.');
            }
            const queryParameters = {};
            if (requestParameters.contractId !== undefined) {
                queryParameters['contractId'] = requestParameters.contractId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription/contract`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleContractToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleContractFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceCreateContract(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceCreateContractRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceCreateProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceCreateProduct.');
            }
            const queryParameters = {};
            if (requestParameters.productId !== undefined) {
                queryParameters['productId'] = requestParameters.productId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription/product`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleProductToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleProductFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceCreateProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceCreateProductRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * project subscription
     */
    subscriptionServiceCreateProjectSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
                throw new runtime.RequiredError('subscriptionId', 'Required parameter requestParameters.subscriptionId was null or undefined when calling subscriptionServiceCreateProjectSubscription.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling subscriptionServiceCreateProjectSubscription.');
            }
            if (requestParameters.body === null || requestParameters.body === undefined) {
                throw new runtime.RequiredError('body', 'Required parameter requestParameters.body was null or undefined when calling subscriptionServiceCreateProjectSubscription.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/project-subscription/{subscriptionId}/project/{projectId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: InlineObject3ToJSON(requestParameters.body),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleProjectSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     * project subscription
     */
    subscriptionServiceCreateProjectSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceCreateProjectSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceCreateSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceCreateSubscription.');
            }
            const queryParameters = {};
            if (requestParameters.subscriptionId !== undefined) {
                queryParameters['subscriptionId'] = requestParameters.subscriptionId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleSubscriptionToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceCreateSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceCreateSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceDeactivateSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
                throw new runtime.RequiredError('subscriptionId', 'Required parameter requestParameters.subscriptionId was null or undefined when calling subscriptionServiceDeactivateSubscription.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceDeactivateSubscription.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription/{subscriptionId}/deactivate`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleSubscriptionToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceDeactivateSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceDeactivateSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceDeleteContractRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
                throw new runtime.RequiredError('contractId', 'Required parameter requestParameters.contractId was null or undefined when calling subscriptionServiceDeleteContract.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadBody !== undefined) {
                queryParameters['payload.body'] = requestParameters.payloadBody;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/contract/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    subscriptionServiceDeleteContract(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceDeleteContractRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceDeleteProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.productId === null || requestParameters.productId === undefined) {
                throw new runtime.RequiredError('productId', 'Required parameter requestParameters.productId was null or undefined when calling subscriptionServiceDeleteProduct.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadTier !== undefined) {
                queryParameters['payload.tier'] = requestParameters.payloadTier;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    subscriptionServiceDeleteProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceDeleteProductRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceDeleteProjectSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling subscriptionServiceDeleteProjectSubscription.');
            }
            const queryParameters = {};
            if (requestParameters.subscriptionId !== undefined) {
                queryParameters['subscriptionId'] = requestParameters.subscriptionId;
            }
            if (requestParameters.projectSubscriptionId !== undefined) {
                queryParameters['projectSubscriptionId'] = requestParameters.projectSubscriptionId;
            }
            if (requestParameters.redirectUrl !== undefined) {
                queryParameters['redirectUrl'] = requestParameters.redirectUrl;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/project-subscription/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    subscriptionServiceDeleteProjectSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceDeleteProjectSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceDeleteProjectSubscriptionByIDRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectSubscriptionId === null || requestParameters.projectSubscriptionId === undefined) {
                throw new runtime.RequiredError('projectSubscriptionId', 'Required parameter requestParameters.projectSubscriptionId was null or undefined when calling subscriptionServiceDeleteProjectSubscriptionByID.');
            }
            const queryParameters = {};
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            if (requestParameters.subscriptionId !== undefined) {
                queryParameters['subscriptionId'] = requestParameters.subscriptionId;
            }
            if (requestParameters.redirectUrl !== undefined) {
                queryParameters['redirectUrl'] = requestParameters.redirectUrl;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/project-subscription/{projectSubscriptionId}`.replace(`{${"projectSubscriptionId"}}`, encodeURIComponent(String(requestParameters.projectSubscriptionId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    subscriptionServiceDeleteProjectSubscriptionByID(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceDeleteProjectSubscriptionByIDRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceDeleteSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
                throw new runtime.RequiredError('subscriptionId', 'Required parameter requestParameters.subscriptionId was null or undefined when calling subscriptionServiceDeleteSubscription.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadContractId !== undefined) {
                queryParameters['payload.contract.id'] = requestParameters.payloadContractId;
            }
            if (requestParameters.payloadContractName !== undefined) {
                queryParameters['payload.contract.name'] = requestParameters.payloadContractName;
            }
            if (requestParameters.payloadContractDescription !== undefined) {
                queryParameters['payload.contract.description'] = requestParameters.payloadContractDescription;
            }
            if (requestParameters.payloadContractBody !== undefined) {
                queryParameters['payload.contract.body'] = requestParameters.payloadContractBody;
            }
            if (requestParameters.payloadPrice !== undefined) {
                queryParameters['payload.price'] = requestParameters.payloadPrice;
            }
            if (requestParameters.payloadContractId2 !== undefined) {
                queryParameters['payload.contractId'] = requestParameters.payloadContractId2;
            }
            if (requestParameters.payloadProductId !== undefined) {
                queryParameters['payload.productId'] = requestParameters.payloadProductId;
            }
            if (requestParameters.payloadStatus !== undefined) {
                queryParameters['payload.status'] = requestParameters.payloadStatus;
            }
            if (requestParameters.payloadTier !== undefined) {
                queryParameters['payload.tier'] = requestParameters.payloadTier;
            }
            if (requestParameters.payloadProductId2 !== undefined) {
                queryParameters['payload.product.id'] = requestParameters.payloadProductId2;
            }
            if (requestParameters.payloadProductName !== undefined) {
                queryParameters['payload.product.name'] = requestParameters.payloadProductName;
            }
            if (requestParameters.payloadProductDescription !== undefined) {
                queryParameters['payload.product.description'] = requestParameters.payloadProductDescription;
            }
            if (requestParameters.payloadProductTier !== undefined) {
                queryParameters['payload.product.tier'] = requestParameters.payloadProductTier;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/{subscriptionId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    subscriptionServiceDeleteSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceDeleteSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceListContractsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/contract`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListContractsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceListContracts(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceListContractsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceListProductsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/product`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListSubscriptionProductsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceListProducts(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceListProductsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceListProjectSubscriptionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.productId !== undefined) {
                queryParameters['productId'] = requestParameters.productId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/project-subscription`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListProjectSubscriptionsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceListProjectSubscriptions(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceListProjectSubscriptionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceListSubscriptionsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListSubscriptionsResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceListSubscriptions(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceListSubscriptionsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceMarkProjectSubscriptionAsSeenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectSubscriptionId === null || requestParameters.projectSubscriptionId === undefined) {
                throw new runtime.RequiredError('projectSubscriptionId', 'Required parameter requestParameters.projectSubscriptionId was null or undefined when calling subscriptionServiceMarkProjectSubscriptionAsSeen.');
            }
            const queryParameters = {};
            if (requestParameters.redirectUrl !== undefined) {
                queryParameters['redirectUrl'] = requestParameters.redirectUrl;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/project-subscription/{projectSubscriptionId}/seen`.replace(`{${"projectSubscriptionId"}}`, encodeURIComponent(String(requestParameters.projectSubscriptionId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    subscriptionServiceMarkProjectSubscriptionAsSeen(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceMarkProjectSubscriptionAsSeenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceReadContractRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
                throw new runtime.RequiredError('contractId', 'Required parameter requestParameters.contractId was null or undefined when calling subscriptionServiceReadContract.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadBody !== undefined) {
                queryParameters['payload.body'] = requestParameters.payloadBody;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/contract/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleContractFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceReadContract(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceReadContractRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * product
     */
    subscriptionServiceReadProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.productId === null || requestParameters.productId === undefined) {
                throw new runtime.RequiredError('productId', 'Required parameter requestParameters.productId was null or undefined when calling subscriptionServiceReadProduct.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadTier !== undefined) {
                queryParameters['payload.tier'] = requestParameters.payloadTier;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleProductFromJSON(jsonValue));
        });
    }
    /**
     * product
     */
    subscriptionServiceReadProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceReadProductRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceReadProjectSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling subscriptionServiceReadProjectSubscription.');
            }
            const queryParameters = {};
            if (requestParameters.subscriptionId !== undefined) {
                queryParameters['subscriptionId'] = requestParameters.subscriptionId;
            }
            if (requestParameters.projectSubscriptionId !== undefined) {
                queryParameters['projectSubscriptionId'] = requestParameters.projectSubscriptionId;
            }
            if (requestParameters.redirectUrl !== undefined) {
                queryParameters['redirectUrl'] = requestParameters.redirectUrl;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/project-subscription/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleProjectSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceReadProjectSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceReadProjectSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * by id for admin
     */
    subscriptionServiceReadProjectSubscriptionByIDRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectSubscriptionId === null || requestParameters.projectSubscriptionId === undefined) {
                throw new runtime.RequiredError('projectSubscriptionId', 'Required parameter requestParameters.projectSubscriptionId was null or undefined when calling subscriptionServiceReadProjectSubscriptionByID.');
            }
            const queryParameters = {};
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            if (requestParameters.subscriptionId !== undefined) {
                queryParameters['subscriptionId'] = requestParameters.subscriptionId;
            }
            if (requestParameters.redirectUrl !== undefined) {
                queryParameters['redirectUrl'] = requestParameters.redirectUrl;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/project-subscription/{projectSubscriptionId}`.replace(`{${"projectSubscriptionId"}}`, encodeURIComponent(String(requestParameters.projectSubscriptionId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleProjectSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     * by id for admin
     */
    subscriptionServiceReadProjectSubscriptionByID(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceReadProjectSubscriptionByIDRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceReadSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
                throw new runtime.RequiredError('subscriptionId', 'Required parameter requestParameters.subscriptionId was null or undefined when calling subscriptionServiceReadSubscription.');
            }
            const queryParameters = {};
            if (requestParameters.payloadId !== undefined) {
                queryParameters['payload.id'] = requestParameters.payloadId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadContractId !== undefined) {
                queryParameters['payload.contract.id'] = requestParameters.payloadContractId;
            }
            if (requestParameters.payloadContractName !== undefined) {
                queryParameters['payload.contract.name'] = requestParameters.payloadContractName;
            }
            if (requestParameters.payloadContractDescription !== undefined) {
                queryParameters['payload.contract.description'] = requestParameters.payloadContractDescription;
            }
            if (requestParameters.payloadContractBody !== undefined) {
                queryParameters['payload.contract.body'] = requestParameters.payloadContractBody;
            }
            if (requestParameters.payloadPrice !== undefined) {
                queryParameters['payload.price'] = requestParameters.payloadPrice;
            }
            if (requestParameters.payloadContractId2 !== undefined) {
                queryParameters['payload.contractId'] = requestParameters.payloadContractId2;
            }
            if (requestParameters.payloadProductId !== undefined) {
                queryParameters['payload.productId'] = requestParameters.payloadProductId;
            }
            if (requestParameters.payloadStatus !== undefined) {
                queryParameters['payload.status'] = requestParameters.payloadStatus;
            }
            if (requestParameters.payloadTier !== undefined) {
                queryParameters['payload.tier'] = requestParameters.payloadTier;
            }
            if (requestParameters.payloadProductId2 !== undefined) {
                queryParameters['payload.product.id'] = requestParameters.payloadProductId2;
            }
            if (requestParameters.payloadProductName !== undefined) {
                queryParameters['payload.product.name'] = requestParameters.payloadProductName;
            }
            if (requestParameters.payloadProductDescription !== undefined) {
                queryParameters['payload.product.description'] = requestParameters.payloadProductDescription;
            }
            if (requestParameters.payloadProductTier !== undefined) {
                queryParameters['payload.product.tier'] = requestParameters.payloadProductTier;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/subscription/{subscriptionId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceReadSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceReadSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceUpdateContractRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.contractId === null || requestParameters.contractId === undefined) {
                throw new runtime.RequiredError('contractId', 'Required parameter requestParameters.contractId was null or undefined when calling subscriptionServiceUpdateContract.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceUpdateContract.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription/contract/{contractId}`.replace(`{${"contractId"}}`, encodeURIComponent(String(requestParameters.contractId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleContractToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleContractFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceUpdateContract(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceUpdateContractRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceUpdateProductRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.productId === null || requestParameters.productId === undefined) {
                throw new runtime.RequiredError('productId', 'Required parameter requestParameters.productId was null or undefined when calling subscriptionServiceUpdateProduct.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceUpdateProduct.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleProductToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleProductFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceUpdateProduct(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceUpdateProductRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    subscriptionServiceUpdateSubscriptionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
                throw new runtime.RequiredError('subscriptionId', 'Required parameter requestParameters.subscriptionId was null or undefined when calling subscriptionServiceUpdateSubscription.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling subscriptionServiceUpdateSubscription.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/subscription/{subscriptionId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: ApiSimpleSubscriptionToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiSimpleSubscriptionFromJSON(jsonValue));
        });
    }
    /**
     */
    subscriptionServiceUpdateSubscription(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.subscriptionServiceUpdateSubscriptionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceDeleteProductPayloadTierEnum;
(function (SubscriptionServiceDeleteProductPayloadTierEnum) {
    SubscriptionServiceDeleteProductPayloadTierEnum["SUBSCRIPTION_TIER_START"] = "SubscriptionTierStart";
    SubscriptionServiceDeleteProductPayloadTierEnum["SUBSCRIPTION_TIER_BASIC"] = "SubscriptionTierBasic";
    SubscriptionServiceDeleteProductPayloadTierEnum["SUBSCRIPTION_TIER_PRO"] = "SubscriptionTierPro";
    SubscriptionServiceDeleteProductPayloadTierEnum["SUBSCRIPTION_TIER_ENTERPRISE"] = "SubscriptionTierEnterprise";
})(SubscriptionServiceDeleteProductPayloadTierEnum || (SubscriptionServiceDeleteProductPayloadTierEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceDeleteSubscriptionPayloadStatusEnum;
(function (SubscriptionServiceDeleteSubscriptionPayloadStatusEnum) {
    SubscriptionServiceDeleteSubscriptionPayloadStatusEnum["SUBSCRIPTION_STATUS_INACTIVE"] = "SubscriptionStatusInactive";
    SubscriptionServiceDeleteSubscriptionPayloadStatusEnum["SUBSCRIPTION_STATUS_ACTIVE"] = "SubscriptionStatusActive";
})(SubscriptionServiceDeleteSubscriptionPayloadStatusEnum || (SubscriptionServiceDeleteSubscriptionPayloadStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceDeleteSubscriptionPayloadTierEnum;
(function (SubscriptionServiceDeleteSubscriptionPayloadTierEnum) {
    SubscriptionServiceDeleteSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_START"] = "SubscriptionTierStart";
    SubscriptionServiceDeleteSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_BASIC"] = "SubscriptionTierBasic";
    SubscriptionServiceDeleteSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_PRO"] = "SubscriptionTierPro";
    SubscriptionServiceDeleteSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_ENTERPRISE"] = "SubscriptionTierEnterprise";
})(SubscriptionServiceDeleteSubscriptionPayloadTierEnum || (SubscriptionServiceDeleteSubscriptionPayloadTierEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum;
(function (SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum) {
    SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_START"] = "SubscriptionTierStart";
    SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_BASIC"] = "SubscriptionTierBasic";
    SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_PRO"] = "SubscriptionTierPro";
    SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_ENTERPRISE"] = "SubscriptionTierEnterprise";
})(SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum || (SubscriptionServiceDeleteSubscriptionPayloadProductTierEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceReadProductPayloadTierEnum;
(function (SubscriptionServiceReadProductPayloadTierEnum) {
    SubscriptionServiceReadProductPayloadTierEnum["SUBSCRIPTION_TIER_START"] = "SubscriptionTierStart";
    SubscriptionServiceReadProductPayloadTierEnum["SUBSCRIPTION_TIER_BASIC"] = "SubscriptionTierBasic";
    SubscriptionServiceReadProductPayloadTierEnum["SUBSCRIPTION_TIER_PRO"] = "SubscriptionTierPro";
    SubscriptionServiceReadProductPayloadTierEnum["SUBSCRIPTION_TIER_ENTERPRISE"] = "SubscriptionTierEnterprise";
})(SubscriptionServiceReadProductPayloadTierEnum || (SubscriptionServiceReadProductPayloadTierEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceReadSubscriptionPayloadStatusEnum;
(function (SubscriptionServiceReadSubscriptionPayloadStatusEnum) {
    SubscriptionServiceReadSubscriptionPayloadStatusEnum["SUBSCRIPTION_STATUS_INACTIVE"] = "SubscriptionStatusInactive";
    SubscriptionServiceReadSubscriptionPayloadStatusEnum["SUBSCRIPTION_STATUS_ACTIVE"] = "SubscriptionStatusActive";
})(SubscriptionServiceReadSubscriptionPayloadStatusEnum || (SubscriptionServiceReadSubscriptionPayloadStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceReadSubscriptionPayloadTierEnum;
(function (SubscriptionServiceReadSubscriptionPayloadTierEnum) {
    SubscriptionServiceReadSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_START"] = "SubscriptionTierStart";
    SubscriptionServiceReadSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_BASIC"] = "SubscriptionTierBasic";
    SubscriptionServiceReadSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_PRO"] = "SubscriptionTierPro";
    SubscriptionServiceReadSubscriptionPayloadTierEnum["SUBSCRIPTION_TIER_ENTERPRISE"] = "SubscriptionTierEnterprise";
})(SubscriptionServiceReadSubscriptionPayloadTierEnum || (SubscriptionServiceReadSubscriptionPayloadTierEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SubscriptionServiceReadSubscriptionPayloadProductTierEnum;
(function (SubscriptionServiceReadSubscriptionPayloadProductTierEnum) {
    SubscriptionServiceReadSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_START"] = "SubscriptionTierStart";
    SubscriptionServiceReadSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_BASIC"] = "SubscriptionTierBasic";
    SubscriptionServiceReadSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_PRO"] = "SubscriptionTierPro";
    SubscriptionServiceReadSubscriptionPayloadProductTierEnum["SUBSCRIPTION_TIER_ENTERPRISE"] = "SubscriptionTierEnterprise";
})(SubscriptionServiceReadSubscriptionPayloadProductTierEnum || (SubscriptionServiceReadSubscriptionPayloadProductTierEnum = {}));
